import React from 'react'
import Section from '../section';
import ManageTabs from './managetabs';
import RouteTabs from '../Routes/routetabs';
import { PointsPage } from '../../containers/common/profilepic';
import PrivacySetting from '../../containers/Manage/privacsetting';
import Changepwd from '../../containers/Manage/changepwd';
import EditPartner from './editpartner';
import EditProfileForm from '../../containers/Register/editprofile';
import { ManagePhotos } from '../Register/userphotos';
import Loadprofile from '../../containers/Userprofile/loadprofile';
import EditEmail from './EditEmail';
import Deactform from '../../containers/Manage/deactform';
import Pagelayout from '../pagelayout';

const ManagePage = () => (
    <Pagelayout>
        <Loadprofile>
            <Section className="pt-4 pb-4">
                <div className="col-lg-12 mb-3">

                    <ManageTabs />
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-10">
                    <RouteTabs
                        title="manage"
                        tabs={
                            [
                                {
                                    path: "/",
                                    exact: true,
                                    component: EditProfileForm
                                },
                                {
                                    path: "/edit-email",
                                    component: EditEmail
                                },
                                {
                                    path: "/change-password",
                                    component: Changepwd
                                },
                                {
                                    path: "/partner-preferences",
                                    component: EditPartner
                                },
                                {
                                    path: "/privacy",
                                    component: PrivacySetting
                                },
                                {
                                    path: "/photos",
                                    component: ManagePhotos
                                },
                                {
                                    path: "/points",
                                    component: PointsPage
                                },
                                {
                                    path: "/deactivate-account",
                                    component: Deactform
                                },
                            ]
                        }
                    />
                </div>
            </Section>
        </Loadprofile>
    </Pagelayout>
)

export default ManagePage
