import React from 'react'
import { NavLink } from "react-router-dom"
import List from '../list';
import { OpenModalBtn } from '../../containers/Modal/closemodal';
import { logout } from '../../redux/actions/user';
import { LOGOUT } from '../../redux/actions/constants';
import ManageShortTabs from '../../containers/common/ManageShortTabs';

const manLinks = [

    {
        to: "/manage",
        title: "Edit profile"
    },
    {
        to: "/manage/partner-preferences",
        title: "Edit partner preferences"
    },
    {
        to: "/manage/photos",
        title: "Manage photos"
    },
    {
        to: "/manage/privacy",
        title: "Privacy settings"
    },
    {
        to: "/manage/change-password",
        title: "Change password"
    },
    {
        to: "/manage/deactivate-account",
        title: "Deactivate account"
    },
];

const manageLinks = [
    {
        heading: "Account Setting",
        links: manLinks
    }
];

const ManageTabs = () => {
    return (
        <ManageShortTabs linksList={manLinks}>
            {/* <div className="manage-border"> */}
            <List
                RenderItem={ManageBox}
                list={manageLinks}
                title="manage-box"
            />
            {/* </div> */}
        </ManageShortTabs>
    )
}

export const ManageBox = ({ heading, links }) => (
    <div>
        <ul className="list-inline p-2">
            <List
                RenderItem={ManageTabLink}
                list={links}
                title="manage-links"
            />
        </ul>
    </div>
)

export const ManageTabLink = ({ to, title }) => (
    <li className="list-inline-item nav-item pl-2 pr-2">
        <NavLink className="btn btn-matches-normal" exact activeClassName="btn-matches-active" to={to}>
            {title}
        </NavLink>
    </li>
)

export const HeaderDropDown = () => (
    <ul className="logout-dropdown-content">
        <List
            RenderItem={ManageTabLink}
            list={[
                {
                    to: "/my-profile",
                    title: "My Profile"
                },
                {
                    to: "/manage/photos",
                    title: "Upload Photos"
                },
                {
                    to: "/manage",
                    title: "Manage Profile"
                },
            ]}
            title="manage-box"
        />
        <li>
            <LogoutBtn
                className="btn logout-btn"
            />
        </li>
    </ul>
)

export const LogoutBtn = ({ className }) => (
    <OpenModalBtn
        className={className}
        modalName="CONFIRM_MODAL"
        modalProps={{
            msg: "Are you sure you want to logout?",
            confirmProps: {
                reducerName: "log_out",
                ntAction: () => logout(),
                formName: LOGOUT
            }
        }}
    >
        Logout
    </OpenModalBtn>
)

export default ManageTabs
