import { _FETCHING, _FULFILLED, _REJECTED, _CLEAR, _ADD_DATA, _UPDATE_DATA } from "../actions/constants";

//intial httpObject
const httpObjectInitial = {
    fetching: false, data: {}, error: false
};

//intial httpObject
const httpArrayInitial = {
    fetching: false, data: [], error: false
};

//intial httpResultsReducer
export const resultsIntial = {
    fetching: false,
    data: [],
    byId: {},
    error: false,
    count: null,
    next: null,
    prev: null
};

//create http reducer function
export const httpObjectReducer = (name) => {
    return (state = httpObjectInitial, action) => {
        switch (action.type) {
            case `${name}${_FETCHING}`:
                return {
                    ...state,
                    fetching: true,
                    error: false
                }
            case `${name}${_FULFILLED}`:
                return {
                    ...state,
                    fetching: false,
                    data: action.payload
                }
            case `${name}${_REJECTED}`:
                return {
                    ...state,
                    fetching: false,
                    error: action.payload
                }
            case `${name}${_CLEAR}`:
                return { ...state, ...httpObjectInitial }
            default:
                return state
        }
    }
}

//create http reducer function
export const httpArrayReducer = (name) => {
    return (state = httpArrayInitial, action) => {
        switch (action.type) {
            case `${name}${_FETCHING}`:
                return {
                    ...state,
                    fetching: true,
                    error: false
                }
            case `${name}${_FULFILLED}`:
                return {
                    ...state,
                    fetching: false,
                    data: action.payload
                }
            case `${name}${_REJECTED}`:
                return {
                    ...state,
                    fetching: false,
                    error: action.payload
                }
            case `${name}${_UPDATE_DATA}`:
                return {
                    ...state,
                    data: state.data.map(x => {
                        if (x.id === action.id) {
                            return { ...x, ...action.payload }
                        }
                        return x;
                    })
                }
            case `${name}${_CLEAR}`:
                return { ...state, ...httpArrayInitial }
            default:
                return state
        }
    }
}

export const reduceResults = (state, results) => results.reduce((acc, cur) => {
    return {
        byId: {
            ...acc.byId,
            [cur.id]: cur
        },
        data: acc.data.concat(cur.id)
    }
}, { byId: state.byId, data: state.data })

export const byIdAddData = (state, action) => {
    return {
        ...state,
        data: [action.payload.id, ...state.data],
        byId: {
            ...state.byId,
            [action.payload.id]: action.payload
        }
    }
}

export const byIdUpdateData = (state, action) => {
    return {
        ...state,
        byId: {
            ...state.byId,
            [action.payload.id]: {
                ...state.byId[action.payload.id],
                ...action.payload
            }
        }
    }
}

export const httpResultsReducer = (name) => {
    return (state = resultsIntial, action) => {
        switch (action.type) {
            case `${name}${_FETCHING}`:
                return {
                    ...state,
                    fetching: true,
                    error: false
                }
            case `${name}${_FULFILLED}`:
                return {
                    ...state,
                    fetching: false,
                    count: action.payload.count,
                    ...reduceResults(state, action.payload.results),
                    next: action.payload.next,
                    prev: action.payload.prev,
                }
            case `${name}${_REJECTED}`:
                return {
                    ...state,
                    fetching: false,
                    error: action.payload
                }
            case `${name}${_ADD_DATA}`:
                return byIdAddData(state, action)
            case `${name}${_UPDATE_DATA}`:
                return byIdUpdateData(state, action)
            case `${name}${_CLEAR}`:
                return { ...state, ...resultsIntial }
            default:
                return state
        }
    }
}