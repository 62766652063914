import React from 'react'

const MatchBtn = ({ children, profile, fetching = false, ...restProps }) => {
    return (
        <button
            disabled={fetching}
            className={`btn ${profile ? "custom-btn btn-block" : "match-func-btn"}`}
            {...restProps}
        >
            {children}
        </button>
    )
}

export default MatchBtn
