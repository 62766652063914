import { saveLocalState } from "../../utils/localstorage";
import apiInstance from "../apiInstace";
import { LOGIN, _FULFILLED, SIGNUP, REGISTER, UPLOAD_PHOTOS, PARTNER_PREFER, OTP_VERIFY, LOGOUT, DELETE_PHOTOS, PRIVACY, MY_PROFILE, RESETPWD, UPDATE_USER, SET_PRO_PIC } from "../actions/constants";

const loginTypes = [ UPDATE_USER, `${LOGIN}${_FULFILLED}`, `${SIGNUP}${_FULFILLED}`, `${REGISTER}${_FULFILLED}`, `${UPLOAD_PHOTOS}${_FULFILLED}`, `${DELETE_PHOTOS}${_FULFILLED}`, `${PARTNER_PREFER}${_FULFILLED}`, `${OTP_VERIFY}${_FULFILLED}`, `${PRIVACY}${_FULFILLED}`, `${MY_PROFILE}${_FULFILLED}`, `${RESETPWD}${_FULFILLED}`, SET_PRO_PIC + _FULFILLED];

const localStorageMiddleware = store => next => action => {
	if (loginTypes.includes(action.type)) {
		if (!action.error) {
			const user = action.payload;
			saveLocalState(user);
			// Alter defaults after instance has been created
			if(user.token){
				apiInstance.defaults.headers.common['Authorization'] = `Token ${user.token}`;
			}
		}
	} else if (action.type === `${LOGOUT}${_FULFILLED}`) {
		delete apiInstance.defaults.headers.common['Authorization'];
		localStorage.removeItem('user');
	}
	next(action);
};

export default localStorageMiddleware