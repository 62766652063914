import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubmitBtn from '../common/submitbtn';
import { chats_url, LIST_CHAT_USERS, _UPDATE_DATA } from '../../redux/actions/constants';

class AcceptChat extends Component {

    renderAccept = (title= "Accept", className="btn btn-primary") => {
        const { id } = this.props;
        return (
            <SubmitBtn
                className={className}
                style={{ minWidth: `125px` }}
                config={{
                    url: `${chats_url}${id}/accept_request/`,
                    method: "POST",
                    data: {
                        is_accept: true
                    }
                }}
                succFunc={(payload) => {
                    this.updateChatuser(payload);
                }}
            >
                <i className="far fa-thumbs-up mr-1"></i> {title}
            </SubmitBtn>
        )
    }

    updateChatuser = (payload) => {
        const { dispatch, filter } = this.props;
        dispatch({
            type: LIST_CHAT_USERS + _UPDATE_DATA,
            payload,
            id: filter
        });
    }

    render() {
        const { requested_me, id, status } = this.props;
        return (
            <div className="pt-1 pb-1 d-flex justify-content-center align-items-center">
                {
                    requested_me ?
                        <h4 className="m-0 text-center">Invite has been sent</h4> :
                        status === null ?
                            <React.Fragment>
                                <SubmitBtn
                                    className="btn btn-outline-danger mr-2"
                                    style={{ minWidth: `125px` }}
                                    config={{
                                        url: `${chats_url}${id}/accept_request/`,
                                        method: "POST",
                                        data: {
                                            is_accept: false
                                        }
                                    }}
                                    succFunc={(payload) => {
                                        this.updateChatuser(payload);
                                    }}
                                >
                                    <i className="far fa-thumbs-down mr-1"></i> Deny
                                </SubmitBtn>
                            {this.renderAccept()}
                            </React.Fragment> : status === false ?
                                <div className="d-flex align-items-center">
                                    <h4 className="m-0 text-center">Denied this Invite</h4>
                                    {/* {this.renderAccept("Unblock", "btn btn-outline-dark ml-4 f-14")} */}
                                </div>   
                            : null
                }
            </div>
        )
    }
}

const mapStateToProps = ({ chatusers }) => ({
    filter: chatusers.id
})

export default connect(mapStateToProps)(AcceptChat)
