import React from 'react'
import Section from '../section';

const PrivacyPolicy = () => {
    return (
        <div>
            <Section className="pt-4 mb-5">

                <div className="col-md-12 bx-shdw">
                    <p className="theme-color f-20">Privacy Policy</p>
                    <div className="p-2 pl-3">
                        <p className="f-13"><span className="theme-support-color">Santhoshammatrimony.com</span> is a matrimony portal that shows you best matrimonial services. The conditions in <span className="theme-support-color">Santhoshammatrimony</span> is applicable for all other related sites and we are strictly indulge in our privacy policy. The information that you are submitted to us is in safety hands. The information that you are giving the details according to your interest. Please don’t fill the form if you are not eligible our policy.</p>

                        <p className="f-13">The online payment is very secure in our sites and we will protect your entered transaction details. For quick accessing our site we save the login information.</p>
                        <b className="f-14">How the matrimony site works?</b>
                        <p className="f-13">We collect information from various users and provide you the matrimony details according to the related search.</p>

                        <b className="f-14">Can I visit the site without registering?</b>
                        <p className="f-13">Yes, you can go through the without registering and payment but you can view only the ads and browse the site.</p>

                        <b className="f-14">How to remove account?</b>
                        <p className="f-13">The customer requested to open the required page and remove the account.</p>

                        <b className="f-14">Can I go through all profiles?</b>
                        <p className="f-13">Yes, you can but there is a limit in sending messages per day.</p>


                    </div>

                </div>
            </Section>
        </div>
    )
}

export default PrivacyPolicy
