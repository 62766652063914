import React from 'react'
import CustomBtn from '../Buttons/CustomBtn';

const PayStatus = ({ icon, title = "Payment Success", to = "/my-profile", toTitle = "profile page", children }) => {
    return (
        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: `calc(100vh - 60px)` }}>
            <div className="p-4 pt-5 pb-5 border text-center bg-white" style={{ maxWidth: `450px` }}>
                <img src={icon} className="mb-3" alt="" />
                <h4>{title}</h4>
                <div className="mb-4">
                    {
                        children
                    }
                </div>
                <CustomBtn to={to} replace={true}>
                    Go to {toTitle}
                </CustomBtn>
            </div>
        </div >
    )
}

export default PayStatus
