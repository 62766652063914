import React from 'react'
import { connect } from 'react-redux'
import PurchasedPlan from '../../components/Manage/purchasedplan';

const ProfilePic = ({user}) => {
    return (
        <div className="d-flex align-item-center border-bottom pb-2">
            <div className="pr-2">
                <img className="round-profile-pic" src={user.profile_pic} alt="" />
            </div>
            <div>
                <p className="f-12 mb-1">Hi,</p>
                <h6 className="m-0">{user.user.first_name}</h6>
            </div>
        </div>
    )
}

const Points = ({user}) => <PurchasedPlan packages={user.packages} />
  
const mapStateToProps = ({user}) => ({
    user
})

export const PointsPage = connect(mapStateToProps)(Points);

export default connect(mapStateToProps)(ProfilePic)
