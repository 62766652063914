import React from 'react'
import { connect } from 'react-redux'
import {Route, Redirect} from "react-router-dom"

const skip = {
    1: "/register",
    2: "/otp",
    3: "/upload-photos",
    4: "/partner-preferences"
};

export const UserRoute = ({user, path, ...restProps}) => {
    if(!user.token){
        return <Redirect to="/" />
    } else if (user.skip_status && user.skip_status < 4 && skip[user.skip_status] !== path){
        return <Redirect to={skip[user.skip_status]} />
    } else {
        return (
            <Route path={path} {...restProps} />
        )
    }
}

const mapStateToProps = ({user}) => ({
    user
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoute)
