import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setFormValue } from '../../redux/actions/formactions';

export class CCheckBox extends Component {

    handleChange = (name, value) => {
        const { setFormValue, formName, isMulti } = this.props;
        const oldValue = this.props.value;
        setFormValue(formName, {
            name,
            data: {
                value: isMulti ?
                    oldValue.includes(value) ?
                        oldValue.filter(x => x !== value) :
                        [...this.props.value, value] : [value]
            }
        });
    }

    render() {
        const { type, setFormValue, name, value, className, formName, options, isMulti, labelKey, valueKey, ...restProps } = this.props;
        return (
            <div className="d-flex align-item-center flex-wrap">
                {
                    options.map((x, index) => {
                        let ipVal = x[valueKey || "value"];
                        let labelVal = x[labelKey || "label"];
                        return (
                            <div
                                className={`multiple-checkbox pt-1 pr-3`}
                                key={index}
                            >
                                <input
                                    id={`${name}-${index}`}
                                    type="checkbox"
                                    onChange={() => this.handleChange(name, ipVal)}
                                    name={name}
                                    value={ipVal}
                                    checked={value.includes(ipVal)}
                                    {...restProps}
                                />
                                <label htmlFor={`${name}-${index}`} className="radio-label d-flex align-items-center">{labelVal}</label>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

const mapDispatchToProps = {
    setFormValue
}

export default connect(null, mapDispatchToProps)(CCheckBox)
