import React from 'react'
import Section from '../section';

const Faqs = () => {
    return (
        <div>
            <Section className="pt-4 pb-4">
                <div className="col-lg-12 mb-5">
                    <div className="bx-shdw">
                        <h5 className="mb-3 theme-color">Frequently Asked Questions</h5>
                        <div>
                            <div className="f-13 f-600">How to Register with Santhosham Matrimony.com ?</div>
                            <p className="pl-2 f-12">Santhosham Matrimony has both Free and paid registration services.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">Free Registration:-</div>
                            <p className="pl-2 f-12">You can do free registration from the home page itself, just fill up the form and proceed, you will get a confirmation email, click on the link – your mail id is confirmed, you will be directed to mobile confirmation page, at the same time you will receive SMS with 6 digit PIN NUMBER in your mobile, type that pin in the slot provided to confirm your mobile no, You will be allotted a matrimony id, now you can update your profile with photo, horo etc and add partner preference. By the next time you can login with your mail id (or) Mobile No (or) Matrimony Id and password chosen by you.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">Paid Registartion:-</div>
                            <p className="pl-2 f-12">This is very similar to free registration, you have to choose the package type for a required time period, paid profiles gets all SMS benefits like New Profile SMS, Updated Profile SMS, followed by send and receive Photo Request SMS, Horo Request SMS, Social Reference Request SMS right on your mobile phone, Huge quantity of SMS is allotted, so your alliance search will be made at lightning speed.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">Profile Validation ?</div>
                            <p className="pl-2 f-12">We try to keep our database clean so every profile is manually validated and edited if required; care is taken to keep your identity safe. We are against spam, we reject profiles of under aged people, funny contents which are not related to marriage, abusive contents etc. No one can add content without confirming their mobile numbers, if we found something fraud, we will locate them easily and hand over them to cyber police. We are very serious in marriage making. Is there multiple regional matrimony portals to add my profile? Our site is a big one and can handle millions of data at high speed with in a fraction of a second, so it is not at all required for us to start regional portals.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">Do I register for someone else ?</div>
                            <p className="pl-2 f-12">No, it is better to ask them to register by them self, they get their mobile number confirmed, may be they can get some instant SMS requests or SMS Messages to get their alliance fixed, if you are in between and forgot to convey those messages you will be held responsible for loss of that alliance. If they are uneducated (or) too busy in their works, you can register on their behalf, but try to have them nearby during registration and give their mobile number alone, that is the safer side.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">Do I use same id register for my brother (or) sister (or) myself?</div>
                            <p className="pl-2 f-12">Matrimony id is unique, once a profile is added, certain parameters are fixed and get some benefits, if you alter any, you will be held responsible for the loss of alliance, it is better to make a new registration and get the profile added in our database.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">Do I register for my friend (or) relative (or) sibling?</div>
                            <p className="pl-2 f-12">No, Let it do by them self, everyone needs their mobile no confirmed to get instant SMS, if you intrude and forgot to convey SMS Messages, you will be held responsible for any losses incurred in their matrimony search, however you can help them to register their profile. If you are father, mother or a responsible sibling/ relative you can do it with your mobile number.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">Can I Use same Email id or Mobile No for 2 different profiles?</div>
                            <p className="pl-2 f-12">No, it is not at all allowed, everything is unique; you must have a different email id and mobile no for the next profile.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">What are the details I can add in my profile description?</div>
                            <p className="pl-2 f-12">It is just about yourself, like your education, career details, family background, your interests, partner expectation etc, give all the details in a simple and clear manner, don’t add your email id or mobile no in description, you have to add them in the appropriate spaces provided for them.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600">Sample Profile for reference</div>
                            <p className="pl-2 f-12">I am Ravi Shankar, Bachelor of Engineering in Electrical Department, working as a senior maintenance engineer in a private engineering company for the past 3 years at Hyderabad; I am a friendly and fun loving person with good personality. I have cycling, travelling and music listening as my hobbies. Mine is a small family of 4 members, with father, mother, younger sister and myself, father is a retired Engineer from Agricultural Dept, mother is a house wife and my sister is doing her SSLC, I wish to get a life partner who is intelligent, broad minded, understanding, responsible to care of me and my family.</p>
                        </div>

                        <div>
                            <div className="f-13 f-600"></div>
                            <p className="pl-2 f-12"></p>
                        </div>

                        <div>
                            <div className="f-13 f-600"></div>
                            <p className="pl-2 f-12"></p>
                        </div>

                        <div>
                            <div className="f-13 f-600"></div>
                            <p className="pl-2 f-12"></p>
                        </div>

                        <div>
                            <div className="f-13 f-600"></div>
                            <p className="pl-2 f-12"></p>
                        </div>

                        <div>
                            <div className="f-13 f-600"></div>
                            <p className="pl-2 f-12"></p>
                        </div>

                        <div>
                            <div className="f-13 f-600"></div>
                            <p className="pl-2 f-12"></p>
                        </div>
                    </div>
                </div>
            </Section>
        </div>
    )
}

export default Faqs
