import React, { Component } from "react";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import SendPhotoBtn from "../Register/sendphotobtn";
import { CloseModalBtn } from "../Modal/closemodal";
import * as loadImage from 'blueimp-load-image';

export class ImageCropper extends Component {

    state = {
        src: null,
        crop: {
            aspect: 1,
            width: 50,
            x: 0,
            y: 0
        },
        croppedImageUrl: null,
        blob: null
    };

    onSelectFile = e => {
        const { files } = e.target;
        if (files && files.length > 0) {
            loadImage(
                files[0],
                canvas => this.setState({ src: canvas.toDataURL() }),
                {
                    //orientation in mobile devices/exif
                    orientation: true
                }
            );
        }
    };

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
        makeAspectCrop({ aspect: 1, width: 50 }, image.naturalWidth / image.naturalHeight);
    };

    onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    async makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                "newFile.jpeg"
            );
            this.setState({ ...croppedImageUrl });
        }
    }

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement("canvas");
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({ croppedImageUrl: this.fileUrl, blob });
            }, "image/jpeg");
        });
    }

    render() {
        const { crop, croppedImageUrl, src, blob } = this.state;
        return (
            <div className="p-2">
                <div className="pt-2">
                    <h4 className="mb-2">Upload Your Photo</h4>
                </div>
                <div className="pt-2 pb-3">
                    <input className="f-13" type="file" accept="image/*" onChange={this.onSelectFile} />
                </div>
                <div className="d-flex justify-content-center align-item-center flex-wrap">
                    <div className="image-crop-container">
                        {src && (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        )}
                    </div>
                    <div className="image-crop-container">
                        {croppedImageUrl && (
                            <img className="w-100" src={croppedImageUrl} alt="" />
                        )}
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-center pt-3 pb-2">
                    <CloseModalBtn className="btn btn-outline-req pl-4 pr-4 mr-3">
                        Cancel
                    </CloseModalBtn>
                    <SendPhotoBtn image={blob} />
                </div>
            </div>
        );
    }
}
