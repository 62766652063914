import React, { Component } from "react";
import { connect } from "react-redux";
import { setFormValue } from "../../redux/actions/formactions";
import { EDITUSER_DESCRIPTION } from "../../redux/actions/constants";
import List from "../../components/list";
import { closeModal } from "../../redux/actions/common";

export const getVal = (arr = [], choose = []) => {
    return arr
        .filter(x => choose.includes(x.inputProps.name))
        .reduce((acc, cur) => [...acc, cur.inputProps.value], []);
};

const chooseList = [
    {
        id: 1,
        title: "Profile created by"
    },
    {
        id: 2,
        title: "Education & Employment Details"
    },
    {
        id: 3,
        title: "Family Details"
    },
    {
        id: 4,
        title: "Location"
    }
];

class GenDesc extends Component {
    state = {
        choosenIds: []
    };

    generateDesc = () => {
        const {
            user,
            setFormValue,
            edituser_basic,
            edituser_edu,
            edituser_family,
            closeModal
        } = this.props;
        const { profile_for, gender } = user;
        const { choosenIds } = this.state;
        const is_male = gender === "Male";
        const is_me = profile_for.id === 1;
        const [first_name, district] = getVal(edituser_basic, [
            "first_name",
            "district_id"
        ]);
        let line = "";
        if (choosenIds.includes(1)) {
            line += is_me
                ? first_name
                    ? `I am ${first_name}. `
                    : ""
                : `I have made this profile on behalf of my ${profile_for.name}. `;
        }
        if (choosenIds.includes(2)) {
            const [
                highest_education_id,
                employed_in_id,
                occupation_id
            ] = getVal(edituser_edu, [
                "highest_education_id",
                "employed_in_id",
                "occupation_id"
            ]);
            if (employed_in_id && occupation_id) {
                if (employed_in_id.label && occupation_id.label) {
                    if (employed_in_id.label === "Not Working") {
                        line += `${
                            is_me ? `I am` : `${is_male ? "He" : "She"} is`
                        } currently not working`;
                    } else {
                        line += `${
                            is_me ? `I am` : `${is_male ? "He" : "She"} is`
                        } currently working as a ${occupation_id.label} in ${
                            employed_in_id.label
                        } sector`;
                    }
                    if (highest_education_id && highest_education_id.label) {
                        line += `, after having completed ${
                            is_me ? `my` : `${is_male ? "his" : "her"}`
                        } ${highest_education_id.label}. `;
                    }
                }
            }
        }
        if (choosenIds.includes(3)) {
            const [family_status, family_type, family_values] = getVal(
                edituser_family,
                ["family_status", "family_type", "family_values"]
            );
            if (family_status && family_type && family_values) {
                if (
                    family_status.label &&
                    family_type.label &&
                    family_values.label
                ) {
                    line += `${is_me ? `I` : `We`} come from a ${
                        family_status.label
                    }, ${family_type.label} with ${
                        family_values.label
                    } values. `;
                }
            }
        }
        if (choosenIds.includes(4)) {
            if (district && district.label) {
                line += `${
                    is_me ? `I am` : `${is_male ? "He" : "She"} is`
                } currently living in ${district.label}. `;
            }
        }

        setFormValue(EDITUSER_DESCRIPTION, {
            name: "about_me",
            data: {
                value: line
            }
        });

        closeModal();
    };

    selectLi = id => {
        if (this.state.choosenIds.includes(id)) {
            this.setState(prev => ({
                choosenIds: prev.choosenIds.filter(x => x !== id)
            }));
        } else {
            this.setState(prev => ({ choosenIds: [...prev.choosenIds, id] }));
        }
    };

    render() {
        const { choosenIds } = this.state;
        return (
            <div className="p-3">
                <h3>Generate Description</h3>
                <div className="pt-2">
                    <ul className="choosedesc-list">
                        <List
                            list={chooseList}
                            RenderItem={({ id, title }) => (
                                <li
                                    className={
                                        choosenIds.includes(id)
                                            ? "choosedesc-active"
                                            : ""
                                    }
                                    onClick={() => this.selectLi(id)}
                                >
                                    {title}
                                </li>
                            )}
                            title="chooseList"
                        />
                    </ul>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <button
                        onClick={this.generateDesc}
                        className="btn custom-btn"
                    >
                        Generate
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({
    edituser_basic,
    edituser_phy,
    edituser_astro,
    edituser_edu,
    edituser_family,
    edituser_habits,
    edituser_desc,
    user
}) => ({
    edituser_basic,
    edituser_phy,
    edituser_astro,
    edituser_edu,
    edituser_family,
    edituser_habits,
    edituser_desc,
    user
});

const mapDispatchToProps = { setFormValue, closeModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenDesc);
