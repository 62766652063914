import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from './formbox';
import LoadingBtn from '../../components/Buttons/loadingbtn';
// import { Link } from "react-router-dom";
import { setFormErr } from '../../redux/actions/formactions';
import { validate, reduceForm } from '../../utils/Forms/validation';
import { otpSubmitClear, resendOtp } from '../../redux/actions/user';
import { OTP, OTP_VERIFY, RESEND_OTP, FORGOTPWD } from '../../redux/actions/constants';
import { Redirect } from "react-router-dom";
import FormCon from '../Form/formcon';

export class ResetOtpForm extends Component {

    state = {
        seconds: 0
    };


    tick() {
        this.setState(state => ({
            seconds: state.seconds - 1
        }));
    }

    handleSubmit = e => {
        e.preventDefault();
        const { setFormErr, otp_input, history, forgot_pwd } = this.props;
        const setErr = payload => setFormErr(OTP, payload);
        const isErr = validate(otp_input, setErr);
        if (isErr) {
            return;
        }
        const { otp } = reduceForm(otp_input);
        history.replace(`/reset-password?id=${forgot_pwd.data.id}&otp=${otp}`);
    }

    resend = () => {
        const { resendOtp, forgot_pwd } = this.props;
        this.setState({ seconds: 25 }, () => {
            this.interval = setInterval(() => this.tick(), 1000);
        })
        resendOtp({ mobile_number: forgot_pwd.data.mobile_number });
    }

    componentDidUpdate() {
        if (this.state.seconds === 0) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { forgot_pwd, otpVerify } = this.props;
        if (!forgot_pwd.data.id) {
            return <Redirect to="/forgot-password" />
        }
        return (
            <div>
                <FormCon
                    clear={[OTP, OTP_VERIFY, RESEND_OTP, FORGOTPWD]}
                    onSubmit={this.handleSubmit}
                >
                    <Formbox
                        title="OTP verification"
                        description={`Enter OTP sent to ${forgot_pwd.data.mobile_number}`}
                        formName={OTP}
                        inputsName="otp_input"
                    />
                    <div className="row">
                        <div className="col-md-8">
                            <Center>
                                <LoadingBtn
                                    className="btn custom-btn registerbtn"
                                    fetching={otpVerify.fetching}
                                    disabled={otpVerify.fetching}
                                    onSubmit={this.handleSubmit}
                                    type="submit"
                                >
                                    Submit
                            </LoadingBtn>
                            </Center>
                        </div>
                    </div>
                </FormCon>
                <div className="row">
                    <div className="col-md-8">
                        <Center className="f-12 mt-2">
                            <span className="mr-1">Did not recieve OTP? </span>
                            {this.state.seconds === 0 ?
                                <button className="btn btn-link p-0 m-0 f-12" onClick={this.resend}>Resend OTP</button> :
                                <span>Wait for {this.state.seconds}s</span>
                            }
                        </Center>
                        {/* <Center className="f-12 mt-1">
                            <span className="mr-1">Wrong mobile number?</span>
                            <Link to="/change-mobile-number">Change mobile number</Link>
                        </Center> */}
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
}

const mapStateToProps = ({ forgot_pwd, otp_input, otpVerify, resend_otp }) => ({
    forgot_pwd, otp_input, otpVerify, resend_otp
})

const mapDispatchToProps = {
    setFormErr, otpSubmitClear, resendOtp
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetOtpForm)