import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from '../Register/formbox';
import { setFormErr } from '../../redux/actions/formactions';
import { validate, reduceForm } from '../../utils/Forms/validation';
import FormCon from '../Form/formcon';
import { DEACTIVE_FORM } from '../../redux/actions/constants';
import { openModal } from '../../redux/actions/common';


export class DeactForm extends Component {

    handleSubmit = e => {
        e.preventDefault();
        const { deact_form, setFormErr, openModal } = this.props;
        const setErr = payload => setFormErr(DEACTIVE_FORM, payload);
        const isErr = validate(deact_form, setErr);
        if (isErr) {
            return;
        }
        const formData = reduceForm(deact_form);
        openModal("DEACT_MODAL", { reason: formData.reason === "Others" ? formData.other_reason : formData.reason })
    }

    render() {
        return (
            <FormCon
                clear={[]}
                onSubmit={this.handleSubmit}
            >
                <Formbox
                    title="Deactivate Account"
                    formName={DEACTIVE_FORM}
                    inputsName="deact_form"
                >
                    <div className="row pt-3">
                        <div className="col-md-12">
                            <Center>
                                <button className="btn custom-btn">
                                    Deactive Account
                                </button>
                            </Center>
                        </div>
                    </div>
                </Formbox>
            </FormCon>
        )
    }
}

const mapStateToProps = ({ deact_form }) => ({
    deact_form
})

const mapDispatchToProps = {
    setFormErr, openModal
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactForm)