import React from "react"
import ContentLoader from "react-content-loader"


const Left = props => (
    <div className="col-md-12">
        <ContentLoader
            speed={1}
            width={1020}
            height={400}
            viewBox="0 0 1020 400"
        >
            <rect x="10" y="10" rx="25" ry="25" width="282" height="282" />

            <rect x="310" y="10" rx="5" ry="5" width="600" height="12" />
            <rect x="310" y="33" rx="5" ry="5" width="300" height="8" />

            <circle cx="320" cy="100" r="8" />
            <rect x="344" y="96" rx="5" ry="5" width="200" height="8" />

            <circle cx="620" cy="100" r="8" />
            <rect x="644" y="96" rx="5" ry="5" width="200" height="8" />


            <circle cx="320" cy="148" r="8" />
            <rect x="344" y="144" rx="5" ry="5" width="200" height="8" />


            <circle cx="620" cy="148" r="8" />
            <rect x="644" y="144" rx="5" ry="5" width="200" height="8" />


            <circle cx="320" cy="196" r="8" />
            <rect x="344" y="192" rx="5" ry="5" width="200" height="8" />


            <circle cx="620" cy="196" r="8" />
            <rect x="644" y="192" rx="5" ry="5" width="200" height="8" />

            <rect x="320" y="255" rx="5" ry="5" width="150" height="30" />
            <rect x="520" y="255" rx="5" ry="5" width="150" height="30" />
            <rect x="720" y="255" rx="5" ry="5" width="150" height="30" />
        </ContentLoader>
    </div>
)

export const DetailLoader = () => (
    <div className="container-fluid pt-4 pb-5">
        <div className="row">
            <Left></Left>
        </div>
    </div>
)