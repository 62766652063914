import React from 'react'
import Userprofile from '../../containers/Userprofile/userprofile';
import Pagelayout from '../pagelayout';

const UserProfilePage = () => {
    return (
        <Pagelayout>
            <Userprofile />
        </Pagelayout>
    )
}

export default UserProfilePage
