import React from 'react'
import ProfileBox from './profilebox';

const ProfileDetails = ({ userData, edit }) => {
    const { gender, age, district, state, country, user_details, religion_details, education_details, about_me } = userData;

    const gender_value = gender === "Female" ? "Her" : "Him"
    return (
        <div className="bx-shdw p-4">
            <div className="row">
                <div className="col-md-12">
                    <ProfileBox title={
                        <span>
                            About {gender_value}
                        </span>
                    }>
                        <div className="medium-txt1 mb-4 row">
                            <div className="col-lg-11 col-md-11 col-sm-11">
                                <i className="fas fa-book-reader f-26 theme-color pr-4"></i>
                                <span>
                                    {about_me}
                                </span>
                            </div>
                        </div>
                        <div className="row profile-list-main">
                            <ul className="pl-3">
                                <li>
                                    <i className="fas fa-user f-20 theme-color pr-4"></i>
                                    {`${age} Yrs`}{`, ${user_details.height.name}`}
                                </li>
                                <li>
                                    <i className="fas fa-child f-20 theme-color pr-4"></i>
                                    {`${user_details.weight} kgs`}{`, ${user_details.body_type.name}`}{`, ${user_details.skin_tone.name}`}
                                </li>
                                <li>
                                    <i className="fas fa-ring f-20 theme-color pr-4"></i>
                                    {`${user_details.marital_status.name}`}
                                </li>
                                {user_details && user_details.number_of_children > 0 &&
                                    <li>
                                        <i className="fas fa-baby f-20 theme-color pr-4"></i>
                                        Number of Children<b className="text-danger"> ({user_details.number_of_children} {user_details?.child_living===true ? "Living together" : user_details?.child_living === false? "Not Living together":""})</b>
                                    </li>
                                }
                                <li>
                                    <i className="fas fa-map-marker-alt f-20 theme-color pr-4"></i>
                                    Lives in {district && `${district.name}, ${state.name}, ${country.name}`}
                                </li>
                                <li>
                                    <i className="fas fa-smoking f-20 theme-color pr-4"></i>
                                    {user_details.smoking_habits === 'No' ? "Doesn't Smoke" : "Will Smoke"}
                                </li>
                                <li>
                                    <i className="fas fa-glass-cheers f-20 theme-color pr-4"></i>
                                    {user_details.drinking_habits === 'No' ? "Doesn't Drinking" : user_details.drinking_habits === 'Yes' ? "Will Drinking" : user_details.drinking_habits}
                                </li>
                                <li>
                                    <i className="fas fa-utensils f-20 theme-color pr-4"></i>
                                    {user_details.food_habits}
                                </li>
                                <li>
                                    <i className="fas fa-wheelchair f-20 theme-color pr-4"></i>
                                    {`${user_details.is_physically_challenged ? 'Physically Disabled' : "Physical Status is Normal"}`}
                                </li>
                            </ul>

                        </div>
                        <div className="bx-shdw  pt-3 pb-3 row m-4 medium-txt1">
                            <div className="col-md-8">
                                <div className="row">

                                    <div className=" col-md-1">
                                        <i className="fas fa-mobile-alt theme-color f-22"></i>

                                    </div>
                                    <div className="col-md-9">
                                        {gender_value} Mobile No: +91 XXX-XX XXX-XX
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ProfileBox>

                    {
                        religion_details &&
                        <div className="col-md-12">
                            <ProfileBox
                                edit={edit ? "/manage" : false}
                                title={`${gender_value} religious background`}
                            >
                                <div className="row">
                                    <div className="col-md-12 profile-list-main">
                                        <ul className="pl-3">
                                            <li>
                                                <i className="fas fa-praying-hands f-20 theme-color pr-4"></i>
                                                {`${religion_details.religion.name}`}
                                            </li>
                                            <li>
                                                <i className="fas fa-podcast f-20 theme-color pr-4"></i>
                                                {religion_details &&
                                                    <span>{religion_details.caste && religion_details.caste.name}{religion_details.sub_caste ? ` , ${religion_details.sub_caste.name}` : ""} {religion_details.gothram ? ` - ${religion_details.gothram}` : ""}</span>
                                                }
                                            </li>
                                            <li>
                                                <i className="fas fa-biohazard f-20 theme-color pr-4"></i>
                                                {`Gothram - ${religion_details.gothram ? "" : "Details not mentioned"}`}
                                            </li>
                                            {
                                                religion_details.star &&
                                                <li>
                                                    <i className="fas fa-star f-20 theme-color pr-4"></i>
                                                    {religion_details.star ? `Star is ${religion_details.star.name},` : ""} {religion_details.raasi ? `Raasi is ${religion_details.raasi.name}` : ""}
                                                </li>
                                            }
                                            {
                                                religion_details.raasi &&
                                                <li>
                                                    <i className="fas fa-globe f-20 theme-color pr-4"></i>
                                                Zodiac sign is {religion_details.raasi.name}
                                                </li>
                                            }
                                            <li>
                                                <i className="fas fa-biohazard f-20 theme-color pr-4"></i>
                                                {`Dosham - ${religion_details.dosham_choices ? religion_details.dosham_choices : "No Dosham"}`}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ProfileBox>
                        </div>
                    }

                    {
                        education_details &&
                        <div className="col-md-12">
                            <ProfileBox
                                edit={edit ? "/manage" : false}
                                title={`${gender_value} professional details`}
                            >
                                <div className="row">
                                    <div className="col-md-12 profile-list-main">
                                        <ul className="pl-3">
                                            {education_details.employed_in &&

                                                <li>
                                                    <i className="fas fa-id-card-alt f-20 theme-color pr-4"></i>
                                                    {`Employed in ${education_details.employed_in.name} Sector`}
                                                </li>
                                            }
                                            {education_details.highest_education &&

                                                <li>
                                                    <i className="fas fa-graduation-cap f-20 theme-color pr-4"></i>
                                                    {`${education_details.highest_education.name} Degree`}
                                                </li>
                                            }
                                            {education_details.occupation &&

                                                <li>
                                                    <i className="fas fa-briefcase f-20 theme-color pr-4"></i>
                                                    {`${education_details.occupation.name}`}
                                                </li>
                                            }
                                            {education_details.annual_income &&
                                                <li>
                                                    <i className="fas fa-rupee-sign f-20 theme-color pr-4"></i>
                                                    {`Rs ${education_details.annual_income / 100000} Lakhs`}
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </ProfileBox>
                        </div>
                    }

                    {
                        user_details &&
                        <div className="col-md-12">
                            <ProfileBox
                                edit={edit ? "/manage" : false}
                                title={`About ${gender_value} loved ones`}
                            >
                                <div className="row">
                                    <div className="col-md-12 profile-list-main">
                                        <ul className="pl-3">
                                            <li>
                                                <i className="fas fa-users f-20 theme-color pr-4"></i>
                                                {`${user_details.family_type} and ${user_details.family_status} with ${user_details.family_values} value`}
                                            </li>
                                            <li>
                                                <i className="fas fa-male f-20 theme-color pr-4"></i>
                                                {`${user_details.no_of_brother === 0 ? "No" : user_details.no_of_brother} Brother ${user_details.no_of_brother_married > 0 ? `, ${user_details.no_of_brother_married} Married` : ""} `}
                                            </li>
                                            <li>
                                                <i className="fas fa-female f-20 theme-color pr-4"></i>
                                                {`${user_details.no_of_sister === 0 ? "No" : user_details.no_of_sister} Sister ${user_details.no_of_sister_married > 0 ? `, ${user_details.no_of_sister_married} Married` : ""} `}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ProfileBox>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProfileDetails
