import { combineReducers } from 'redux'
import userReducers from "./user"
import commonReducers from "./common"
import * as formReducers from "./forms"
import * as matchReducers from "./matches"
import chatReducers from "./chat"
import chatusers from './chatusers';
import chatroom from './chatroom';
import upgradeReducers from "./upgrade"

const reducer = combineReducers({
    ...userReducers, 
    ...commonReducers,
    ...formReducers,
    ...matchReducers,
    ...chatReducers,
    ...upgradeReducers,
    chatusers,
    chatroom
});

export default reducer;