import React from 'react'
import Light, { reduceImages } from './Light';

export default function ProfileImage({ photos = [], profile_pic, children }) {
    return (
        <div className="mb-3">
            <div className="d-flex justify-content-center">
            {
                photos && Array.isArray(photos) && photos.length > 0 ?
                    <Light
                        Gallery={({ openLightbox }) => (
                            <div className="profile-pic">
                                <img onClick={(e) => openLightbox(0, e)} src={profile_pic} alt="" />
                            </div>
                        )}
                        images={reduceImages(photos)}
                    /> :
                    <div className="profile-pic">
                        <img src={profile_pic} alt="" />
                    </div>
            }
            </div>
            {children}
        </div>
    )
}
