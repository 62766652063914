import React, { Component } from 'react'
import { connect } from 'react-redux';
import { closeModal, openModal } from "../../redux/actions/common"

class OpenModalContainer extends Component {

    componentDidMount(){
        this.props.closeModal();
    }

    render() {
        return null
    }
}

class ModalBtnContainer extends Component {
    render() {
        let { closeModal, children, ...button} = this.props;
        return (
            <button {...button} onClick={ () => closeModal()}>
                {children}
            </button>
        )
    }
}

const ModalWrapCon = ({children, closeModal, ...rest}) => (
    <div {...rest} onClick={() => closeModal()}>
        {children}
    </div>
)

//modal container to prevent propagation modal wrapper click
export const ModalCon = ({children, ...rest}) => (
    <div {...rest} onClick={(e) => e.stopPropagation()}>
        {children}
    </div>
)

const OpenModalbtn = ({children, openModal, modalName, modalProps={}, ...rest}) => (<button onClick={() => openModal(modalName, modalProps)} {...rest}>{children}</button>);

export const OpenModalBtn = connect(null, {openModal})(OpenModalbtn);

//close modal button
export const CloseModalBtn = connect(null, {closeModal})(ModalBtnContainer);

//modal wrapper for outside click close
export const CloseModalWrapper = connect(null, {closeModal})(ModalWrapCon);

//auto modal close
const CloseModal = connect(null, {closeModal})(OpenModalContainer)

export default CloseModal

