import React from 'react'
import RegisterLayout from './registerlayout';
import ResetOtpForm from '../../containers/Register/resetotpverify';

const ResetOtpPage = props => (
    <RegisterLayout
        heading={
            <span>Almost there!<br></br> Verify your mobile number</span>
        }
    >
        <ResetOtpForm {...props} />
    </RegisterLayout>
)

export default ResetOtpPage