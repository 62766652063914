import React, { Component } from 'react'
import { connect } from 'react-redux'
import { partnerform } from '../../utils/Forms/partnerinputs';
import List from '../../components/list';
import Center from '../../components/center';
import Formbox from './formbox';
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { Link } from "react-router-dom";
import { reduceForm, getFormNames, validate, scrollToEL } from '../../utils/Forms/validation';
import { partnerSubmit } from '../../redux/actions/user';
import { Redirect } from "react-router-dom";
import FormCon from '../Form/formcon';
import { PARTNER_PREFER, PARTNER_ASTRO } from '../../redux/actions/constants';
import InitPartner from './initpartnerform';
import {setFormValue, setFormErr} from '../../redux/actions/formactions';

const formnames = getFormNames(partnerform);

export class PartnerForm extends Component {

    handleSubmit = e => {
        e.preventDefault();
        let { setFormErr, partnerSubmit } = this.props;
        let isErr = false, elName = null;
        partnerform.forEach(x => {
            const setErr = formName => payload => setFormErr(formName, payload);
            const isvaliderr = validate(this.props[x.inputsName], setErr(x.formName));
            if (isvaliderr) {
                if(!elName) elName = isvaliderr;
                isErr = true;
            }
        });
        if (isErr) {
            if(elName) {
                scrollToEL(elName);
            }
            return;
        }
        let postData = {};
        partnerform.forEach(x => {
            const { inputsName } = x;
            const datalist = this.props[inputsName];
            postData = { ...postData, ...reduceForm(datalist) };
        })
        const { age, height, religion_id, income, dosham_choices, dosham_id,...rest } = postData;
        partnerSubmit({
            age_from: age.min,
            age_to: age.max,
            height_from: height.min,
            height_to: height.max,
            //convert to lakhs
            income_from: income.min * 100000,
            income_to: income.max * 100000,
            ////////
            dosham_choices, 
            dosham_id: dosham_choices === "Yes" ? dosham_id : [],
            religion_id: religion_id ? [religion_id] : [],
            ...rest
        })
    }

    componentDidUpdate(prevProps){
        const {dosham_choices} = reduceForm(this.props.partner_astro);
        const prev_astro = reduceForm(prevProps.partner_astro);
        if(dosham_choices !== prev_astro.dosham_choices){
            this.props.setFormValue(PARTNER_ASTRO, {
                name: "dosham_id",
                data: { type: dosham_choices === "Yes" ? "select" : "hidden"}
            })
        }
    }

    render() {
        if (this.props.formData.data.id) {
            return <Redirect to="/dashboard" />
        }
        let listProps = { RenderItem: Formbox, list: partnerform, title: "partnerform" };
        if(this.props.manage){
            listProps.description = '';
        }
        return (
            <InitPartner>
                <FormCon
                    clear={[PARTNER_PREFER, ...formnames]}
                    onSubmit={this.handleSubmit}
                >
                    <List {...listProps} />
                    <div className="row">
                        <div className={`col-md-12`}>
                            <Center>
                                <div>
                                    {
                                        !this.props.manage &&
                                        <Link to="/dashboard" className="btn btn-outline-req f-15 mr-3 border">
                                            Skip a Step & do later
                                        </Link>
                                    }
                                    <LoadingBtn
                                        onSubmit={this.handleSubmit}
                                        className="btn custom-btn"
                                        disabled={this.props.formData.fetching}
                                        type="submit"
                                        fetching={this.props.formData.fetching}
                                    >
                                        Submit
                                    </LoadingBtn>
                                </div>
                            </Center>
                        </div>
                    </div>
                </FormCon>
            </InitPartner>
        )
    }
}

const mapStateToProps = ({
    partner_basic,
    partner_phy,
    partner_astro,
    partner_edu,
    partner_habits,
    partner_prefer,
    user
}) => ({
    partner_basic,
    partner_phy,
    partner_astro,
    partner_edu,
    partner_habits,
    formData: partner_prefer,
    intialData: user.partner_preferences
})

const mapDispatchToProps = {
    partnerSubmit, setFormValue, setFormErr
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerForm)
