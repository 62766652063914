import React, { Fragment } from "react";
import RejectBtn from "./rejectbtn";
import ShortListBtn from "./shortlistbtn";
import AcceptBtn from "./acceptbtn";

const MatchFunc = props => {
    const {
        id,
        is_shortlist,
        is_ignore,
        send_id,
        type,
        status,
        activeID,
        baseId,
        user
    } = props;
    if (user.is_active === false) {
        return (
            <div className="border-top f-14 text-danger p-2 text-center">
                <i className="fas fa-ban mr-1"></i> Profile is currently
                unavailable
            </div>
        );
    } else {
        return (
            <div className="match-func border-top">
                {send_id && type ? (
                    <Fragment>
                        <div className="match-func-item">
                            <AcceptBtn
                                activeID={activeID}
                                id={send_id}
                                type={type}
                                status={status}
                                is_accept={true}
                            />
                        </div>
                        <div className="match-func-item">
                            <AcceptBtn
                                activeID={activeID}
                                id={send_id}
                                type={type}
                                status={status}
                                is_accept={false}
                            />
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="match-func-item">
                            <ShortListBtn
                                baseId={baseId}
                                activeID={activeID}
                                id={id}
                                is_shortlist={is_shortlist}
                            />
                        </div>
                        <div className="match-func-item">
                            <RejectBtn
                                baseId={baseId}
                                activeID={activeID}
                                id={id}
                                is_ignore={is_ignore}
                            />
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
};

export default MatchFunc;
