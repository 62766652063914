import React, { Component } from 'react'
import { connect } from 'react-redux'
import { OpenModalBtn } from '../../containers/Modal/closemodal';
import { deletePhoto, uploadPhoto } from '../../redux/actions/user';
import { DELETE_PHOTOS } from '../../redux/actions/constants';
import imagePreview from '../../assets/img/upload-avatar.png';

class UploadPhoto extends Component {
    handleClick(e) {
        e.preventDefault();
        let file = e.target.files[0];
        let form_data = new FormData();
        form_data.append('image', file);
        this.props.uploadPhoto(form_data);

    }
    render() {
        const { id, image, is_approved, is_profile_pic } = this.props


        return (
            <div className="col-md-3 col-sm-6 col-12 mb-3">
                <div className="relative">
                    {image && !is_approved && !is_profile_pic &&
                        <div className="ribbon">Pending Approval</div>
                    }
                    {image && is_profile_pic && is_approved &&
                        <div className="ribbon bg-success">Profile pic</div>
                    }
                    <div className="photo-holder">
                        <input type="file" className="image-uploader-wrapper" accept="image/*"
                            onChange={this.handleClick.bind(this)}
                        />
                        {
                            id && image ?
                                <img className="w-100 img-responsive imagepreview album-img-preview" src={image} alt="" /> :
                                <img className="img-responsive imagepreview album-img-preview" src={imagePreview} alt="photos" />
                        }
                        <div className="upload-placeholder"></div>
                    </div>
                    <div className="d-flex">
                        {
                            id ?
                                <React.Fragment>
                                    <OpenModalBtn
                                        className="btn flex-grow-1 br-0 custom-btn f-12"
                                        modalName="CONFIRM_MODAL"
                                        modalProps={{
                                            msg: `Are you sure you want delete this photo ?`,
                                            confirmProps: {
                                                reducerName: "delete_photo",
                                                ntAction: () => deletePhoto(id),
                                                formName: DELETE_PHOTOS
                                            }
                                        }}
                                    >
                                        Delete
                                </OpenModalBtn>
                                </React.Fragment> :
                                <div> </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ upload_photo }) => ({
    upload_photo
})

const mapDispatchToProps = {
    uploadPhoto
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto)



