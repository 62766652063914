import React, { memo, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MatchFunc from "../../containers/Dashboard/matchfunc";

const CardProfile = memo(props => {
    const {
        profile_pic,
        user,
        id,
        religion_details,
        district,
        uid,
        user_details,
        age,
    } = props;

    const [width, setWidth] = useState(window.innerWidth)
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    let isMobile = (width <= 750);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])


    return (

        <div className="bx-shdw p-0">
            <MatchLink
                isMobile={isMobile}
                isUserActive={user.is_active}
                className=""
                to={`/profile/${id}`}
            >
                <div className="bx-shdw-img">
                    <img src={profile_pic} alt={user.first_name}></img>

                </div>
                <div className="bx-shdw-content">
                    <div className="">
                        <div className="bx-shdw-name">{user.first_name}</div>
                        <div className="bx-shdw-id">{uid}</div>
                    </div>

                    <div className="bx-shdw-desc">
                        <span> {age} yrs, </span>
                        {user_details && user_details.height &&
                            <span>{user_details.height.short_name}</span>
                        }
                        <div className='bx-shdw-caste'>{religion_details && religion_details.caste ? religion_details.caste.name : "Not Mentioned"},</div>
                        {
                            district ?
                                <div className='bx-shdw-caste'>{district.name}</div> : ""
                        }
                    </div>

                    <div className="a-1 pt-2 text-center">View Profile</div>

                </div>
            </MatchLink>
            <MatchFunc {...props} />
        </div>


    );
});


export const MatchLink = ({
    isUserActive,
    children,
    isMobile,
    width,
    to,
    ...restProps
}) => {

    if (to && isUserActive !== false) {
        return (
            <Link {...restProps} to={to} target={isMobile ? "" : "_blank"}>
                {children}
            </Link>
        );
    }
    return <div {...restProps}>{children}</div>;
};

export default CardProfile;