import React from 'react'
import ProfileImage from './ProfileImage';
import Gallery from './gallery';
import { OpenModalBtn } from "../../containers/Modal/closemodal";
import ShortListBtn from "../../containers/Dashboard/shortlistbtn";
import RejectBtn from "../../containers/Dashboard/rejectbtn";
import { RequestMobile, RequestPhoto } from '../../containers/Profile/sendrequest';

const ProfilePersonalInfo = ({ userData }) => {
    const { user, age, mother_tongue, district, user_details, profile_pic,
        religion_details, education_details, uid, profile_for,
        photos, is_photo_public, id, is_number_public, is_shortlist, is_ignore } = userData;
    return (
        <div className="row">
            <div className="col-lg-3">

                <ProfileImage photos={photos} profile_pic={profile_pic}>
                </ProfileImage>
                <div className="row">
                    {!is_photo_public && <RequestPhoto hasPhotos={photos && photos.length > 0} postData={id} />}
                </div>
                {photos && is_photo_public && Array.isArray(photos) && photos.length > 0 &&
                    <Gallery checkUserPhotos={true} images={photos} />
                }
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 xs-mt-20 profile-details">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pr-sm-0">
                    <h2 className="name">{user.first_name} {user.last_name}</h2>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pr-sm-0">
                    <div className="small-desc">
                        <span>{uid}</span>
                        <span className="pl-2 pr-2">|</span>
                        <span>Profile created by {[2, 3].includes(profile_for.id) ?
                            `Parents` :
                            [4, 5].includes(profile_for.id) ?
                                `Siblings` : profile_for.name} </span>

                    </div>
                </div>
                <div className='personal-infos'>
                    <ul>
                        <li>{age} yrs, {user_details && user_details.height.name}</li>
                        <li>Mother Tongue is {mother_tongue.name}</li>
                        {religion_details &&
                            <li>{religion_details.caste && religion_details.caste.name}
                                {religion_details.sub_caste ? ` , ${religion_details.sub_caste.name}` : ""}
                                {religion_details.gothram ? ` - ${religion_details.gothram}` : ""}</li>
                        }
                        {
                            religion_details.star &&
                            <li> {religion_details.star ? `Star is ${religion_details.star.name},` : ""} {religion_details.raasi ? `Raasi is ${religion_details.raasi.name}` : ""}</li>
                        }
                        {district &&
                            <li>Lives in {district.name}</li>
                        }
                        {education_details && education_details.highest_education &&
                            < li > Studied {education_details.highest_education.name}. {[1,2].includes(education_details.highest_education.id) ? "":"Degree"}</li>
                        }
                        {education_details && education_details.occupation &&
                            <li>{education_details.occupation.name}</li>
                        }
                        {education_details?.annual_income ?
                            <li>Earns {`Rs ${education_details.annual_income.toLocaleString("en-IN")}`} annually</li> : <li>Income Not Mentioned</li>
                        }
                    </ul>

                </div>
                <div className="ml-2 mr-2">


                    <div className="row mt-4">
                        <div className="col-6 col-md-4">
                            {!is_number_public ? <RequestMobile postData={id} /> :

                                <div className="match-func-item">
                                    <OpenModalBtn
                                        modalName="PHONE_MODAL"
                                        modalProps={userData}
                                        className="btn f-600 f-14 custom-btn btn-block"
                                    >
                                        View Mobile Number
                                </OpenModalBtn>
                                </div>
                            }
                        </div>
                        <div className="col-6 col-md-4">
                            <ShortListBtn
                                profile={true} baseId={id} id={id} is_shortlist={is_shortlist} />
                        </div>
                        <div className="col-6 col-md-4">
                            <RejectBtn profile={true} baseId={id} id={id} is_ignore={is_ignore} />
                        </div>
                    </div>
                </div>


            </div>

        </div >
    )
}

export default ProfilePersonalInfo