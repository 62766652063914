import React from 'react'
import { ModalCon, CloseModalWrapper, CloseModalBtn } from '../../containers/Modal/closemodal';
import ConfirmBtn from '../../containers/Modal/confirmbtn';
import confirmimg from "../../assets/img/warning.png";
import Center from '../center';

//modal name "CONFIRM_MODAL"
const ConfirmModal = ({msg, confirmProps}) => (
    <ConfirmBody 
        msg={msg}
        children={
            <ConfirmBtn 
                {...confirmProps}
            />
        }
    />
)

export const ConfirmBody = ({ msg, children }) => (
    <CloseModalWrapper className="modal-wrapper">
        <ModalCon className="modal-container">
            <div className="p-1">
                <Center className="flex-column">
                    <img className="mb-3" src={confirmimg} alt="" />
                    <p className="text-center">{msg}</p>
                    <div className="d-flex justify-content-end pt-3">
                        <CloseModalBtn className="btn btn-outline-req pr-3 pl-3 mr-3">
                            Cancel
                        </CloseModalBtn>
                        {
                            children
                        }
                    </div>
                </Center>
            </div>
        </ModalCon>
    </CloseModalWrapper>
)

export default ConfirmModal