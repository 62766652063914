import React from 'react'
import { profile_url } from '../../redux/actions/constants';
import Profile from '../../containers/Profile/profile';
import Pagelayout from '../pagelayout';

const ProfilePage = ({match}) => {
    return (
        <Pagelayout>
            <Profile url={profile_url + match.params.id + "/"} />
        </Pagelayout>
    )
}

export default ProfilePage
