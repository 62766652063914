import React from 'react'
import RegisterLayout from './registerlayout';
import OtpForm from '../../containers/Register/otpform';

const OtpPage = () => (
    <RegisterLayout
        heading={
            <span>Almost there!<br></br> Verify your mobile number</span>
        }
    >
        <OtpForm />
    </RegisterLayout>
)

export default OtpPage
