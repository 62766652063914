import React from 'react'

export default ({ title = "", onClick }) => {
    return (
        <div 
            onClick={onClick}
            className="show-md-flex justify-content-between align-items-center border shadow p-3 mb-3"
        >
            <h6 className="m-0">{title}</h6>
            <span className="fas fa-angle-double-down f-18"></span>
        </div>
    )
}
