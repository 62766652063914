import React, { Component } from "react";
import { connect } from "react-redux";
import { quickSearchInputform } from "../../utils/Forms/quicksearchinputs";
import List from "../../components/list";
import Center from "../../components/center";
import LoadingBtn from "../../components/Buttons/loadingbtn";
import {
  reduceForm,
  getFormNames,
  toQuery,
} from "../../utils/Forms/validation";
import { partnerSubmit } from "../../redux/actions/user";
import FormCon from "../Form/formcon";
import { PARTNER_PREFER } from "../../redux/actions/constants";
import Formbox from "../Register/formbox";
import { setFormValue, setFormErr } from "../../redux/actions/formactions";
import InitSearch from "./initSearch";
import { withRouter } from "react-router-dom";

const formnames = getFormNames(quickSearchInputform);

export class QuickSearchformNew extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    let postData = {};
    quickSearchInputform.forEach((x) => {
      const { inputsName } = x;
      const datalist = this.props[inputsName];
      postData = { ...postData, ...reduceForm(datalist) };
    });

    if (postData?.age) {
      postData["max_age"] = postData.age.max;
      postData["min_age"] = postData.age.min;
      delete postData.age;
    }
    return this.props.history.push(`/search/results${toQuery(postData)}`);
  };

  render() {
    let listProps = {
      RenderItem: Formbox,
      list: quickSearchInputform,
      title: "quick_search_form",
    };
    return (
      <InitSearch inputForm={quickSearchInputform}>
        <FormCon
          clear={[PARTNER_PREFER, ...formnames]}
          onSubmit={this.handleSubmit}
        >
          <List {...listProps} />
          <div className="row">
            <div className={`col-md-12`}>
              <Center>
                <div>
                  <LoadingBtn
                    onSubmit={this.handleSubmit}
                    className="btn custom-btn"
                    disabled={this.props.formData.fetching}
                    type="submit"
                    fetching={this.props.formData.fetching}
                  >
                    Submit
                  </LoadingBtn>
                </div>
              </Center>
            </div>
          </div>
        </FormCon>
      </InitSearch>
    );
  }
}

const mapStateToProps = ({ partner_prefer, quick_search_form, user }) => ({
  quick_search_form,
  formData: partner_prefer,

  intialData: user.partner_preferences,
});

const mapDispatchToProps = {
  partnerSubmit,
  setFormValue,
  setFormErr,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuickSearchformNew)
);
