import { Component } from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../redux/actions/common';

class Promote extends Component {

    componentDidMount() {
        const promote = getCookie("action");
        if (!promote) {
            const { openModal } = this.props;
            setCookie();
            let title = `Download Mobile App`;
            let link = `/manage`;
            let btnTitle = `Update profile`;
            let desc = `complete your profile details and partner prefrences to improve your visibility to other profiles.`;
            openModal("DOWNLOAD_APP_MODEL", {
                title, link, btnTitle, desc
            });
        }
    }

    render() {
        return null
    }
}

function setCookie() {
    var d = new Date();
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = "action=promote" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const mapStateToProps = ({ user }) => ({
    user
})

const mapDispatchToProps = {
    openModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Promote)
