import httpAction from "./httpAction";
import { SIGNUP, signup_url, LOGIN, signin_url, REGISTER, register_url, otp_url, OTP_VERIFY, uploadphotos_url, UPLOAD_PHOTOS, PARTNER_PREFER, partnerpreference_url, _CLEAR, changepwd_url, CHANGE_PWD, DELETE_PHOTOS, PRIVACY, privacy_url, LOGOUT, logout_url, RESEND_OTP, resendotp_url, MY_PROFILE, myprofile_url, FORGOTPWD, forgot_url, RESETPWD, resetpwd_url, CONTACT_US, issues_url, UPDATE_USER, SET_PRO_PIC } from "./constants";

//update user
export const updateUser = payload => ({
    type: UPDATE_USER,
    payload
}) 

//register submit
export const signUpSubmit = data => httpAction(SIGNUP, {
    url: signup_url,
    method: "POST",
    data
})

//login
export const loginSubmit = data => httpAction(LOGIN, {
    url: signin_url,
    method: "POST",
    data
})

//register
export const registerSubmit = data => httpAction(REGISTER, {
    url: register_url,
    method: "post",
    data
})

//otp verfication
export const otpSubmit = data => httpAction(OTP_VERIFY, {
    url: otp_url,
    method: "post",
    data
})

export const otpSubmitClear = () => ({ type: `${OTP_VERIFY}${_CLEAR}` });

//resend otp
export const resendOtp = data => httpAction(RESEND_OTP, {
    url: resendotp_url,
    method: "post",
    data
})

//upload photos
export const uploadPhoto = data => httpAction(UPLOAD_PHOTOS, {
    url: uploadphotos_url,
    method: "post",
    crossDomain: true,
    contentType: false,
    processData: true,
    data
})

//delete photo
export const deletePhoto = id => httpAction(DELETE_PHOTOS, {
    url: `users/${id}/delete_photo/`,
    method: "DELETE"
})

export const setProPic = id => httpAction(SET_PRO_PIC, {
    url: `users/${id}/set_profile_pic/`,
    method: "GET"
})

//partner preference
export const partnerSubmit = data => httpAction(PARTNER_PREFER, {
    url: partnerpreference_url,
    method: "post",
    data
})

export const partnerSubmitClear = () => ({ type: `${PARTNER_PREFER}${_CLEAR}` });

//change password
export const changePwd = data => httpAction(CHANGE_PWD, {
    url: changepwd_url,
    method: "POST",
    data
})

//edit privacy
export const editPrivacy = data => httpAction(PRIVACY, {
    url: privacy_url,
    method: "POST",
    data
})

//logout
export const logout = () => httpAction(LOGOUT, {
    url: logout_url,
    method: "POST"
})

//my profile
export const myProfile = () => httpAction(MY_PROFILE, {
    url: myprofile_url
})

//forgot password
export const forgotPwd = data => httpAction(FORGOTPWD, {
    url: forgot_url,
    method: "POST",
    data
})

//reset password
export const resetPwd = data => httpAction(RESETPWD, {
    url: resetpwd_url,
    method: "POST",
    data
})

//contact us
export const sendIssue = data => httpAction(CONTACT_US, {
    url: issues_url,
    method: "POST",
    data
})