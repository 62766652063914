import React from "react";
import Section from "../section";
import Recentlyjoined from "../../containers/Landing/recentlyjoined";

const RecentMembers = () => (
    <Section className="landing-section-pad greybg">
        <div className="col-12 pb-4">
            <h3 className="text-center mb-1">RECENTLY JOINED</h3>
            <h2 className="horizontalline">
                <span className="horizontaltext">
                    <i className="fa fa-heart theme-color" aria-hidden="true"></i>
                </span>
            </h2>
        </div>
        <div className="col-12 pt-2">
            <div className="row">
                <Recentlyjoined />
            </div>
        </div>
    </Section>
);

export default RecentMembers;
