import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearForm } from '../../redux/actions/formactions';
import { addToast } from '../../redux/actions/common';

class FormCon extends Component {

    clear = () => {
        const {clear, clearForm} = this.props;
        clear.forEach(cl => 
            clearForm(cl)    
        )
    }

    componentDidUpdate(prevProps){
        const { unMount, toastMsg, addToast } = this.props;
        if(unMount && unMount !== prevProps.unMount){
            if(toastMsg){
                addToast(toastMsg);
            }
            this.clear();
        }
    }

    render() {
        const {children, unMount, clearForm, clear,  toastMsg, addToast, ...rest} = this.props;
        return (
            <form noValidate {...rest}>
                {children}
            </form>
        )
    }
    
    componentWillUnmount(){
        this.clear();
    }
}

const mapDispatchToProps = {
    clearForm, addToast
}

export default connect(null, mapDispatchToProps)(FormCon)
