import React from 'react'
import RegisterLayout from './registerlayout';
import EditEmail from '../Manage/EditEmail';
import { connect } from 'react-redux'
import { Redirect } from "react-router-dom"

const ChangeNoPage = ({ user }) => {
    if (!user.token) {
        return <Redirect to="/" />
    }
    else {
        return (
            <RegisterLayout
                heading={
                    <span>No problem<br></br> You can update your Phone number here</span>
                }
            >
                <EditEmail
                    isPhonePage
                />
            </RegisterLayout>
        )
    }
}

const mapStateToProps = ({ user }) => ({
    user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNoPage)
