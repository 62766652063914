import React from 'react';
import ActivityNav, { ActivityLinks } from './activityNav'
import RouteTabs from '../Routes/routetabs';
import { Activitytabs } from '../../containers/Activity/Activity'




const Activity = () => {
    return (
        <div className="container custom-container pt-3 pb-3">
            <div className="d-flex mt-2 mb-2">
                <div className="col-md-9 col-sm-12 col-12">
                    <div className="f-600 f-24 clr-black1">
                        Matches based on your preferences
                    </div>
                </div>
                <div className="col-md-3 d-none d-sm-block">
                </div>
            </div>
            <div className="d-flex">
                <div className="col-12">
                    <ActivityNav links={ActivityLinks} />
                </div>

            </div>

            <div className="d-flex">
                <div className="col-md-12 col-sm-12 col-12">
                    <div className="bx-shdw">
                        <RouteTabs
                            title="activity"
                            tabs={Activitytabs}
                        />

                    </div>
                </div>
            </div>

        </div>
    );
};

export default Activity;