import React from 'react'
import Editemail from '../../containers/Manage/editemail';
import EditPhone from '../../containers/Manage/EditPhone';

export default function EditEmail({ isPhonePage = false }) {
    return (
        <div className="row">
            <div className={`col-md-12`}>
                <div className={`form-box border mb-4`}>
                    <div className="container pt-3 pb-3 border-bottom">
                        <div className="row">
                            <div className="col-md-12">
                                <h5 className="m-0 pl-1">
                                    { isPhonePage ? "Change phone number" : "Edit Email & Phone number" }
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="container pt-3 pb-3">
                        { 
                            !isPhonePage && 
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Email</label>
                                </div>
                                <div className="col-md-8">
                                    <Editemail />
                                </div>
                            </div> 
                        }
                        <div className="row">
                            <div className="col-md-4">
                                <label>Phone Number</label>
                            </div>
                            <div className="col-md-8">
                                <EditPhone  isPhonePage={isPhonePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
