import React from 'react'
import { connect } from 'react-redux'

const Filterbtn = ({ title, filter, chatusers, dispatch }) => {
    return (
        <button 
            className={`btn col btn-chat-filter border-right ${chatusers.id === filter ? "logo-red-color" : ""}`}
            onClick={() => dispatch({
                type: "SET_CHAT_USER_FILTER",
                filter: filter
            })}
        >
            { title }
        </button>
    )
}

const mapStateToProps = ({ chatusers }) => ({
    chatusers
})

export default connect(mapStateToProps)(Filterbtn)
