import React, { Component } from 'react'
import { urlParams } from '../../utils/urlParams';
import Submitbtn from '../common/submitbtn';
import { Redirect } from "react-router-dom"
import { verifyEmail_url } from '../../redux/actions/constants';
import icon from "../../assets/img/correct.png"
import { updateUser } from '../../redux/actions/user';
import { connect } from 'react-redux'
import { addToast } from '../../redux/actions/common';

class EmailVer extends Component {
    render() {
        const { location, history, addToast, updateUser  } = this.props;
        if (!location.search) {
            addToast("Invalid Email verification url", false);
            return <Redirect to="/" />
        }
        const { uid, token } = urlParams(location.search);
        if (!uid && !token) {
            addToast("Invalid Email verification url", false);
            return <Redirect to="/" />
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-6 flex-center pb-5 text-center">
                        <div className="p-4 mt-5 mb-5 border">
                            <img src={icon} className="mb-3" alt="" />
                            <h4>Verify your Email</h4>
                            <p>
                                You have landed on the email verification page.<br></br>
                                Please click the below button to verify email and go to Dashboard.
                            </p>
                            <Submitbtn
                                className="btn custom-btn"
                                children="Go to Dashboard"
                                config={{
                                    url: verifyEmail_url,
                                    method: "POST",
                                    data: {
                                        uid, token, client: Math.random() * 1000
                                    }
                                }}
                                succFunc={(data) => {
                                    updateUser(data);
                                    history.replace("/");
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, { addToast, updateUser })(EmailVer)
