import React from 'react'
import Content from './content';
import Section from '../section';
import PageHeading from './pageheading';

const WhyTharagar = () => {
    return (
        <div>
            <PageHeading heading="Why Tharagar?" />
            <Section className="pt-4">
                <div className="col-md-12">
                    <Content heading="Profile Validation">
                        <p>All registered profiles are phone verified to ensure genuineness</p>
                    </Content>
                    <Content heading="Convenient">
                        <p>Receive perfectly matched and shortlisted profiles in your inbox.</p>
                    </Content>
                    <Content heading="Safe And Secure">
                        <p>Confidentiality is maintained across our matrimonial website to ensure enhanced security.</p>
                    </Content>
                    <Content heading="Intuitive Technology">
                        <p>Our proprietary Compatibility Meter utilizes intelligent matchmaking algorithms to ensure that you only receive profiles with the highest compatibility ratio.</p>
                    </Content>
                    <Content heading="Comprehensive Support">
                        <p>Santhosham Matrimony offers extensive assistance to ensure you have a seamless experience.</p>
                    </Content>
                    <Content heading="Advanced Technology">
                        <p>Santhosham Matrimony utilizes advanced matchmaking technology to ensure quicker results and customized support.</p>
                    </Content>
                    <br></br>
                </div>
            </Section>
        </div>  
    )
}

export default WhyTharagar
