import React, {memo} from 'react'
import ContentLoader from "react-content-loader"
import Createroom from '../../containers/Chat/createroom';
import dateFormat from '../../utils/dateformat';

const Chatuser = memo( ({ fetching, actor, updated_on, last_message, status, requested_me, id }) => {
    if (fetching) {
        return <Chatuserldr />
    }
    return (
        <Createroom
            room={id}
        >
            <div>
                <img src={actor.profile_pic} alt="" className="rounded-circle chatuser-image" />
                <div className="chatuser-details">
                    <div className="d-flex align-items-center justify-content-between">
                        <h6 className="ellipsis">{actor.full_name}</h6>
                        {updated_on && <small>{dateFormat(updated_on)}</small>}
                    </div>
                    {last_message && <p className="ellipsis f-14 m-0 chat-user-msg">{last_message}</p>}
                </div>
            </div>
        </Createroom>
    )
})

export const Chatuserldr = props => (
    <ContentLoader
        height={86}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="87" y="53.67" rx="4" ry="4" width="219.96" height="7.55" />
        <rect x="90" y="23.4" rx="3" ry="3" width="114.75" height="8.64" />
        <circle cx="46" cy="42" r="30" />
    </ContentLoader>
)

export default Chatuser
