import React from 'react'
import Matches from '../../components/Dashboard/matches';
import listhoc from '../../hocs/listhoc';
import {SEARCH_MATCHES, SIMILAR_MATCHES } from '../../redux/actions/constants';
import DashMatches from './dashmatches';
import ActvMatches from './actvmatches';
import RequestMatches from './reqmatches';
import ReciMatches from './recimatches';

const DashBoardMatch = ({ size = 3, ...restProps }) => (
    <div className={`col-md-${size} col-6 my-card-profile`} >
        <Matches baseId={restProps.id} {...restProps} />
    </div>
)


const SimMatch = (props) => <DashBoardMatch size={12} {...props} />

export const SearchMatch = listhoc(DashBoardMatch, SEARCH_MATCHES, 'search_matches', null);
export const SimilarMatch = listhoc(SimMatch, SIMILAR_MATCHES, 'similar_matches', null);


export const dashtabs = [
    {
        path: "/",
        exact: true,
        component: () => <DashMatches size={3} type={1} />
    },
    {
        path: "/new-matches",
        component: () => <DashMatches type={2} />
    },
    {
        path: "/all-matches",
        component: () => <DashMatches type={3} />
    },
    {
        path: "/number-viewed",
        component: () => <ActvMatches type={1} />
    }, {
        path: "/profile-viewed",
        component: () => <ActvMatches type={2} />
    }, {
        path: "/rejected-profiles",
        component: () => <ActvMatches type={3} />
    }, {
        path: "/shortlisted-profiles",
        component: () => <ActvMatches type={4} />
    }, {
        path: "/viewed-my-profile",
        component: () => <ActvMatches type={10} />
    }, {
        path: "/viewed-my-number",
        component: () => <ActvMatches type={11} />
    }, {
        path: "/shortlist-my-profile",
        component: () => <ActvMatches type={12} />
    }, {
        path: "/sent-requests",
        component: () => <RequestMatches />
    }, {
        path: "/recieved-requests",
        component: () => <ReciMatches />
    }
]; 