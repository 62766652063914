import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeModal } from '../../redux/actions/common';
import { clearForm } from '../../redux/actions/formactions';
import Loader from './Loader';

//modal name: "PAGE_LOADER" modal props reducerName
export class PageLoaderModal extends Component {

    componentDidUpdate(prevProps) {
        const { closeModal, formData } = this.props;
        if (!formData.fetching) {
            closeModal();
        }
    }

    render() {
        return (
            <div className="modal-wrapper">
                <div className="page-loader-container">
                   <Loader />
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        const { formName, clearForm } = this.props;
        clearForm(formName)
    }

}

const mapStateToProps = (state, props) => ({
    formData: state[props.reducerName]
})

const mapDispatchToProps = {
    closeModal, clearForm
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLoaderModal)
