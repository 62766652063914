export const init = {
    "gender": null,
    "marital_status": [],
    "mother_tongue": [],
    "age_from": 21,
    "age_to": 30,
    "height_from": 150,
    "height_to": 180,
    "income_from": 100000,
    "income_to": 1000000,
    "religion": [],
    "citizenship": [],
    "state": [],
    "district": [],
    "caste": [],
    "sub_caste": [],
    "education": [],
    "employed_in": [],
    "occupation": [],
    "star": [],
    "raasi": [],
    "dosham": [],
    "food_habits": ["Doesn't matter"],
    "smoking_habits": ["Doesn't matter"],
    "drinking_habits": ["Doesn't matter"],
    "dosham_choices": "No"
};
