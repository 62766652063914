import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/datepicker.css";
import { subYears } from '../../utils/dateformat';

const DateIp = ({value, ...restProps}) => {

    //minimum 18 years
    const maxDate = subYears(18);
    //maximum 18 years
    const minDate = subYears(70);

    return (
        <div>
            <DatePicker 
                selected={value}
                dateFormatCalendar={"MMM yyyy"}
                minDate={minDate}
                maxDate={maxDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                {...restProps}
            />
        </div>
    )
}

export default DateIp
