import React  from 'react'
import EditProfileForm from "./editprofile";

export default () => (
    <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
            <EditProfileForm register />
        </div>
    </div>
)
