import React from 'react'
import { withRouter } from "react-router-dom"
import Footer1 from './footer1';
import "../../assets/css/footer.css";

const Footer = ({ location }) => {
    if (location.pathname.indexOf("/chat") > -1) {
        return null;
    }
    return (
        <Footer1 />
    )
}

export default withRouter(Footer)
