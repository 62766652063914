import React from 'react'
import EmailVer from '../../containers/Register/emailverify';
import PageHeading from './pageheading';

function EmailVerify(props) {
    return (
        <React.Fragment>
            <PageHeading heading="Email Verification" />
            <EmailVer {...props} />
        </React.Fragment>
    )
}

export default EmailVerify
