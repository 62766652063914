import React from 'react'
import Partnerform from '../../containers/Register/partnerform';

const EditPartner = () => {
    return (
        <div>
            <Partnerform manage={true} />
        </div>
    )
}

export default EditPartner