import React from 'react'
import { ModalCon, CloseModalWrapper, CloseModalBtn } from '../../containers/Modal/closemodal';
import { ImageCropper } from '../../containers/Image/imagecropper';

//modal name CROP_IMAGE_MODAL
const CropModal = () => (
    <CloseModalWrapper className="modal-wrapper">
        <ModalCon className="modal-container" style={{maxWidth: `580px`}}>
            <CloseModalBtn className="btn-a close btn-close">×</CloseModalBtn>
            <ImageCropper />
        </ModalCon>
    </CloseModalWrapper>
)

export default CropModal
