import React from 'react'
import SubmitBtn from '../common/submitbtn';
import { connect } from 'react-redux'
import { user_activities_url } from '../../redux/actions/constants';
import { addToast } from '../../redux/actions/common';
import { Link } from "react-router-dom"

const mapStateToProps = ({user}) => ({
    user
})

// request mobile number
export const RequestMobile = connect(mapStateToProps)( ({postData, user, dispatch}) => (
    <div className="col-md-12 req-pad">
        {
            user.is_premium ?
            <SubmitBtn 
                className="btn btn-block f-14 custom-btn mb-3"
                config={{
                    url: `${user_activities_url}${postData}/number_request/`
                }}
                succFunc={(payload) => {
                    dispatch(addToast("Number request sent"))
                }}
            >
                Request Number
            </SubmitBtn> : 
            <Link onClick={() => dispatch(addToast("Upgrade to send number request", false))} className="btn btn-block f-14 custom-btn mb-3" to="/upgrade">
                Request Number
            </Link>
        }
    </div>
))

// request mobile number
export const RequestPhoto = connect(null)( ({postData, hasPhotos, dispatch}) => (
    <div className="col-md-6 req-pad">
        <SubmitBtn 
            className="btn btn-block f-14 custom-btn mb-3"
            config={{
                url: `${user_activities_url}${postData}/photo_request/`
            }}
            succFunc={(payload) => {
                dispatch(addToast("Photo request sent"))
            }}
        >
            {hasPhotos ? "Request Photo" : "Ask Photo"}
        </SubmitBtn>
    </div>
))
