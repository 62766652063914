import React from 'react'
import AdvForm from '../../containers/Search/advsearchform';

const AdvSearch = () => {
    return (
        <div className="row mt-4">
            <AdvForm />
        </div>
    )
}

export default AdvSearch
