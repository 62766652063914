import React from 'react'
import CardProfile from '../../components/Dashboard/cardProfile';
import listhocpaginations from '../../hocs/listhocpaginations';
import { activities_url, PROFILE_VIEWED_BY_ME, PROFILE_SHORTLISTED_BY_ME, NUMBER_VIEWED_BY_ME, WHO_VIEWED_NUMBER, WHO_VIEWED_MY_PROFILE, WHO_SHORTLISTED_YOU } from '../../redux/actions/constants';
import MatchesLoaders from '../../components/Loaders/loaders'


const DashBoardMatch = ({ actor }) => (
    <div className={`col-md-2 col-6 pl-2 pr-2`}>
        <CardProfile {...actor} />
    </div>
)


const withldr = (a, b, c, d) => listhocpaginations(a, b, c, d, MatchesLoaders);


const ProfileViewedByMe = withldr(DashBoardMatch, PROFILE_VIEWED_BY_ME, 'profile_viewed_by_me', activities_url + `?type=${1}`);
const ProfileShortlistedByMe = withldr(DashBoardMatch, PROFILE_SHORTLISTED_BY_ME, 'profile_shortlisted_by_me', activities_url + `?type=${2}`);
const NumberViewedByMe = withldr(DashBoardMatch, NUMBER_VIEWED_BY_ME, 'number_viewed_by_me', activities_url + `?type=${3}`);
const WhoViwedMyProfile = withldr(DashBoardMatch, WHO_VIEWED_MY_PROFILE, 'who_viewed_my_profile', activities_url + `?type=${4}`);
const WhoShortlistedMe = withldr(DashBoardMatch, WHO_SHORTLISTED_YOU, 'who_shortlisted_me', activities_url + `?type=${5}`);
const WhoViewedNumber = withldr(DashBoardMatch, WHO_VIEWED_NUMBER, 'who_viewed_number', activities_url + `?type=${6}`);




export const Activitytabs = [
    {
        path: "/",
        exact: true,
        component: ProfileViewedByMe
    },
    {
        path: "/shortlisted-by-me",
        component: ProfileShortlistedByMe
    },
    {
        path: "/me-viewed-number",
        component: NumberViewedByMe
    },
    {
        path: "/who-viewed-you",
        component: WhoViwedMyProfile
    },
    {
        path: "/who-shortlisted-you",
        component: WhoShortlistedMe
    },
    {
        path: "/who-viewed-your-number",
        component: WhoViewedNumber
    },


]; 