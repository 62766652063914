import React, { Component} from 'react'

export default class DetailArr extends Component {

    constructor(props){
        super(props);
        this.state = {
            more: false
        };
        this.max = 6;
    }

    render() {
        const {detailKey} = this.props;
        const list = this.state.more ? detailKey : 
        detailKey.slice(0, detailKey.length > this.max ? this.max : detailKey.length)
        return (
            <span>
                {list.map(x => x.name).join(", ") + " "} 
                {detailKey.length > this.max && 
                    <button 
                        onClick={() => this.setState(prev => ({ more: !prev.more }))} 
                        className="btn btn-link f-12"
                    >
                       {this.state.more ? `View less` : `View all` }
                    </button>
                }
            </span>
        )
    }
}
