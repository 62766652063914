import React from 'react'
import { ModalCon, CloseModalWrapper } from '../../containers/Modal/closemodal';
import GenDesc from '../../containers/Register/GenDesc';

//modal name AUTO_DESC_MODAL
const AutoDescModal = () => (
    <CloseModalWrapper className="modal-wrapper">
        <ModalCon className="modal-container" style={{maxWidth: `450px`}}>
           <GenDesc />
        </ModalCon>
    </CloseModalWrapper>
)

export default AutoDescModal