import React from 'react';
import List from '../../components/list';
import { NavLink } from "react-router-dom"

export const matchesLinks = [
    {
        links: [
            {
                to: "/matches",
                title: "Yet To be Viewed",
            },
            {
                to: "/matches/recently-joined",
                title: "Recently Joined",
            },
            {
                to: "/matches/my-community",
                title: "My Community",
                icon: "fa fa-handshake"
            },
            {
                to: "/matches/no-caste",
                title: "Caste No Bar",

            },
        ]
    }

]

const MatchNav = ({ links }) => {
    return (
        <div className="">
            <List
                RenderItem={ManageBox}
                list={links}
                title="manage-box"
            />

        </div>
    );
};


export const ManageBox = ({ links }) => (
    <ul className="list-inline p-1">
        <List
            RenderItem={ManageTabLink}
            list={links}
            title="manage-links"
        />
    </ul>
)

export const ManageTabLink = ({ to, title, external }) => (
    <li className="list-inline-item nav-item pl-2 pb-2">
        {external ? <a href={to} target="_blank" rel="noopener noreferrer">{title}</a> :
            <NavLink className="btn btn-matches-normal" exact activeClassName="btn-matches-active" to={to}>
                {title}
            </NavLink>}
    </li>
)


export default MatchNav;