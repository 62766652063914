import { _CLEAR, _ON_CHANGE, _UPDATE_OPTIONS, _SET_ERR, _SET_VALUE, _ADD_FORM, _REMOVE_FORM, _LOAD_FORM, _CLEAR_DES } from "../actions/constants";

const updateInput = (state, name, update) => state.map(x => {
    if (name === x.inputProps.name) {
        return {
            ...x,
            err: '',
            inputProps: {
                ...x.inputProps,
                ...update
            }
        }
    }
    return x
})

//update options
const updateOp = (state, name, options) => state.map(x => {
    if (name === x.inputProps.name) {
        return {
            ...x,
            err: '',
            inputProps: {
                ...x.inputProps,
                options
            }
        }
    }
    return x
})

//clear descend value
const clearDes = (state, name) => state.map(x => {
    if (name === x.inputProps.name) {
        return {
            ...x,
            inputProps: {
                ...x.inputProps,
                value: x.inputProps.isMulti ? [] : null
            }
        }
    }
    return x
})

//create http reducer function
export const formReducer = (name, initialState = []) => {
    return (state = initialState, action) => {
        switch (action.type) {
            case `${name}${_ON_CHANGE}`:
                return updateInput(state, action.payload.name, { value: action.payload.value });
            case `${name}${_UPDATE_OPTIONS}`:
                return updateOp(state, action.payload.name, action.payload.options);
            case `${name}${_SET_ERR}`:
                return state.map(x => {
                    if (action.payload.name === x.inputProps.name) {
                        return {
                            ...x,
                            err: action.payload.err
                        }
                    }
                    return x
                })
            case `${name}${_SET_VALUE}`:
                return updateInput(state, action.payload.name, action.payload.data);
            case `${name}${_CLEAR_DES}`:
                return clearDes(state, action.payload);
            case `${name}${_ADD_FORM}`:
                return [...state, ...action.payload]
            case `${name}${_REMOVE_FORM}`:
                return state.filter(x => x.inputprops.name !== action.payload);
            case `${name}${_LOAD_FORM}`:
                return action.payload
            case `${name}${_CLEAR}`:
                return initialState
            default:
                return state
        }
    }
}