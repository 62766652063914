import { _ON_CHANGE, _UPDATE_OPTIONS, _SET_ERR, _SET_VALUE, _ADD_FORM, _REMOVE_FORM, _CLEAR, _LOAD_FORM, _CLEAR_DES } from "./constants";

export const handleInputChange = (name, payload) => {
    return {
        type: `${name}${_ON_CHANGE}`,
        payload
    }
}

export const updateOptions = (name, payload) => {
    return {
        type: `${name}${_UPDATE_OPTIONS}`,
        payload
    }
}

//payload = { name: inputname, err: "invalid" }
export const setFormErr = (name, payload) => {
    return {
        type: `${name}${_SET_ERR}`,
        payload
    }
}

//payload = { name: inputname, data: {value: ''} }
export const setFormValue = (name, payload) => {
    return {
        type: `${name}${_SET_VALUE}`,
        payload
    }
}

//payload: ""
export const clearDes = (name, payload) => {
    return {
        type: `${name}${_CLEAR_DES}`,
        payload
    }
}

export const addForm = (name, payload = []) => {
    return {
        type: `${name}${_ADD_FORM}`,
        payload
    }
}

export const removeForm = (name, payload = "") => {
    return {
        type: `${name}${_REMOVE_FORM}`,
        payload
    }
}

//clear form
export const clearForm = name => ({type: `${name}${_CLEAR}`});

//load form
export const loadForm = (name, payload) => ({type: `${name}${_LOAD_FORM}`, payload });


