import React, { Component } from 'react'
import { connect } from 'react-redux'
import MatchBtn from '../../components/Buttons/matchbtn';
import { withRouter } from "react-router-dom"
import chaticon from "../../assets/img/func/chat.png";
import { addLocalChatUser, updateChatUser, createRoom } from '../../redux/actions/chat';

export const createChatObj = (chatUser, addLocalChatUser, createRoom, history) => {
    const { id, uid, profile_pic, user, chat_room } = chatUser;
    const full_name = user.first_name + " " + user.last_name;
    const actor = { id, uid, profile_pic, full_name };
    const roomId = chat_room ? chat_room.id : "LOCAL_USER";
    const status = chat_room ? chat_room.status : "NEW_ROOM";
    const requested_me = chat_room ? chat_room.requested_me : false;
    const chatuser = {
        actor,
        updated_on: new Date(),
        last_message: "",
        status,
        id: roomId,
        requested_me,
    };
    addLocalChatUser(chatuser);
    createRoom(roomId);
    if(history) {
        history.push("/chat");
    }
}

export class StartChat extends Component {

    handleClick = () => {
        const { chatUser, addLocalChatUser, history, createRoom } = this.props;
        createChatObj(chatUser, addLocalChatUser, createRoom, history);
    }

    render() {
        return (
            <MatchBtn
                onClick={this.handleClick}
            >
                <img src={chaticon} alt="" />
            </MatchBtn>
        )
    }
}


const mapStateToProps = ({ chatusers, user }) => ({
    chatusers, user
})

const mapDispatchToProps = {
    addLocalChatUser, updateChatUser, createRoom
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartChat))
