import React from 'react'
import LftPanelProfile from './lftpanelProfile';
import Pagelayout from '../pagelayout';
import LoadDataHoc from '../../hocs/loaddatahoc';
import { DASHBOARD_PROFILES, dashboard_url } from '../../redux/actions/constants';
import ProfileCardBox from '../../containers/Dashboard/profileCardBox';
import ads from '../../assets/img/ads_assisted.png'
import Promote from '../../containers/Dashboard/Promote';
import MobileProfile from './mobileviewProfie';

const DashboardPage = ({ apiData }) => {
    return (
        <Pagelayout>
            <Promote />
            <div className="container custom-container pt-3 pb-3">
                <div className="d-flex">
                    <div className="col-lg-9 col-12">
                        <div className="row">
                            <div className=" col-12 d-block d-sm-none">
                                <MobileProfile />
                            </div>
                            <div className="col-3 d-none d-sm-block">

                                <div className="lft-panelbg-home">
                                    <LftPanelProfile />
                                </div>

                            </div>

                            <div className="col-12 col-md-9">

                                <ProfileCardBox name={"New Matches"} count={apiData.data.new_matches_count} url={"/matches"}
                                    apiData={apiData} data={apiData.data.new_matches} />

                                <ProfileCardBox name={"Recomanded Profile"} count={apiData.data.updated_profile_count}
                                    apiData={apiData} data={apiData.data.updated_profile} url={"/matches/my-community"} />

                                <ProfileCardBox actor={true} name={"Shortlist Profile"} url={"/activity/shortlisted-by-me"}
                                    apiData={apiData} data={apiData.data.shortlist} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 d-none d-sm-block">
                        <div className="bx-shdw">
                            <img src={ads} alt="ads-banner" width="100%" />

                        </div>
                    </div>
                </div>
            </div>
        </Pagelayout >
    )
}


export default LoadDataHoc(DashboardPage, DASHBOARD_PROFILES, "dashboard_profiles", dashboard_url);

