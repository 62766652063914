import React from 'react'
import QuickSearchformNew from '../../containers/Search/basicSearchNew';

const QuickSearch = () => {
    return (
        <div className="row mt-4 mt-sm-2">
            <QuickSearchformNew />
            
        </div>
    )
}

export default QuickSearch
