import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import Shortmenu from '../../components/common/shortmenu';

class ManageTab extends Component {

    state = {
        open: false
    };

    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname ) {
            this.setState({ open: false });
        }
    }

    render() {
        const  { linksList, location, children } = this.props;
        const title = linksList.filter(x => x.to === location.pathname);
        const { open } = this.state;
        return (
            <div>
                <Shortmenu 
                    open={this.state.open} 
                    title={title.length > 0 ? title[0].title : ""}
                    onClick={() => this.setState(prev => ({open: !prev.open}))}
                />
                {
                    open &&
                    <div className="">
                        {children}
                    </div>
                }
                <div className="hide-md">
                    {children}
                </div>
            </div>
        )
    }
}

const ManageShortTabs = withRouter(ManageTab);

export default ManageShortTabs