import React, { Component } from 'react'
import apiInstance from '../redux/apiInstace';
import ajaxerrmsg from '../utils/ajaxerrmsg';

export default class FetchData extends Component {

    didCancel = false;

    state = {
        fetching: false,
        data: null,
        error: false
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if(this.props.url !== prevProps.url){
            this.fetchData();
        }
    }

    fetchData = async () => {
        const { url } = this.props;
        this.setState({ fetching: true });
        try {
            const res = await apiInstance({
                url
            });
            if(!this.didCancel) {
                this.setState({ fetching: false, data: res.data });
            }
        } catch (err) {
            if(!this.didCancel) {
                this.setState({ fetching: false, error: err.response ? ajaxerrmsg(err.response.data) : 'Error' });
            }
        }
    }

    render() {
        const { url, WrappedComp, ...rest } = this.props;
        return (
            <WrappedComp
                {...this.state}
                {...rest}
            />
        )
    }

    componentWillUnmount() {
        this.didCancel = true;
    }

}