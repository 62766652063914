import React from 'react'
import { ModalCon, CloseModalWrapper, CloseModalBtn } from '../../containers/Modal/closemodal';
import Center from '../center';
import tip from "../../assets/img/logo.png";
import googleplaystore from "../../assets/img/google-play-store.png";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'

//modal name PROMOTE_MODAL
const DownloadAppModal = ({ user, title = "", link = "/", btnTitle = "", desc = "" }) => {
    return (
        <CloseModalWrapper className="modal-wrapper">
            <ModalCon className="phone-modal-container slideInDown">
                <div className="bg-white pt-4 pb-4 pl-3 pr-3">
                    <Center>
                        <img src={tip} alt="" height='60' />
                    </Center>
                    <div className="text-center mt-3">
                        <h5>{title}</h5>

                        <div className="d-flex mb-3 mt-3 justify-content-center align-item-center">
                            <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.santhoshammatrimony_app" }} target="_blank">
                                <img src={googleplaystore} alt="google-play-store" style={{ width: "250px", height: "80px", objectFit: "cover" }} />
                            </Link>
                            {/* <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.santhoshammatrimony_app" }} target="_blank">
                                <img src={appleappstore} alt="google-play-store" style={{ width: "250px", height: "70px", objectFit: "contain" }} />
                            </Link> */}
                        </div>
                        <CloseModalBtn className="btn btn-block btn-rounded btn-link f-14">
                            Close
                        </CloseModalBtn>
                    </div>
                </div>
            </ModalCon>
        </CloseModalWrapper>
    )
}


const mapStateToProps = ({ user }) => ({
    user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAppModal)
