import React from 'react'
import { Link } from "react-router-dom"
import DetailArr from '../../containers/Profile/detailArr';

const ProfileBox = ({ children, title, edit }) => (
    <div className="profile-box mb-3">
        <div className="profile-box-head">
            <span className="m-0 f-18 f-600">{title}</span>
            {edit && <Link className="float-right f-12" to={edit}>
                <i className="fas fa-pen"></i>
            </Link>}
        </div>
        <div className="profile-box-content">
            {children}
        </div>
    </div>
);

export const ProfileContent = ({ title, detailKey, verified = false }) => {
    if (detailKey !== null || detailKey !== undefined) {
        return (
            <li className={`d-flex pb-1 profile-content`}>
                <div className="col-lg-3 col-md-3 col-sm-3 pr-1 d-flex justify-content-between align-items-start">
                    <p className="content-title">{title}</p>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 pl-0">
                    <p className="medium-txt1">
                        {
                            Array.isArray(detailKey) ?
                                detailKey.length > 0 ?
                                    <DetailArr detailKey={detailKey} />
                                    : "Any" :
                                detailKey
                        }
                        {
                            verified &&
                            <span className="ml-2 f-14 color-green">
                                <i className="fas fa-certificate f-13 mr-1"></i>
                                Verified
                            </span>
                        }
                    </p>
                </div>
            </li>
        )
    }
    return null
}

export default ProfileBox
