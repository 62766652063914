import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from './formbox';
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { setFormErr } from '../../redux/actions/formactions';
import { validate, reduceForm } from '../../utils/Forms/validation';
import { Redirect } from "react-router-dom";
import FormCon from '../Form/formcon';
import { FORGOT_FORM } from '../../redux/actions/constants';
import { forgotPwd } from '../../redux/actions/user';

export class ForgotForm extends Component {

    handleSubmit = e => {
        e.preventDefault();
        const { setFormErr, forgot_input, forgotPwd } = this.props;
        const setErr = payload => setFormErr(FORGOT_FORM, payload);
        const isErr = validate(forgot_input, setErr);
        if (isErr) {
            return;
        }
        const { mobile_number } = reduceForm(forgot_input);
        forgotPwd({ mobile_number });
    }

    render() {
        const { forgot_pwd } = this.props;
        if (forgot_pwd.data.mobile_number) {
            return <Redirect to="/reset-otp-verify" />
        }
        return (
            <div>
                <FormCon
                    clear={[FORGOT_FORM]}
                    onSubmit={this.handleSubmit}
                >
                    <Formbox
                        title="Forgot password"
                        description={`An OTP will be sent to the mobile number you have submitted`}
                        formName={FORGOT_FORM}
                        inputsName="forgot_input"
                    />
                    <div className="row">
                        <div className="col-md-8">
                            <Center>
                                <LoadingBtn
                                    className="btn custom-btn registerbtn"
                                    fetching={forgot_pwd.fetching}
                                    disabled={forgot_pwd.fetching}
                                    onSubmit={this.handleSubmit}
                                    type="submit"
                                >
                                    Submit
                                </LoadingBtn>
                            </Center>
                        </div>
                    </div>
                </FormCon>
            </div>
        )
    }
}

const mapStateToProps = ({ forgot_input, forgot_pwd }) => ({
    forgot_input, forgot_pwd
})

const mapDispatchToProps = {
    setFormErr, forgotPwd
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotForm)