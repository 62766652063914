import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.css';
import './assets/css/chat.css';
import './assets/css/custom.css';
import './assets/css/datepicker.css';
import './assets/css/footer.css';
import './assets/css/signin.css'
import './assets/css/toast.css';
import './assets/css/tooltip.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
