import React from 'react'
import LoadingBtn from '../../components/Buttons/loadingbtn';
import Submit from './submit';

export default function SubmitBtn(props) {
    return (
        <Submit 
            Btn={LoadingBtn}
            {...props}
        />
    )
}