import { _FULFILLED, LOGOUT } from "./constants";
import { openModal, addToast } from "./common";
import ajaxerrmsg from "../../utils/ajaxerrmsg";

//common action for error
const httpStatusErr = errRes => {
    return dispatch => {
        if (errRes.status === 401) {
            dispatch({ type: `${LOGOUT}${_FULFILLED}` });
        } else if (
            errRes.status === 400 &&
            errRes.data &&
            errRes.data.modal_open
        ) {
            dispatch(openModal("ERROR_MODAL", errRes.data.modal_open));
        } else {
            dispatch(
                addToast(
                    errRes.data && errRes.status !== 500
                        ? ajaxerrmsg(errRes.data)
                        : "Something went wrong",
                    false
                )
            );
        }
    };
};

export default httpStatusErr;
