import React from 'react'
import { Link, NavLink } from "react-router-dom"

const NavLinks = ({ isLoggedIn = false, isSide = false }) => (
    <div className={`d-flex justify-content-evenly ${isSide ? `flex-column` : `header-links align-items-center`}`}>
        {
            isLoggedIn &&
            <React.Fragment>
                <PageLink
                    to={"/dashboard"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">My Home</span>
                </PageLink>
                <PageLink
                    to={"/search"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Search</span>
                </PageLink>
                <PageLink
                    to={"/matches"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Matches</span>
                </PageLink>
                <PageLink
                    to={"/activity"}
                    isLoggedIn={isLoggedIn}
                ><span className="header-span">Matrimony</span></PageLink>
                <PageLink
                    to={"/upgrade"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Upgrade Now</span>
                </PageLink>
                <PageLink
                    to={"/services"}
                    isLoggedIn={isLoggedIn}
                >
                    <span className="header-span">Services</span>
                </PageLink>

            </React.Fragment>
        }

    </div>
)

const PageLink = ({ isLoggedIn, children, icon, ...restProps }) => (
    isLoggedIn ?
        <NavLink
            {...restProps}
            className={`d-flex align-items-center nav-link relative`}
            activeClassName="active"
        >
            {icon && <i className={`fas fa-${icon} headericon`}></i>}
            {children}
        </NavLink>
        :
        <Link
            className="d-flex align-items-center nav-link"
            {...restProps}
        >
            {children}
        </Link>
)

export default NavLinks
