import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { uploadPhoto } from '../../redux/actions/user';
import { closeModal } from '../../redux/actions/common';

export class SendPhotoBtn extends Component {

    handleClick = () => {
        const {image, uploadPhoto, closeModal} = this.props;
        if(!image){
            return;
        }
        const formdata = new FormData();
        formdata.append("image", image);
        uploadPhoto(formdata);
        closeModal();
    }

    render() {
        const { image, upload_photo} = this.props;
        return (
            <LoadingBtn 
                onClick={this.handleClick}
                className="btn custom-btn"
                disabled={(image ? false : true) || upload_photo.fetching}
                fetching={upload_photo.fetching}
            >
                Upload
            </LoadingBtn>
        )
    }
}

const mapStateToProps = ({upload_photo}) => ({
    upload_photo
})

const mapDispatchToProps = {
    uploadPhoto, closeModal
}

export default connect(mapStateToProps, mapDispatchToProps)(SendPhotoBtn)
