import React from 'react'
import ChatMsg from '../../components/Chat/chatmsg';
import { connect } from 'react-redux'

const Chatmsg = ({user, send_by, ...restProps}) => <ChatMsg isRight={user.id === send_by} {...restProps} />;

const mapStateToProps = ({ user }) => ({
    user
})

export default connect(mapStateToProps)(Chatmsg)
