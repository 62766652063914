import React, { Component } from 'react'
import { connect } from 'react-redux'
import { chats_url, SEND_INTEREST } from '../../redux/actions/constants';
import SubmitBtn from '../common/submitbtn';

const testmsgs = [
    "We are interested in your Profile and would like to proceed further.", 
    "Very happy to know you. I Would like to take this forward and connect with you.", 
    "We think you will be a good match for our Son/Daughter. Let us know how we can take this ahead.", 
    "We seem to have a lot of shared interests. Would like to know you better."
];

export class SendInterest extends Component {

    state = {
        mesIndex: 0
    };

    render() {
        const { mesIndex } = this.state;
        const { actor, dispatch, filter } = this.props;
        return (
            <div className="bg-white rounded p-3 mb-3">
                <div className="d-flex flex-wrap mb-5">
                    {
                        testmsgs.map((msg, index) => {
                            return (
                                <div
                                    className={`border send-int-msg send-int-msg${mesIndex === index ? "-active" : "-inactive"}`}
                                    onClick={() => this.setState({ mesIndex: index })}
                                    key={index}
                                >
                                    {msg}
                                </div>
                            )
                        })
                    }
                </div>
                <SubmitBtn
                    className={"btn send-invite-btn"}
                    config={{
                        url: `${chats_url}${actor.id}/send_interest/`,
                        method: "POST",
                        data: {
                            message: testmsgs[mesIndex]
                        }
                    }}
                    succFunc={(payload) => {
                        dispatch({
                            type: SEND_INTEREST,
                            payload,
                            id: filter
                        });
                    }}
                >
                    Send Invite
                </SubmitBtn>
            </div>
        )
    }
}


const mapStateToProps = ({ chatusers }) => ({
    filter: chatusers.id
})

export default connect(mapStateToProps)(SendInterest)
