import React from "react";
import CustomBtn from "../Buttons/CustomBtn";

const Notfound = () => {
    return (
        <React.Fragment>
            <div className="info-banner relative">
                <div className="abs-tint d-flex justify-content-center align-items-center flex-column color-white">
                    <h1 className="mb-0" style={{ fontSize: "10vw" }}>404</h1>
                    <h3 className="mb-3">PAGE NOT FOUND</h3>
                    <CustomBtn to="/">Go to Home</CustomBtn>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Notfound;
