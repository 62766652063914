import React, { Component } from "react";
import ProfileBox from "./profilebox";
import Light, { reduceImages } from "./Light";
import { connect } from "react-redux";
import { openModal } from "../../redux/actions/common";

class Gallery extends Component {
    max = 3;

    renderGallery = openLightbox => {
        const { images } = this.props;

        if (!images) return;

        const imageLength = images.length > this.max ? this.max : images.length;

        const gallery = images.slice(0, imageLength).map((obj, i) => {
            return (
                <button
                    className="btn p-0 gallery-img"
                    key={obj.id}
                    onClick={e => {
                        if (
                            this.props.checkUserPhotos &&
                            this.props.user.photos.length === 0
                        ) {
                            this.props.openModal("PROMOTE_MODAL", {
                                title: "Upload Profile Picture",
                                link: "/manage/photos",
                                btnTitle: "Upload photo",
                                desc:
                                    "update your profile picture to view this image"
                            });
                        } else {
                            openLightbox(i, e);
                        }
                    }}
                >
                    <img src={obj.image} alt="" />
                </button>
            );
        });

        return <div className="d-flex flex-wrap">{gallery}</div>;
    };

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <Light
                        images={reduceImages(this.props.images)}
                        Gallery={({ openLightbox }) => (
                            <ProfileBox
                                title="Photos"
                                edit={
                                    this.props.edit ? "/manage/photos" : false
                                }
                            >
                                {this.renderGallery(openLightbox)}
                                {this.props.images.length > this.max ? (
                                    <div className="d-flex justify-content-end pt-2">
                                        <button
                                            onClick={e => openLightbox(0, e)}
                                            className="btn btn-link f-14"
                                        >
                                            View all photos
                                        </button>
                                    </div>
                                ) : null}
                            </ProfileBox>
                        )}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({
    user
});

const mapDispatchToProps = {
    openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
