import React, { Component } from "react";

export class NumberOnlyInput extends Component {
    preventChar(event) {
        if (!(event.charCode >= 48 && event.charCode <= 57)) {
            event.preventDefault();
            return;
        }
    }

    onPaste = e => {
        // return isNaN(Number())
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("Text");
        if (isNaN(pastedData)) {
            e.preventDefault();
            return;
        }
    };

    render() {
        return (
            <input
                type="tel"
                onDrop={this.onPaste}
                onPaste={this.onPaste}
                onKeyPress={this.preventChar}
                {...this.props}
            />
        );
    }
}
