import React from 'react'
import { connect } from 'react-redux'
import MatchBtn from '../../components/Buttons/matchbtn';
import accepticon from "../../assets/img/func/checked.png";
import acceptactive from "../../assets/img/func/checked-active.png";
import denyactive from "../../assets/img/func/access-denied-active.png";
import denyicon from "../../assets/img/func/access-denied.png";
import { addToast } from '../../redux/actions/common';
import { user_activities_url, ACCEPT_DENY, _FULFILLED, _REJECTED } from '../../redux/actions/constants';
import Submit from '../common/submit';

const AcceptBtn = ({ is_accept, status, type, id, dispatch, activeID }) => {
    const active = status === is_accept;
    return (
        <Submit
            config={{
                url: `${user_activities_url}${id}/accept_request/`,
                method: "POST",
                data: {type, is_accept}
            }}
            succFunc={(payload) => {
                dispatch({
                    type: ACCEPT_DENY + _FULFILLED,
                    payload,
                    id: activeID
                });
                dispatch(addToast(is_accept ? "Request Accepted": "Request Denied"));
            }}
            errFunc={(payload) => {
                dispatch({
                    type: ACCEPT_DENY + _REJECTED,
                    payload,
                    id: activeID
                })
            }}
            Btn={({ fetching, onClick }) => (
                <MatchBtn
                    fetching={fetching}
                    onClick={onClick}
                >
                    <img 
                        src={active ? 
                            is_accept ? acceptactive : denyactive : 
                            is_accept ? accepticon : denyicon} 
                        alt="" 
                    />
                    <p>{ is_accept ? "Accept" : "Deny" } request</p>
                </MatchBtn>
            )}
        />
    )
} 

export default connect(null)(AcceptBtn)