import React from 'react'
import { SEARCH_MATCHES_NO, search_url } from '../../redux/actions/constants';
import listhocoffsetpaginations from '../../hocs/listhocoffsetpaginations';
import MatchesLoaders from '../Loaders/loaders';
import CardProfile from '../Dashboard/cardProfile';



const DashBoardMatch = props => (
    <div className={`col-md-2 col-6 pl-2 pr-2`}>
        <CardProfile {...props} />
    </div>
)





const SearchResultsPage = listhocoffsetpaginations(DashBoardMatch, SEARCH_MATCHES_NO, 'search_matches_no', search_url, 12, MatchesLoaders);


// const SearchResultsPage = ({ ...restProps }) => {
    
//     const SearchMatch = listhocpaginations(DashBoardMatch, SEARCH_MATCHES, 'search_matches_no', search_url + restProps.location.search);

//     return (
//         <div className="row mt-3">
//             <div className="col-lg-12">
//                 <SearchMatch {...restProps} />
//             </div>
//         </div>
//     )
// }

export default SearchResultsPage
