import React from 'react'
import RegisterLayout from './registerlayout';
import ForgotForm from '../../containers/Register/forgotform';

const ForgotPage = () => (
    <RegisterLayout
        heading={
            <span>Forgot your password!<br></br> Recover your password here</span>
        }
    >
        <ForgotForm />
    </RegisterLayout>
)

export default ForgotPage