import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadMore from "./loadmore";
import apiInstance, { CancelToken, isCancel } from "../../redux/apiInstace";
import {
    _CLEAR,
    _FETCHING,
    _FULFILLED,
    _REJECTED,
} from "../../redux/actions/constants";
import httpStatusErr from "../../redux/actions/httpStatusErr";

class Scrollpaging extends PureComponent {
    componentDidMount() {
        this.loadData(this.props.url, this.getCreatedOn());
    }

    getCreatedOn = () => {
        const { list, url } = this.props;
        let created_on = "";
        let len = list ? list.data.length : 0;
        if (len !== 0) {
            let c_on = list.data[len - 1].created_on;
            if (c_on) {
                created_on = `${
                    url.indexOf("?") > -1 ? `&` : `?`
                    }created_on=${c_on}`;
            }
        }
        return created_on;
    };

    componentDidUpdate(prevProps) {
        const { url, reducerId } = this.props;
        const urlChange = url && url !== prevProps.url;
        const reducerIdChange = reducerId && reducerId !== prevProps.reducerId;
        if (urlChange || reducerIdChange) {
            this.clear();
            this.loadData(url);
        }
    }

    clear = () => {
        const { dispatch, actionName, reducerId } = this.props;
        const load = reducerId ? { id: reducerId } : {};
        dispatch({
            type: `${actionName}${_CLEAR}`,
            ...load
        });
        if (this.cancel) {
            this.cancel();
        }
    };

    loadMore = () => {
        const { next } = this.props.list;
        this.cancel = null;
        this.loadData(next);
    };

    loadData = async (url, created_on = "") => {
        const { dispatch, actionName, reducerId } = this.props;
        const load = reducerId ? { id: reducerId } : {};
        dispatch({
            type: `${actionName}${_FETCHING}`,
            ...load
        });
        try {
            const res = await apiInstance({
                url: url + created_on,
                cancelToken: new CancelToken(c => (this.cancel = c))
            });
            //dispatch fulfilled action
            dispatch({
                type: `${actionName}${_FULFILLED}`,
                payload: res.data,
                ...load
            });
        } catch (err) {
            if (!isCancel(err)) {
                dispatch({
                    type: `${actionName}${_REJECTED}`,
                    payload: "Something went wrong",
                    ...load
                });
                if (err.response) {
                    dispatch(httpStatusErr(err.response));
                }
                if (err.response && err.response.status === 406) {
                    this.props.history.replace("/registration-complete");
                }
            }
        }
    };

    renderNodata = () => {
        const { NoData } = this.props;
        if (NoData) {
            return <NoData />;
        }
        return null;
    };

    render() {
        const {
            dispatch,
            list, //from connect
            noNext,
            url,
            actionName,
            reducerName,
            reducerId,
            NoData,
            RenderItem,
            match,
            location,
            history,
            ...restProps
        } = this.props;
        if (list) {
            const { fetching, error, data, byId, count, next } = list;
            return (
                <Fragment>
                    {typeof count === "number" &&
                        count === 0 &&
                        data.length === 0
                        ? this.renderNodata()
                        : data.map(item => {
                            return (
                                <RenderItem
                                    {...byId[item]}
                                    {...restProps}
                                    key={item + "---" + reducerName}
                                />
                            );
                        })}
                    {fetching && (
                        <Fragment>
                            <RenderItem fetching={true} />
                            <RenderItem fetching={true} />
                        </Fragment>
                    )}
                    {!noNext && next && !fetching && !error && (
                        <LoadMore listmore={this.loadMore} />
                    )}
                </Fragment>
            );
        }
        return null;
    }

    componentWillUnmount() {
        this.clear();
    }
}

const mapStateToProps = (state, { reducerName, reducerId }) => ({
    list: reducerId ? state[reducerName][reducerId] : state[reducerName]
});

const ScrollPaging = withRouter(connect(mapStateToProps)(Scrollpaging));

export default ScrollPaging;
