import { Component } from 'react'
import { connect } from 'react-redux'
import { myProfile } from '../../redux/actions/user';

class LoadProfile extends Component {

    componentDidMount() {
        this.props.myProfile();
    }

    render() {
        return this.props.children
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    myProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadProfile)
