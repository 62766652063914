import React from 'react'
import CustomBtn from '../Buttons/CustomBtn';
import Center from '../center';

const PurchasedPlan = ({ packages }) => {
    return (
        <div className="row">
            <div className={`col-md-12`}>
                <div className={`form-box border mb-4`}>
                    <div className="container pt-3 pb-3 border-bottom">
                        <div className="row">
                            <div className="col-md-12">
                                <h5 className="m-0 pl-1">Membership Plan</h5>
                            </div>
                        </div>
                    </div>
                    <div className="container pt-3 pb-3">
                        <div className="row">
                            <div className={`col-md-12`}>
                                {packages.package ? <div className="row">
                                    <div className="col-md-4">
                                        <p className="ml-1 f-500">Plan name</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p>{packages.package.name}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="ml-1 f-500">Remaining Contacts to View</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p>{packages.points} Points</p>
                                    </div>

                                    <div className="col-md-4">
                                        <p className="ml-1 f-500">Plan Expires on</p>
                                    </div>
                                    <div className="col-md-8">
                                        <p>{new Date(packages.expiration_on).toLocaleDateString('en-IN', {
                                            year: 'numeric', month: 'long', day: 'numeric'
                                        })}</p>
                                    </div>
                                </div> :
                                    <Center className="flex-column pt-5 pb-5">
                                        <h4 className="mb-4">You don't have an active plan</h4>
                                        <CustomBtn to="/upgrade">
                                            Upgrade Now
                                    </CustomBtn>
                                    </Center>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PurchasedPlan
