import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setFormValue } from '../redux/actions/formactions';
import { reduceValue } from '../utils/Forms/validation';
import { depends } from '../utils/Forms/depends';

const formDepend = (WrappedComponent) => {

    class FormDepend extends Component {

        componentDidMount(){
            const { formName, inputProps, dependData } = this.props;
            if(dependData && formName) {
                this.setValue(reduceValue(inputProps));
            }
        }

        setValue = (cur) => {
            const { formName, setFormValue, dependData } = this.props;
            const func = depends[dependData];
            if(typeof func === "function" && cur) {
                func( (data, fName = "") => setFormValue(fName || formName, data), cur);
            }
        }

        componentDidUpdate(prevProps) {
            const { formName, inputProps, dependData } = this.props;
            if(dependData && formName) { 
                const cur = reduceValue(inputProps);
                const prev = reduceValue(prevProps.inputProps);
                if (cur !== prev) {
                    this.setValue(cur);
                }
            }
        }

        render() {
            const { setFormValue, ...rest } = this.props;
            return (
                <WrappedComponent {...rest} />
            )
        }

    }

    return connect(null, { setFormValue })(FormDepend)

}

export default formDepend
