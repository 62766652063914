import React from 'react'
import Packages from '../../containers/upgrade/packages';
import Gpay from "../../assets/img/Google-Pay.png";
import Paytm from "../../assets/img/paytm.png";
import Phonepay from "../../assets/img/phone-pe.png";

const UpgradePage = () => {
    return (
        <div className="container custom-container">
            <Packages />
            <div className="mb-5 mt-5">
                <h5>Other Payment Method</h5>
                <div className="d-flex">
                    <div className="col-12">

                        <div className="row">
                            <div className="col-12 col-md-6 pb-3 pt-3">
                                <div className="bx-shdw">
                                    <h6 className="f-600">Bank Transfer</h6>
                                    <div className="pl-3 f-13">
                                        <div className="d-flex justify-content-between mb-2">
                                            <div className="col-5">Bank name</div>
                                            <div className="col-1">:</div>
                                            <div className="col-6">Axis Bank, Pallikaranai</div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <div className="col-5">Account name</div>
                                            <div className="col-1">:</div>
                                            <div className="col-6">Santhosham Matrimony</div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <div className="col-5">A/c No</div>
                                            <div className="col-1">:</div>
                                            <div className="col-6">920020002294707</div>
                                        </div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <div className="col-5">Ifsc code</div>
                                            <div className="col-1">:</div>
                                            <div className="col-6">UTIB0003944</div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="col-12 col-md-6 pb-3 pt-3">

                                <div className="bx-shdw">
                                    <h6 className="f-600">Mobile Banking</h6>
                                    <div className="p-2 f-13">
                                        <div className="row align-item-center justify-content-center">

                                            <div className="col-6">
                                                <img className="p-2" width="50" src={Gpay} alt="google Pay" />
                                                <img className="p-2" width="50" src={Paytm} alt="Paytm" />
                                                <img className="p-2" width="50" src={Phonepay} alt="PhonePay" />
                                                <div>
                                                    <a className="pl-4 f-22 theme-color" href="tel:9150576647">9150576647</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UpgradePage
