import React, { memo, Fragment } from 'react'

const ChatMsg = memo(({ fetching, isRight, message }) => {
    if (fetching) {
        return (
            <Fragment>
                <div className={`chat-right`}>
                    <div className="chat-msg shine">

                    </div>
                </div>
                <div className={`chat-left`}>
                    <div className="chat-msg shine">

                    </div>
                </div>
            </Fragment>
        )
    }
    return (
        <div className={`chat-${!isRight ? "left" : "right"}`}>
            <div className="chat-msg">
                {message}
            </div>
        </div>
    )
})

export default ChatMsg
