import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProfileDetails from '../../components/Profile/profiledetails';
import { myProfile } from '../../redux/actions/user';
import PartnerPrefers from '../../components/Profile/partnerprefers';
import Loadprofile from './loadprofile';
import ProfilePersonalInfo from './mypersonalinfo';

class UserProfile extends Component {
    render() {
        const { partner_preferences } = this.props.userData;
        const prefers = partner_preferences || {};
        return (
            <Loadprofile>
                <div className="container custom-container profile-page pt-3">
                    <ProfilePersonalInfo userData={this.props.userData} />
                    <div className="d-flex">
              
                        <div className="col-12">
                            <ProfileDetails edit={true} userData={this.props.userData} />
                            <PartnerPrefers {...prefers} />
                        </div>

                    </div>
                </div>
            </Loadprofile>
        )
    }
}

const mapStateToProps = ({ user, my_profile }) => ({
    userData: user, my_profile
})

const mapDispatchToProps = {
    myProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile) 
