import React from "react";
import apiInstance from "../../redux/apiInstace";
import { dashboard_url } from "../../redux/actions/constants";

class UserUnVerified extends React.Component {

    async componentDidMount() {
        this.cancel = false;
        try {
            await apiInstance({
                url: dashboard_url + "?type=1",
            });
            if(!this.cancel) {
                this.props.history.replace("/dashboard");
            }
        } catch (err) {
            
        }
    }

    render() {
        return (
            <div className="pt-4">
                <h1 className="text-center mb-2">
                    Congratulations! Registration Completed
                </h1>
                <p className="text-center f-16 mb-0">
                    Your profile is being validated by the admin. Kindly have
                    patience.
                </p>
                <div className="container">
                    <img
                        src="https://cdn.pixabay.com/photo/2017/01/18/19/37/st-valentines-day-1990691_1280.png"
                        className="w-100"
                        alt=""
                    />
                </div>
            </div>
        );
    }

    componentWillUnmount(){
        this.cancel = true;
    }

}

export default UserUnVerified;
