import React from "react";
import Section from "../section";
import BannerSection from "./BannerSection";
import RecentMembers from "./RecentMembers";
import Info from "./Info";
import mastervalues from "../../getmaster";
import videosample from "../../assets/img/video-sample.jpg";

const { religions, languages, stars, } = mastervalues;

const LandingPage = () => (
    <React.Fragment>
        <BannerSection />
        <RecentMembers />
        <Info />

        <Section className="pt-5 pb-5 download-bg">
            <div className="col-12">
                <h1 className="text-right text-white f-30">Register with us</h1>
                <h1 className="text-right text-white f-22">To choose life partner from your </h1>
                <h1 className="text-right text-white f-30">own Community</h1>

            </div>
        </Section>
        <Section className="landing-section-pad">
            <div className="col-12 mb-5">
                <div className="mb-5">
                    <h1 className="text-center mb-1 f-30">For Successful Marriages</h1>
                    <h2 className="horizontalline">
                        <span className="horizontaltext">
                            <i className="fa fa-heart theme-color" aria-hidden="true"></i>
                        </span>
                    </h2>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-5">
                        <img src={videosample} className='img-fluid' alt="happymarriage" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-7">
                        <div className="intro-details">
                            <h5 className="subtitle">1000s of successful Marriages trusted by Tamil Brides & Grooms</h5>
                            <div className="h1-title">Santhosham Matrimony Free Matrimonial Site.</div>
                            <div className="text">
                                We make happy marriages especially for middle class people in Tamil culture setup, providing the most secure and convenient matchmaking experience to all its members.
                                We help you to find your right and best Life Partner with best and lowest membership fee.
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="col-12 mb-5 mt-5">
                <div className="">
                    <h1 className="text-center mb-1 f-30">Browse by</h1>
                    <p className="text-center f-18 mb-0">Santhosham Matrimonials</p>
                    <h2 className="horizontalline">
                        <span className="horizontaltext">
                            <i className="fa fa-heart theme-color" aria-hidden="true"></i>
                        </span>
                    </h2>

                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6  col-md-4">
                        <div className='browse-category-box outline'>
                            <p className="text-center f-18 mb-0 f-600">Language</p>
                            <h2 className="horizontalline">
                                <span className="horizontaltext">
                                    <i className="fa fa-heart theme-color" aria-hidden="true"></i>
                                </span>
                            </h2>
                            <ul className="list-unstyled mt-2">
                                {
                                    languages.slice(0, 10).map(value => (
                                        <li key={value.id} className="f-14 mb-2">{value.name} Brides and Grooms</li>
                                    )
                                    )
                                }
                            </ul>
                            <p className="float-right theme-support-color mb-0">...More</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6  col-md-4">
                        <div className='browse-category-box outline'>
                            <p className="text-center f-18 mb-0 f-600">Religions</p>
                            <h2 className="horizontalline">
                                <span className="horizontaltext">
                                    <i className="fa fa-heart theme-color" aria-hidden="true"></i>
                                </span>
                            </h2>
                            <ul className="list-unstyled mt-2">
                                {
                                    religions.slice(0, 10).map(value => (
                                        <li key={value.id} className="f-14 mb-2">{value.name} Brides and Grooms</li>
                                    )
                                    )
                                }
                            </ul>
                            <p className="float-right theme-support-color mb-0">...More</p>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6  col-md-4">
                        <div className='browse-category-box outline'>
                            <p className="text-center f-18 mb-0 f-600">Stars</p>
                            <h2 className="horizontalline">
                                <span className="horizontaltext">
                                    <i className="fa fa-heart theme-color" aria-hidden="true"></i>
                                </span>
                            </h2>
                            <ul className="list-unstyled mt-2">
                                {
                                    stars.slice(0, 10).map(value => (
                                        <li key={value.id} className="f-14 mb-2">{value.name} Brides and Grooms</li>
                                    )
                                    )
                                }
                            </ul>
                            <p className="float-right theme-support-color mb-0">...More</p>

                        </div>
                    </div>
                </div>
            </div>

        </Section>
   
    </React.Fragment>
);

export default LandingPage;
