import React, { Component } from 'react';
import "../../assets/css/chat.css"
import ListChatusers from '../../containers/Chat/listchatusers';
import ChatRoom from '../../containers/Chat/chatroom';
import ClearForm from '../../containers/Form/clearForm';
import { SEND_INTEREST } from '../../redux/actions/constants';
import Filterbtn from '../../containers/Chat/filterbtn';
import ChatCon from '../../containers/Chat/ChatCon';

class ChatPage extends Component {

    render() {
        return (
            <ClearForm
                clear={[SEND_INTEREST, "CHATROOMS", "CHATUSERS"]}
            >
                <div>
                    <ChatCon>
                        <div className="chat-app-left">
                            <div className="chat-app-left-header chat-app-header-height pt-0 pb-0">
                                <div className="row h100p border-bottom">
                                    <Filterbtn 
                                        title="ALL"
                                        filter="ALL"
                                    />
                                    <Filterbtn 
                                        title="RECIEVED"
                                        filter="RECIEVED"
                                    />
                                    <Filterbtn 
                                        title="SENT"
                                        filter="SENT"
                                    />
                                </div>
                            </div>
                            <div className="chatuser-list-container chatscroll">
                                <ListChatusers />
                            </div>
                        </div>
                        <div className="chat-app-right d-flex flex-column justify-content-between">
                            <ChatRoom />
                        </div>
                    </ChatCon>
                </div>
            </ClearForm>
        );
    }
}

export default ChatPage;
