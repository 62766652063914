import { _FETCHING, _FULFILLED, _REJECTED, _CLEAR, SHORT_LIST_MATCH, REJECT_MATCH, ACCEPT_DENY } from "../actions/constants";
import { resultsIntial, byIdUpdateData, reduceResults } from "./httpreducer";

//intial match data
const matchesIntial = resultsIntial;

const updateData = (state, action) => {
    const { payload, baseId } = action;
    const { byId } = state;
    if(payload.id === baseId) {
        return byIdUpdateData(state, action)
    } 
    if(byId[baseId] && byId[baseId].actor && byId[baseId].actor.id === payload.id  ) {
        return {
            ...state,
            byId: {
                ...state.byId,
                [baseId]: {
                    ...state.byId[baseId],
                    actor: {
                        ...state.byId[baseId].actor,
                        ...payload
                    }
                }
            }
        }
    }
    return state
}

const createMatchesWithId = name => (state = matchesIntial, action) => {
    switch (action.type) {
        case `${name}${_FETCHING}`:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case `${name}${_FULFILLED}`:
            return {
                ...state,
                fetching: false,
                count: action.payload.count,
                next: action.payload.next,
                prev: action.payload.prev,
                ...reduceResults(state, action.payload.results)
            }
        case `${name}${_REJECTED}`:
            return {
                ...state,
                fetching: false,
                error: action.payload
            }
        case `${SHORT_LIST_MATCH}${_FULFILLED}`:
        case `${REJECT_MATCH}${_FULFILLED}`:
            return updateData(state, action)
        case `${ACCEPT_DENY}${_FULFILLED}`:
            return byIdUpdateData(state, action)
        case `${name}${_CLEAR}`:
            return { ...state, ...matchesIntial }
        default:
            return state
    }
}

export const createMatchList = name => (state = matchesIntial, action) => {
    switch (action.type) {
        case `${name}${_FETCHING}`:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case `${name}${_FULFILLED}`:
            return {
                ...state,
                fetching: false,
                ...reduceResults(state, action.payload)
            }
        case `${name}${_REJECTED}`:
            return {
                ...state,
                fetching: false,
                error: action.payload
            }
        case `${SHORT_LIST_MATCH}${_FULFILLED}`:
        case `${REJECT_MATCH}${_FULFILLED}`:
            return updateData(state, action)
        case `${ACCEPT_DENY}${_FULFILLED}`:
            return byIdUpdateData(state, action)
        case `${name}${_CLEAR}`:
            return { ...state, ...matchesIntial }
        default:
            return state
    }
}

export default createMatchesWithId