import React from 'react'
import { CloseModalBtn } from '../../containers/Modal/closemodal';
import Loginform from '../../containers/Landing/loginform';

//modal name SIGNIN_MODAL
const SignInModal = () => (
    <div className="modal-wrapper">
        <div className="modal-container" style={{maxWidth: `365px`}}>
            <CloseModalBtn className="btn-a close btn-close">×</CloseModalBtn>
            <div className="signin-modal p-2">
                <h3 className="mb-3">Sign In</h3>
                <Loginform  
                    isModal 
                />
            </div>
        </div>
    </div>
)

export default SignInModal