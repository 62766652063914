import React from 'react'
import CustomBtn from '../../components/Buttons/CustomBtn';
import FetchData from '../../hocs/fetchdata';

const NumberView = ({ username, secondary_number }) => (
    <div>
        <div>
            <h6 className="mb-0">Primary Contact Number</h6>
            <a href={`tel:${username}`}>{username}</a>
        </div>
        <div className="mt-3">
            <h6 className="mb-0">Secondary Contact Number</h6>
            {secondary_number ?
                <a href={`tel:${secondary_number}`}>{secondary_number}</a> :
                "Not Available"
            }
        </div>
    </div>
)

const PhoneView = ({ url }) => {
    return (
        <FetchData
            url={url}
            WrappedComp={({ data, fetching, error }) => (
                <div className="pt-2 pb-2 m-2">
                    <div>
                        <div className="f-16">
                            {
                                fetching ?
                                    "Fetching phone number..." :
                                    data ?
                                        data.username ?
                                            <NumberView {...data} /> :
                                            "Number request has been sent"
                                        :
                                        error ? (
                                            <div>
                                                <div className="f-14 theme-support-color">{error}</div>
                                                <CustomBtn to="/upgrade" className="mt-2">Upgrade</CustomBtn>
                                            </div>
                                        )
                                            : null
                            }
                        </div>
                    </div>
                </div>
            )}
        />
    )
}

export default PhoneView
