import React from 'react'
import bannerImage from "../../assets/img/banner.jpg"
import SignUp from '../../containers/Landing/signup';


const BannerSection = () => (
    <div>
        <div className="bannerImage">
            <img className="landing-img" src={bannerImage} alt="" />
        </div>
        <div className="container landing-section-pad">
            <div className="row ml-2 mr-2">
                <div className="col-lg-8">
                    <div className="border-bottom">
                        <h5 className="pb-2 theme-color">Find Lakhs of Indian Brides & Grooms in Santhosham Matrimony</h5>
                        <p className='f-14'>One of southern India’s leading matrimonial websites that has helped lots of members find their perfect life partner.</p>
                        <p className='f-14'>We believe choosing a life partner is the most primary decision, and hence works towards giving a simple and secure matchmaking experience for you and your family. Each profile registered with us goes through a manual screening process before going live on-site; we provide superior privacy controls for Free, and also verify contact information of members.</p>
                        <p className='f-14'>You can register for Free and search according to your specific criteria on age, height, community, profession, income, location and much more- on your computer, tablet or mobile. Regular custom mails and notifications make the process easier and take you closer to your Santhosham!.</p>
                    </div>
                    <div className="mt-4 pl-4 pr-4">
                        <h6 className="theme-color">Why Santhosham Matrimony</h6>
                        <div className="d-flex justify-content-around">
                            <ul className="list-unstyled">
                                <li>
                                    <i className="fas f-12 theme-color fa-heart" aria-hidden="true"></i>
                                    <span className="pl-2 f-12">Fits your busy lifestyle</span>
                                </li>
                                <li>
                                    <i className="fas f-12 theme-color fa-heart" aria-hidden="true"></i>
                                    <span className="pl-2 f-12">Match reviews across multiple criteria</span>
                                </li>
                                <li>
                                    <i className="fas f-12 theme-color fa-heart" aria-hidden="true"></i>
                                    <span className="pl-2 f-12">Intelligent matchmaking</span>
                                </li>

                            </ul>
                            <ul className="list-unstyled">
                                <li>
                                    <i className="fas f-12 theme-color fa-heart" aria-hidden="true"></i>
                                    <span className="pl-2 f-12">Connect with Matches who spark your interest</span>
                                </li>
                                <li>
                                    <i className="fas f-12 theme-color fa-heart" aria-hidden="true"></i>
                                    <span className="pl-2 f-12">Chat with your matches on the go</span>
                                </li>
                                <li>
                                    <i className="fas f-12 theme-color fa-heart" aria-hidden="true"></i>
                                    <span className="pl-2 f-12">Get notifications about new </span>
                                </li>

                            </ul>

                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="">
                        <SignUp />
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default BannerSection
