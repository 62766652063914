import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from '../Register/formbox';
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { reduceForm } from '../../utils/Forms/validation';
import { PROFILE_SETTING, PRIVACY } from '../../redux/actions/constants';
import { setFormValue } from '../../redux/actions/formactions';
import { editPrivacy } from '../../redux/actions/user';
import FormCon from '../Form/formcon';


export class PrivacySetting extends Component {

    handleSubmit = e => {
        e.preventDefault();
        const { privacy_setting, editPrivacy } = this.props;
        const postData = reduceForm(privacy_setting);
        postData.is_number_public = parseInt(postData.is_number_public, 10);
        postData.is_photo_public = parseInt(postData.is_photo_public, 10);
        editPrivacy(postData);
    }

    componentDidMount() {
        const { user, setFormValue } = this.props;
        //set initial values
        setFormValue(PROFILE_SETTING, {
            name: "is_number_public",
            data: { value: [user.is_number_public===true ? '1': '0'] }
        });
        setFormValue(PROFILE_SETTING, {
            name: "is_photo_public",
            data: { value: [user.is_photo_public===true ? '1': '0'] }
        });
    }

    render() {
        const { fetching, data } = this.props.privacy_form;
        return (
            <FormCon
                toastMsg={`Privacy settings updated`}
                clear={[PRIVACY]}
                unMount={data.id}
                onSubmit={this.handleSubmit}
            >
                <Formbox
                    title="Privacy Setting"
                    formName={PROFILE_SETTING}
                    inputsName="privacy_setting"
                >
                    <div className="row pt-4">
                        <div className="col-md-12">
                            <Center>
                                <LoadingBtn
                                    className="btn custom-btn registerbtn"
                                    fetching={fetching}
                                    disabled={fetching}
                                    onSubmit={this.handleSubmit}
                                >
                                    Submit
                                </LoadingBtn>
                            </Center>
                        </div>
                    </div>
                </Formbox>
            </FormCon>
        )
    }
}

const mapStateToProps = ({ privacy_setting, privacy_form, user }) => ({
    privacy_setting, privacy_form, user
})

const mapDispatchToProps = {
    setFormValue, editPrivacy
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacySetting)