import React from 'react'
import ProfileImage from '../../components/Profile/ProfileImage';
import Gallery from '../../components/Profile/gallery';
import CustomBtn from '../../components/Buttons/CustomBtn';
import { annualLimit } from '../../utils/Forms/editdetails';

const ProfilePersonalInfo = ({ userData }) => {
    const { user, age, mother_tongue, district, user_details, profile_pic,
        religion_details, education_details, uid, profile_for, is_photo_public,
        is_number_public,
        photos } = userData;
    return (
        <div className="row">
            <div className="col-lg-3">

                <ProfileImage photos={photos} profile_pic={profile_pic}>
                </ProfileImage>
                {photos && Array.isArray(photos) && photos.length > 0 ?
                    <Gallery edit={true} images={photos} /> :
                    <CustomBtn className="btn-block" children="Upload Photos" to={"/manage/photos"} />
                }
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 xs-mt-20 profile-details">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pr-sm-0">
                    <h2 className="name">{user.first_name} {user.last_name}</h2>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pr-sm-0">
                    <div className="small-desc">
                        <span>{uid}</span>
                        <span className="pl-2 pr-2">|</span>
                        <span>Profile created by {[2, 3].includes(profile_for.id) ?
                            `Parents` :
                            [4, 5].includes(profile_for.id) ?
                                `Siblings` : profile_for.name} </span>
                    </div>
                    <div className="d-flex justify-content-between col-md-6">
                        <div>
                            <span>Photo Privacy:</span> <span className="theme-support-color">{is_photo_public ? "Public" : "Private"}</span>
                        </div>
                        <div>
                            <span>Photo Privacy:</span> <span className="theme-support-color">{is_number_public ? "Public" : "Private"}</span>
                        </div>
                    </div>
                </div>
                <div className='personal-infos'>
                    <ul>
                        <li>{age} yrs, {user_details && user_details.height.name}</li>
                        <li>Mother Tongue is {mother_tongue.name}</li>
                        {religion_details &&
                            <li>{religion_details.caste && religion_details.caste.name}
                                {religion_details.sub_caste ? ` , ${religion_details.sub_caste.name}` : ""}
                                {religion_details.gothram ? ` - ${religion_details.gothram}` : ""}</li>
                        }
                        {
                            religion_details.star &&
                            <li> {religion_details.star ? `Star is ${religion_details.star.name},` : ""} {religion_details.raasi ? `Raasi is ${religion_details.raasi.name}` : ""}</li>
                        }
                        {district &&
                            <li>Lives in {district.name}</li>
                        }
                        {education_details && education_details.highest_education &&
                            < li > Studied {education_details.highest_education.name}. {[1,2].includes(education_details.highest_education.id) ? "":"Degree"}</li>
                        }
                        {education_details && education_details.occupation &&
                            <li>{education_details.occupation.name}</li>
                        }
                        {education_details?.annual_income ?
                            <li>Earns {`Rs  ${education_details.annual_income < (annualLimit*100000) ? parseInt(education_details.annual_income / 100000) : "30+"} Lakhs`} annually</li> : "Income Not Mentioned"
                        }
                    </ul>

                </div>


            </div>

        </div >
    )
}

export default ProfilePersonalInfo