import React, { Component } from 'react'
import Loader from './Loader';
import Submit from '../common/submit';
import { connect } from 'react-redux'
import { closeModal } from '../../redux/actions/common';

//modal name: "LOADER_MODAL" modal props reducerName
class LoaderModal extends Component {

    render() {
        const { success, error, config, dispatch } = this.props;
        return (
            <Submit
                Btn={ModalRender}
                succFunc={(data) => {
                    if(typeof success === "function") {
                        success(data)
                    }
                    dispatch(closeModal());
                }}
                errFunc={(data) => {
                    if(typeof error === "function") {
                        error(data)
                    }
                    dispatch(closeModal());
                }}
                config={config}
            />
        )
    }

}

class ModalRender extends Component {

    componentDidMount(){
        const { onClick } = this.props;
        if(onClick) {
            onClick();
        }
    }

    render() {
        const { fetching } = this.props;
        if (fetching) {
            return (
                <div className="modal-wrapper">
                    <div className="page-loader-container">
                        <Loader />
                    </div>
                </div>
            )
        }
        return null
    }

}

export default connect(null)(LoaderModal)
