import React, { Component } from 'react';
import Matches from '../../components/Dashboard/matches';
import ScrollPaging from '../common/scrollpaging';
import { NoData } from '../../components/dynamiclist';
import { ACTV_MATCHES, user_activities_url } from '../../redux/actions/constants';
import { setDashId } from '../../redux/actions/dashboard';
import { connect } from 'react-redux'

class ActvMatches extends Component {

    componentDidMount() {
        this.setActive();
    }

    componentDidUpdate(prevProps) {
        if(this.props.type !== prevProps.type) {
            this.setActive();
        }
    }

    setActive = () => {
        this.props.setDashId(ACTV_MATCHES, this.props.type);
    }
    
    render () {
        const  { activeId } = this.props;
        console.log(this.props)
        return (
            <div className="row">
                {
                    activeId  &&
                    <ScrollPaging
                        RenderItem={({ id, actor, fetching }) => (
                            <div className={`col-md-6`}>
                                <Matches baseId={id} activeID={activeId} {...actor} fetching={fetching} /> 
                            </div>
                        )}
                        actionName={ACTV_MATCHES}
                        reducerName={"actv_matches"}
                        url={user_activities_url + `?type=${activeId}` }
                        NoData={NoData}
                        reducerId={activeId}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = ({ actv_matches }) => ({
    activeId: actv_matches.id
})

const mapDispatchToProps = {
    setDashId
}

export default connect(mapStateToProps, mapDispatchToProps)(ActvMatches)