import { CHOOSE_PACKAGE, RAZOR_PAY, paysuccess_url } from "./constants";
import httpAction from "./httpAction";

// choose package
export const choosePackage = id => {
    return {
        type: CHOOSE_PACKAGE,
        id
    }
}

//razor pay success
export const razorPaySucc = data => httpAction(RAZOR_PAY, {
    url: paysuccess_url,
    method: "POST",
    data
})