import React from "react";

const Section = ({ children, className = "" }) => (
    <section className={className}>
        <div className="container custom-container">
            <div className="row ml-2 mr-2">
                {children}
            </div>
        </div>
    </section>
);

export default Section;
