import { Component } from 'react'
import { connect } from 'react-redux'
import { loadForm } from '../../redux/actions/formactions';
import { initValue } from '../../utils/Forms/validation';
import { init } from '../../utils/Forms/intSearchValue';


export class InitSearch extends Component {

    state = {
        show: false
    };

    componentDidMount(){
        const { initData, gender } = this.props;
        if(initData){
            this.loadData(initValue({ ...initData, gender}));
        } else {
            this.loadData({...init, gender});
        }
    }

    loadData = init => {
        this.props.inputForm.forEach( x => {
            this.props.loadForm(x.formName, x.initFunc(init));
        })
        this.setState({show: true});
    }

    render() {
        return this.state.show ? this.props.children : null 
    }
}

const mapStateToProps = ({user}) => ({
    initData: user.partner_preferences
})

const mapDispatchToProps = {
    loadForm
}

export default connect(mapStateToProps, mapDispatchToProps)(InitSearch)