import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setFormValue } from '../../redux/actions/formactions';
import CheckBox from '../../components/Form/checkbox';

export class CCheckBox extends Component {

    handleChange = (e) =>{
        const { setFormValue, formName } = this.props;
        const {name, checked } = e.target;
        setFormValue( formName, {
            name,
            data: {
                checked
            }
        });
    }

    render() {
        const {type, setFormValue, formName, ...restProps} = this.props;
        return (
            <CheckBox onChange={this.handleChange}  {...restProps}/>
        )
    }
}

const mapDispatchToProps = {
    setFormValue
}

export default connect(null, mapDispatchToProps)(CCheckBox)
