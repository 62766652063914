import React from 'react'
import Deactive from '../../containers/Modal/deactivate';
import { ConfirmBody } from './confirmodal';

//modal name DEACT_MODAL
const DeactiveModal = ({ reason }) => (
    <ConfirmBody 
        msg={"Are you sure you want to deactivate this Account"}
        children={<Deactive reason={reason} />}
    />
)

export default DeactiveModal
