import React, { Component } from 'react';
import Matches from '../../components/Dashboard/matches';
import ScrollPaging from '../common/scrollpaging';
import { NoData } from '../../components/dynamiclist';
import { requests_url, SENT_MATCHES } from '../../redux/actions/constants';
import { setDashId } from '../../redux/actions/dashboard';
import { connect } from 'react-redux'

const TabBtn = ({ title, onClick, active }) => (
    <button
        className={`col btn btn-chat-filter pt-2 pb-2`}
        onClick={onClick}
        style={active ? { borderBottom: `3px solid #f61454` } : {}}
    >
        {title}
    </button>
)

class RequestMatches extends Component {

    componentDidMount(){
        this.props.setDashId(SENT_MATCHES, 1);
    }

    handleClick = filter => this.props.setDashId(SENT_MATCHES, filter);

    render() {
        const  { filter } = this.props;
        return (
            <div>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="d-flex border-bottom">
                            <TabBtn 
                                onClick={() => this.handleClick(1)}
                                title="PENDING"
                                active={filter === 1}
                            />
                            <TabBtn 
                                onClick={() => this.handleClick(2)}
                                title="ACCEPTED"
                                active={filter === 2}
                            />
                            <TabBtn 
                                onClick={() => this.handleClick(3)}
                                title="DENIED"
                                active={filter === 3}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                {
                    filter && 
                    <ScrollPaging
                        RenderItem={({ id, actor, fetching, type }) => {
                            return (
                                <div className="col-md-6">
                                    <Matches 
                                        type={type}
                                        baseId={id}
                                        showRequestText={"sent"}
                                        activeID={filter} 
                                        {...actor} 
                                        fetching={fetching} 
                                    />
                                </div>
                            )
                        }}
                        actionName={SENT_MATCHES}
                        reducerName={"sent_matches"}
                        url={requests_url + `?type=${0}&filter=${filter}`}
                        NoData={NoData}
                        reducerId={filter}
                    />
                }
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ sent_matches }) => ({
    filter: sent_matches.id
})

const mapDispatchToProps = {
    setDashId
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestMatches)