import React, { Component } from 'react'
import { connect } from 'react-redux'
import { razorPaySucc } from '../../redux/actions/upgrade';
import { rZPay } from '../../utils/razorpay';
import { addToast, openModal } from '../../redux/actions/common';
import { Redirect } from "react-router-dom";
import SubmitBtn from '../common/submitbtn';
import { transaction_url, paysuccess_url } from '../../redux/actions/constants';
import { withRouter } from "react-router-dom"

export class Paybtn extends Component {

    handleSucc = data => {
        rZPay(data, this.props.userDetails, this.paymentSucc);
    }

    handleErr = err => {
        const { history } = this.props;
        history.replace("/payment-failed")
    }

    paymentSucc = res => {
        const { openModal, history } = this.props;
        openModal("LOADER_MODAL", { 
            config: {
                url: paysuccess_url,
                method: "POST",
                data: res
            },
            success: (data) => {
                history.replace("/payment-success")
            },
            error: () => {
                history.replace("/payment-failed")
            }
        })
    }

    render() {
        const { razor_succ, children, className, package_id, coupon_code } = this.props;
        const postData = coupon_code ? { package_id, coupon_code } : { package_id, coupon_code };
        if (Object.keys(razor_succ.data).length > 0) {
            return <Redirect to="/dashboard" />
        }
        return (
            <SubmitBtn
                className={className}
                config={{
                    url: transaction_url,
                    method: "POST",
                    data: postData
                }}
                succFunc={res => this.handleSucc(res)}
                errFunc={err => this.handleErr(err)}
            >
                {children}
            </SubmitBtn>
        )
    }
}

const mapStateToProps = ({ payment, razor_succ, user }) => ({
    payment, razor_succ, userDetails: user.user
})

const mapDispatchToProps = {
    addToast, razorPaySucc, openModal
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Paybtn))