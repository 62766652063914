import axios from "axios";

const dev_domain = "admin.santhoshammatrimony.com";
const prod_domain = "admin.santhoshammatrimony.com";

//rest
const devUrl = `https://${dev_domain}/`;
const prodUrl = `https://${prod_domain}/`;

//websocket 
const devWsUrl = `http://${process.env.HOST}:4000/`;
const prodWsUrl = `https://chat.Santhosham Matrimony/`;

export const baseURL = process.env.NODE_ENV === "development" ? devUrl : prodUrl;
export const wsURL = process.env.NODE_ENV === "development" ? devWsUrl : prodWsUrl;

const { CancelToken, create, isCancel } = axios;

const apiInstance = create({ baseURL });

export { CancelToken, isCancel }

export default apiInstance;