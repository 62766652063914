import React, { PureComponent } from 'react'
import { closeModal } from "../../redux/actions/common"
import { connect } from 'react-redux'

class SideModalCon extends PureComponent {

    state = {
        translateX: null
    };

    width = 275
    offset = this.width * 0.5

    _onClick = evt => {
        evt.stopPropagation();
    }

    getX = evt => {
        return evt.targetTouches[0].clientX
    }

    _onTouchStart = evt => {
        this.tX = this.getX(evt);
    }

    _onTouchMove = evt => {
        const translateX = this.getX(evt) - this.tX;
        if(translateX < 0){
            this.setState({translateX});
        }
    }

    _onTouchEnd = evt =>{
        const { translateX } = this.state;
        if(translateX < -this.offset){
            this.props.closeModal();
        } else {
            this.setState({translateX: null});
        }
    }

    componentDidUpdate(prevState){
        const { translateX } = this.state;
        if(translateX < -this.width){
            this.props.closeModal();
        }
    }

    render() {
        const {children, closeModal, ...rest} = this.props;
        const { translateX } = this.state;
        const style = { width: `${this.width}px` };
        return (
            <div 
                ref={el => this.el = el}
                onTouchStart={this._onTouchStart}
                onTouchMove={this._onTouchMove}
                onTouchEnd={this._onTouchEnd}
                onClick={this._onClick}
                style={translateX ? {...style, transform: `translate3d(${translateX}px, 0, 0)`} : style}
                {...rest}
            >
                {children}
            </div>
        )
    }
}


export default connect(null, {closeModal})(SideModalCon)