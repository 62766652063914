import React from 'react'
import { Link } from "react-router-dom"
import { OpenModalBtn } from '../../containers/Modal/closemodal';
import { logout } from '../../redux/actions/user';
import { LOGOUT } from '../../redux/actions/constants';


const HeaderProfile = ({ profile_pic, notiCount = 0 }) => {
    return (
        <div className="d-flex">
            <div className="d-flex align-items-center mr-2 ml-2">
                <Link to="/notifications">

                    <i className="far f-22 theme-color fa-bell">
                        <span className="badge badge-pill notification-count">{notiCount}</span>
                    </i>
                </Link>
            </div>
            <div className="d-flex align-items-center mr-2 ml-2 theme-color">
                <OpenModalBtn
                    className={"btn logout-btn"}
                    modalName="CONFIRM_MODAL"
                    modalProps={{
                        msg: "Are you sure you want to logout?",
                        confirmProps: {
                            reducerName: "log_out",
                            ntAction: () => logout(),
                            formName: LOGOUT
                        }
                    }}
                >

                    <i className="fas fa-power-off theme-color f-22"></i>
                </OpenModalBtn>
            </div>
            <div className="d-flex align-items-center headerprofile h100p mr-2 ml-2">
                <Link to="/my-profile" className="d-flex align-items-center justify-content-end theme-color">
                    <img className="top-profilepic border" src={profile_pic} alt="" />
                </Link>
            </div>

        </div>

    )
}

export default HeaderProfile
