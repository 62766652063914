import React from 'react'

const Content = ({heading, children}) => {
    return (
        <div className="content-box pb-2 pt-2">
            <h4>{heading}</h4>
            {children}
        </div>
    )
}

export default Content
