import React  from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header/header';
import {withRouter} from "react-router-dom";

export const ConnectHeader = ({user}) => (
    <Header 
        profilePic={user.profile_pic}
        isLoggedIn={user.token ? true : false} 
        isSignIn={user.skip_status && user.skip_status < 3}
        notiCount={user.notification || 0}
    />
)
  
const mapStateToProps = ({user}) => ({
    user
})

export default withRouter(connect(mapStateToProps)(ConnectHeader))
