import React from "react";
import {
    ModalCon,
    CloseModalWrapper,
    CloseModalBtn
} from "../../containers/Modal/closemodal";
import { Link } from "react-router-dom";

//modal name "ERROR_MODAL"
const ErrModal = ({
    title = "",
    description = "",
    button_name,
    button_link
}) => (
    <CloseModalWrapper className="modal-wrapper">
        <ModalCon className="modal-container p-0" style={{ maxWidth: `500px` }}>
            <div className="d-flex justify-content-between align-items-center gradientbg p-3">
                <h5 className="m-0">{title}</h5>
                <CloseModalBtn
                    className="btn p-0"
                    style={{ background: "transparent" }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </CloseModalBtn>
            </div>
            <div className="pl-5 pr-5 pt-4 pb-4">
                <p className="text-center">{description}</p>
            </div>
            <div className="d-flex justify-content-end p-2">
                <CloseModalBtn className="btn btn-outline-req pr-3 pl-3 mr-3">
                    Close
                </CloseModalBtn>
                {button_name && button_link && (
                    <Link to={button_link} className="btn custom-btn">
                        {button_name}
                    </Link>
                )}
            </div>
        </ModalCon>
    </CloseModalWrapper>
);

export default ErrModal;
