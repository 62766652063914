import { LOGIN, UPDATE_PROFILE, LOGOUT, _FULFILLED, REGISTER, SIGNUP, OTP_VERIFY, UPLOAD_PHOTOS, PARTNER_PREFER, CHANGE_PWD, DELETE_PHOTOS, PRIVACY, RESEND_OTP, MY_PROFILE, FORGOTPWD, RESETPWD, CONTACT_US, UPDATE_USER, SET_PRO_PIC} from "../actions/constants";
import { httpObjectReducer } from "./httpreducer";

//login reducer
const user = (state = {}, action) => {
    switch (action.type) {
        case `${LOGIN}${_FULFILLED}`:
        case `${RESETPWD}${_FULFILLED}`:
        case `${SIGNUP}${_FULFILLED}`:
        case `${REGISTER}${_FULFILLED}`:
        case `${UPLOAD_PHOTOS}${_FULFILLED}`:
        case `${DELETE_PHOTOS}${_FULFILLED}`:
        case `${SET_PRO_PIC}${_FULFILLED}`:
        case `${PARTNER_PREFER}${_FULFILLED}`:
        case `${PRIVACY}${_FULFILLED}`:
        case `${MY_PROFILE}${_FULFILLED}`:
        case `${OTP_VERIFY}${_FULFILLED}`:
        case UPDATE_USER:
            return { ...state, ...action.payload }
        case `${LOGOUT}${_FULFILLED}`:
            return {}
        default:
            return state
    }
}

//login reducer
const login_form = httpObjectReducer(LOGIN);

//signup form
const signup = httpObjectReducer(SIGNUP);

//register reducer
const register = httpObjectReducer(REGISTER);

//login reducer
const update_profile = httpObjectReducer(UPDATE_PROFILE);

//logut reducer
const logout = httpObjectReducer(LOGOUT);

//OTP verify reducer
const otpVerify = httpObjectReducer(OTP_VERIFY);

//resend otp
const resend_otp = httpObjectReducer(RESEND_OTP);

//upload photo
const upload_photo = httpObjectReducer(UPLOAD_PHOTOS);

//delete photo
const delete_photo = httpObjectReducer(DELETE_PHOTOS);

const set_profile_pic = httpObjectReducer(SET_PRO_PIC);

//partner preferences
const partner_prefer = httpObjectReducer(PARTNER_PREFER);

//change password
const change_pwd_form = httpObjectReducer(CHANGE_PWD); 

//privacy setting
const privacy_form = httpObjectReducer(PRIVACY);

//logout
const log_out = httpObjectReducer(LOGOUT);

//my profle
const my_profile = httpObjectReducer(MY_PROFILE);

//forgot pwd
const forgot_pwd = httpObjectReducer(FORGOTPWD);

//reset pwd
const reset_pwd = httpObjectReducer(RESETPWD);

const contact_us = httpObjectReducer(CONTACT_US);

export default {
    user,
    signup,
    register,
    login_form,
    update_profile,
    logout,
    otpVerify,
    upload_photo,
    delete_photo,
    partner_prefer,
    change_pwd_form,
    privacy_form,
    log_out,
    resend_otp,
    my_profile,
    forgot_pwd,
    reset_pwd,
    contact_us,
    set_profile_pic
};