import React from 'react'

const PageHeading = ({ heading }) => {
    return (
        <div className="page-heading">
            <h1>{heading}</h1>
        </div>
    )
}

export default PageHeading
