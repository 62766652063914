import { signupvalues } from "./mastervalues";

const signupinputs = [
    {
        size: 12,
        left: true,
        inputProps: {
            type: "pureText",
            value: "",
            name: "first_name",
            placeholder: "Full Name",
            maxLength: 30
        }
    },
    // {
    //     size: 6,
    //     inputProps: {
    //         type: "pureText",
    //         value: "",
    //         name: "last_name",
    //         placeholder: "Last Name",
    //         maxLength: 30
    //     }
    // },
    {
        dependData: "profileFor",
        inputProps: {
            type: "select",
            value: null,
            name: "profile_for_id",
            placeholder: "Create profile for",
            options: signupvalues.relationship
        }
    },
    {
        size: 6,
        left: true,
        inputProps: {
            type: "select",
            value: null,
            name: "gender",
            placeholder: "Gender",
            options: signupvalues.gender
        }
    },
    {
        size: 6,
        inputProps: {
            type: "select",
            value: null,
            name: "mother_tongue_id",
            placeholder: "Mother tongue",
            options: signupvalues.languages
        }
    },
    {
        size: 6,
        left: true,
        inputProps: {
            type: "select",
            value: null,
            name: "religion_id",
            placeholder: "Religion",
            options: signupvalues.religions,
            decendant: "caste_id",
            decendentOptionName: "castes"
        }
    },
    {
        size: 6,
        inputProps: {
            type: "select",
            value: null,
            name: "caste_id",
            placeholder: "Caste",
            options: []
        }
    },
    {
        size: 4,
        left: true,
        dependData: "phValid",
        inputProps: {
            type: "select",
            name: "country_id",
            value: signupvalues.dial_codes[0],
            placeholder: "+91",
            options: signupvalues.dial_codes
        }
    },
    {
        size: 8,
        inputProps: {
            type: "number",
            value: "",
            name: "username",
            placeholder: "Phone number",
            maxLength: "12"
        }
    },
    {
        size: 6,
        left: true,
        inputProps: {
            type: "email",
            value: "",
            name: "email",
            placeholder: "Email"
        }
    },
    {
        size: 6,
        inputProps: {
            type: "password",
            value: "",
            name: "password",
            placeholder: "Password"
        }
    }
    // {
    //     size: 12,
    //     inputProps: {
    //         type: "checkbox",
    //         value: '',
    //         name: 'terms',
    //         checklabel: ["I agree to Terms and Conditions."],
    //         checked: false
    //     }
    // }
];

export default signupinputs;
