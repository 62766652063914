import React, { Component } from 'react'
// import * as qs from 'query-string';
import { connect } from 'react-redux'
import listAction from "../redux/actions/listaction"
import DynamicList from '../components/dynamiclist';
import Pagination from '../containers/common/pagination';
import MatchesLoaders from '../components/Loaders/loaders'

const listhocpaginations = (RenderItem, actionName, reducerName, api, Loader = null) => {
    
    class ListhocPaginations extends Component {

        componentDidMount() {
            const { url, noNext, dispatch } = this.props;
            const query_string = new URLSearchParams(this.props.location.search);
            let new_api = query_string.get("page") ? api + "&page=" + query_string.get("page") : api
            dispatch(
                listAction("LOAD", actionName, `${new_api || url}${noNext || ''}`)
            );
        }

        render() {
            let { list, url, noNext, match, ...restProps } = this.props;
            const query_string = new URLSearchParams(this.props.location.search);

            let { fetching, error, data, nextFetching, nextError, count, page_max_size } = list;

            if (fetching) {
                return (
                    <MatchesLoaders />
                )
            } else {

                return (
                    <div>
                        {
                            <Pagination classname={"justify-content-end"} count={count} page_size={page_max_size} api={api}
                                actionName={actionName} current_page={query_string.get("page")} {...this.props} query_string={query_string}
                            />
                        }
                        <div className={`row`}>
                            <DynamicList
                                RenderItem={RenderItem}
                                fetching={fetching}
                                error={error}
                                data={data}
                                Loader={Loader}
                                {...restProps}
                            />


                        </div>
                        {Loader && !noNext && nextFetching && !nextError && <Loader />}
                        {
                            <Pagination count={count} page_size={page_max_size} api={api} query_string={query_string}
                                actionName={actionName} current_page={query_string.get("page")} {...this.props}
                            />
                        }
                    </div>
                )
            }
        }

        componentWillUnmount() {
            this.props.dispatch(
                listAction("UNLOAD", actionName, api)
            );
        }

    }

    const mapStateToProps = (state) => ({
        list: state[reducerName]
    })

    return connect(mapStateToProps)(ListhocPaginations)
}

export default listhocpaginations