import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from '../Register/formbox';
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { setFormErr, setFormValue } from '../../redux/actions/formactions';
import { validate, reduceForm } from '../../utils/Forms/validation';
import { CONTACT_FORM, CONTACT_US } from '../../redux/actions/constants';
import FormCon from '../Form/formcon';
import { sendIssue } from '../../redux/actions/user';


export class ContactUS extends Component {

    handleSubmit = e => {
        e.preventDefault();
        const { setFormErr, sendIssue, contact_form } = this.props;
        const setErr = payload => setFormErr(CONTACT_FORM, payload);
        const isErr = validate(contact_form, setErr);
        if (isErr) {
            return;
        }
        const formData = reduceForm(contact_form);
        sendIssue(formData);
    }

    componentDidMount() {
        this.setValues();
    }

    componentDidUpdate(prevProps) {
        if(this.props.user && this.props.user.user !== prevProps.user.user) {
            this.setValues();
        }
    }

    setValues = () => {
        const { user, setFormValue } = this.props;
        if(user.user) {
            const cuser = user.user;
            const setValue = (name, value) => setFormValue(CONTACT_FORM, {
                name, data: { value }
            });
            setValue("name", cuser.first_name + " " + cuser.last_name);
            setValue("email", cuser.email);
            setValue("phone_number", cuser.username);
        }
    }

    render() {
        const { fetching, data } = this.props.contact_us;
        return (
            <FormCon
                toastMsg={`Message sent`}
                clear={[CONTACT_FORM, CONTACT_US]}
                unMount={data.id}
                onSubmit={this.handleSubmit}
            >
                <Formbox
                    title="Contact us"
                    formName={CONTACT_FORM}
                    inputsName="contact_form"
                    fullWidth
                >
                    <div className="row pt-4">
                        <div className="col-md-12">
                            <Center>
                                <LoadingBtn
                                    className="btn custom-btn registerbtn"
                                    fetching={fetching}
                                    disabled={fetching}
                                    onSubmit={this.handleSubmit}
                                >
                                    Submit
                                </LoadingBtn>
                            </Center>
                        </div>
                    </div>
                </Formbox>
            </FormCon>
        )
    }
}

const mapStateToProps = ({ contact_form, contact_us, user }) => ({
    contact_form, contact_us, user
})

const mapDispatchToProps = {
    setFormErr, sendIssue, setFormValue
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUS)