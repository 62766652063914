import React from 'react'
import DynamicList from '../../components/dynamiclist';
import CardProfile from '../../components/Dashboard/cardProfile';
import { Link } from 'react-router-dom';

const DashBoardMatch = props => (
    <div className="col-lg-4 col-md-4 col-6 dash-profile">
        <CardProfile {...props} />
    </div>
)

const ActMatch = ({ actor }) => (
    <div className="col-lg-4 col-md-4 col-6 dash-profile">
        <CardProfile {...actor} />
    </div>
)

const ProfileCardBox = ({ apiData, data, actor = false, count, name, url }) => {

    return (

        <div className="bx-shdw">
            <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 bx-shdw-title">
                    {name} {count ? `(${count})` : ""}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 text-right">
                    {url ?
                        <Link to={url} className="bx-shdw-sub-title">View all profiles </Link> :
                        <span className="bx-shdw-sub-title">View all profiles</span>
                    }
                </div>

            </div>
            <div className="row">
                <DynamicList
                    RenderItem={actor ? ActMatch : DashBoardMatch}
                    fetching={apiData.fetching}
                    data={data}
                    error={apiData.error}
                    title={name}
                />
            </div>
        </div>
    )
}

export default ProfileCardBox