import React, { Component } from 'react'
import { connect } from 'react-redux'
import Portal from "../../components/common/portal"
import { removeToast } from "../../redux/actions/common"
import ToastMsg from './toastmsg'
import "../../assets/css/toast.css"

class ToastContainer extends Component {
    render() {
        return (
            <Portal>
                <div className="toast-container">
                    {
                        this.props.toast.map(t =>
                            <ToastMsg {...t} remove={this.props.removeToast} key={`toast-${t.id}`} />
                        )
                    }
                </div>
            </Portal>
        )
    }
}

const mapStateToProps = ({ toast }) => ({
    toast
})

const mapDispatchToProps = {
    removeToast
}

const Toast = connect(mapStateToProps, mapDispatchToProps)(ToastContainer);

export default Toast




