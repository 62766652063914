import React, { Component } from 'react'
import { connect } from 'react-redux'
import SubmitBtn from '../common/submitbtn';
import { LOGOUT, _FULFILLED } from '../../redux/actions/constants';

export class Deactive extends Component {

    handleSucc = () => {
        this.props.dispatch({
            type: `${LOGOUT}${_FULFILLED}`
        });
    }


    render() {
        const  { reason } = this.props;
        return (
            <SubmitBtn 
                className="btn custom-btn"
                config={{
                    url: "users/deactivate/",
                    method: "POST",
                    data: {reason}
                }}
                succFunc={res => this.handleSucc(res)}
            >
                Submit
            </SubmitBtn>
        )
    }
}

export default connect(null)(Deactive)
