import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleInputChange } from '../../redux/actions/formactions';
import { NumberOnlyInput } from '../../components/Form/numberip';

export class CInput extends Component {

    setValue = (e, format = (val) => val ) => {
        const {name, value} = e.target;
        const { handleInputChange, formName } = this.props;
        handleInputChange( formName, {name, value: format(value)});
    }

    handleChange = (e) =>{
        this.setValue(e);
    }

    preventChar = (event) =>{
        if(!(event.charCode >= 48 && event.charCode <= 57)){
            event.preventDefault();
            return;
        }
    }

    handleTextarea = (e) => {
        this.setValue(e, (val) => val.replace(/[^a-z'"?. ]/gi, '') );
    }

    pureTextChange = e => {
        this.setValue(e, (val) => val.replace(/[^a-z ]/gi, '') );
    }

    render() {
        const {type, formName, handleInputChange, value, ...restProps} = this.props;
        //remove null values
        const inputValue = value || "";
        if(type === 'number'){
            return (
                <NumberOnlyInput value={inputValue} onChange={this.handleChange} {...restProps}/>
            )
        }
        if(type === 'pureText'){
            return (
                <input type={"text"}  value={inputValue} onChange={this.pureTextChange} {...restProps}/>
            )
        }
        if(type === 'textarea'){
            return (
                <textarea  value={inputValue} onChange={this.handleTextarea} {...restProps}/>
            )
        }
        return (
            <input type={type}  value={inputValue} onChange={this.handleChange} {...restProps}/>
        )
    }
}

const mapDispatchToProps = {
    handleInputChange
}

export default connect(null, mapDispatchToProps)(CInput)
