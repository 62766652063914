import React from 'react';
import MatchNav, { matchesLinks } from './matchesNav'
import RouteTabs from '../Routes/routetabs';
import { Matchestabs } from '../../containers/Matches/matches';



const Matches = () => {
    return (
        <div className="container custom-container pt-3 pb-3">
            <div className="d-flex mt-2 mb-2">
                <div className="col-md-9 col-12">
                    <div className="f-600 f-24 clr-black1">
                        Matches based on your preferences
                    </div>
                </div>
                <div className="col-md-3 col-12 d-none d-sm-block">
                    <div className="clr-orange1">
                        Edit preferences for relevant matches
                    </div>
                </div>
            </div>

            
            <div className="d-flex">
                <div className="col-12">

                    <MatchNav links={matchesLinks} />

                </div>

            </div>

            <div className="d-flex">
                <div className="col-md-12 col-sm-12 col-12">
                    <div className="bx-shdw">
                        <RouteTabs
                            title="matches"
                            tabs={Matchestabs}
                        />

                    </div>
                </div>
            </div>

        </div>
    );
};

export default Matches;