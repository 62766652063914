import React, { Component } from "react";
import { connect } from "react-redux";
import List from "../../components/list";
import Center from "../../components/center";
import Formbox from "../Register/formbox";
import {
  reduceForm,
  getFormNames,
  toQuery,
} from "../../utils/Forms/validation";
import { partnerSubmit } from "../../redux/actions/user";
import { advInputform } from "../../utils/Forms/advsearchinputs";
import { PARTNER_PREFER } from "../../redux/actions/constants";
import { setFormValue } from "../../redux/actions/formactions";
import { withRouter } from "react-router-dom";
import InitSearch from "./initSearch";
import LoadingBtn from "../../components/Buttons/loadingbtn";
import Formcon from "../Form/formcon";

const formnames = getFormNames(advInputform);
export class AdvForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    let searchData = {};

    advInputform.forEach((x) => {
      const { inputsName } = x;
      const datalist = this.props[inputsName];
      searchData = { ...searchData, ...reduceForm(datalist) };
    });
    if(searchData?.physical_status ==="Doesn't matter"){
      delete searchData.physical_status; 
    }
    if(searchData?.smoking_habits ==="D"){
      delete searchData.smoking_habits; 
    }
    if(searchData?.food_habits ==="D"){
      delete searchData.food_habits; 
    }
    if(searchData?.undefined ==="D"){
      delete searchData.undefined; 
    }
    if (searchData?.age) {
      searchData["max_age"] = searchData.age.max;
      searchData["min_age"] = searchData.age.min;
      delete searchData.age;
    }
    if (searchData?.income) {
      searchData["max_income"] = searchData.income.max * 100000;
      searchData["min_income"] = searchData.income.min * 100000;
      delete searchData.income;
    }
    if (searchData?.weight) {
      searchData["max_weight"] = searchData.weight.max;
      searchData["min_age"] = searchData.weight.min;
      delete searchData.weight;
    }
    if (searchData?.height) {
      searchData["max_height"] = searchData.height.max;
      searchData["min_height"] = searchData.height.min;
      delete searchData.height;
    }
    return this.props.history.push(`/search/results${toQuery(searchData)}`);
  };

  render() {
    let listProps = {
      RenderItem: Formbox,
      list: advInputform,
      title: "quick_search_formwere",
    };
    return (
      <InitSearch inputForm={advInputform}>
        <Formcon
          clear={[PARTNER_PREFER, ...formnames]}
          onSubmit={this.handleSubmit}
        >
          <List {...listProps} />
          <div className="row">
            <div className={`col-md-12`}>
              <Center>
                <div>
                  <LoadingBtn
                    onSubmit={this.handleSubmit}
                    className="btn custom-btn"
                    disabled={this.props.formData.fetching}
                    type="submit"
                    fetching={this.props.formData.fetching}
                  >
                    Submit
                  </LoadingBtn>
                </div>
              </Center>
            </div>
          </div>
        </Formcon>
      </InitSearch>
    );
  }
}

const mapStateToProps = ({
  partner_prefer,
  user,
  basic_advsearch,
  edu_advsearch,
  astro_advsearch,
  habit_advsearch,
}) => ({
  basic_advsearch,
  edu_advsearch,
  astro_advsearch,
  habit_advsearch,
  formData: partner_prefer,
  intialData: user.partner_preferences,
});

const mapDispatchToProps = {
  partnerSubmit,
  setFormValue,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdvForm)
);
