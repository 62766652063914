import React, { Component } from 'react'

export default class SearchById extends Component {

    state = {
        uid: ''
    };

    handleChange = e => {
        const {name, value} = e.target;
        this.setState({ [name]: value });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.history.push(`/search/results?uid=${this.state.uid}`);
    };
    
    render() {
        return (
            <form className="row align-items-center" noValidate onSubmit={this.onSubmit}>
                <div className="col-8 pr-0 mb-3">
                    <input name="uid" value={this.state.uid} onChange={this.handleChange} placeholder={"Search by id"} className="form-control" type="text" />
                </div>
                <div className="col-4 pl-0 mb-3">
                    <button type="submit" className="btn search-by-id-btn">
                        Search
                    </button>
                </div>
            </form>
        )
    }
}
