import React from 'react'
import Section from '../section';
import CustomBtn from '../Buttons/CustomBtn';
import wedding1 from "../../assets/img/wedding1.jpeg";
import wedding2 from "../../assets/img/wedding2.jpeg";
import wedding3 from "../../assets/img/wedding3.jpeg";

const Info = () => (
    <Section className="landing-section-pad">
        <div className="col-md-12 pb-5 mb-4">
            <h1 className="text-center mb-1">Success Stories</h1>
            <p className="text-center mb-1">Happy Stories of Tamil Marriages</p>
            <h2 className="horizontalline">
                <span className="horizontaltext">
                    <i className="fa fa-heart theme-color" aria-hidden="true"></i>
                </span>
            </h2>
        </div>
        <div className="col-md-12">
            <div className="row align-items-center">
                <div className="col-xs-12 col-sm-6 col-md-4">
                    <div className="wedding-block mb-5  border">
                        <div className="wedding-img mb-3">
                            <img className="img-responsive" alt="Ravi & Soundarya"
                                src={wedding1} />
                        </div>
                        <div className="wedding-info mt-3 mb-3 text-center p-2">
                            <h5 className="mt-3 mb-2 title font-weight-normal theme-color">Ravi & Soundarya</h5>
                            <p className="f-14">We meet through Santhosham Matrimony. on first talk we exchange numbers and stat talking. than after we decide to go further and talk to our parents. than after through parents we meet officially. than we both agree to get marry with each other. and spend our life with each other. i m lucky to have her in my life</p>
                            <p className="theme-color">
                                <small>12 March 2020</small>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                    <div className="wedding-block mb-5  border">
                        <div className="wedding-img mb-3">
                            <img className="img-responsive" alt="Karthikeyan & Aishwarya"
                                src={wedding2} />
                        </div>
                        <div className="wedding-info mt-3 mb-3 text-center p-2">
                            <h5 className="mt-3 mb-2 title font-weight-normal theme-color">Karthikeyan & Aishwarya</h5>
                            <p className="f-14">We truly love being with one another and are extremely happy with our lives together. It is a match made in heaven. Thank You Santhosham Matrimony</p>
                            <p className="theme-color">
                                <small>22 September 2019</small>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                    <div className="wedding-block mb-5  border">
                        <div className="wedding-img mb-3">
                            <img className="img-responsive" alt="Senthil & Monisa"
                                src={wedding3} />
                        </div>
                        <div className="wedding-info mt-3 mb-3 text-center p-2">
                            <h5 className="mt-3 mb-2 title font-weight-normal theme-color">Senthil & Monisa</h5>
                            <p className="f-14">Hello, I would like to Thank Santhosham Matrimony and team for helping me finding my soul mate. We both and our family are extremely happy with our decision of meeting first and develop our bonding and finally decided to get married. Thank you once again.</p>
                            <p className="theme-color">
                                <small>8 May 2019</small>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="d-flex justify-content-center">
                <CustomBtn className={"regfreebtn"} onClick={() => {
                    window.scrollTo(0, 0);
                }}>Register Free  - Get Started! </CustomBtn>
            </div>
        </div>

    </Section>
)

export default Info
