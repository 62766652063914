import FileInput from './fileinput';
import ImageInput from './imageinput';
// import RangeSlider from '../../containers/Form/crangeslider';
import CSelect from '../../containers/Form/cselect';
import CDateInput from '../../containers/Form/cdateinput';
import CInput from '../../containers/Form/cinput';
import CCheckBox from '../../containers/Form/ccheckbox';
import Cradiogroup from '../../containers/Form/cradiogroup';
import RangeSelect from '../../containers/Form/crangeselect';



const renderInput = (type) => {
    switch(type){
        case "select":
            return CSelect
        case "date":
            return CDateInput
        case "radioGroup":
            return Cradiogroup
        case "range":
            return RangeSelect
        case "file":
            return FileInput
        case "image":
            return ImageInput
        case "checkbox":
            return CCheckBox
        case "number":
        case "textarea":
        case "text":
        default:
            return CInput
    }
}

export default renderInput
