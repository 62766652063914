import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from "react-router-dom"

const skip = {
    1: "/register",
    2: "/otp",
    3: "/upload-photos",
    // 4: "/partner-preferences",
    "default": "/dashboard"
};

const HomeRoute = ({user, ...restProps}) => {
    if(user.token){
        return <Redirect to={skip[user.skip_status || "default"] || "/dashboard"} />
    }
    return (
        <Route {...restProps} />
    )
}

const mapStateToProps = ({ user }) => ({
    user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(HomeRoute)