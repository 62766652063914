import { _FETCHING, _FULFILLED, _REJECTED } from "./constants";
import httpStatusErr from "./httpStatusErr";

//common action for all network request
const httpAction = (name, config) => {
    //check if config is an array
    const isConfigArray = Array.isArray(config);

    return async (dispatch, getState, api) => {
        //dispatch fetching action
        dispatch({
            type: `${name}${_FETCHING}`
        });
        try {
            //network request(s)
            const result = isConfigArray
                ? await Promise.all(config.map(conf => api(conf)))
                : await api(config);
            //dispatch fulfilled action
            dispatch({
                type: `${name}${_FULFILLED}`,
                payload: result.data || { message: "success" }
            });
        } catch (err) {
            //handle error
            if (err.response) {
                dispatch({
                    type: `${name}${_REJECTED}`,
                    payload: "error"
                });
                dispatch(httpStatusErr(err.response));
            } else {
                dispatch({
                    type: `${name}${_REJECTED}`,
                    payload: "Server Unreachable"
                });
            }
        }
    };
};

export default httpAction;
