import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from './formbox';
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { Link } from "react-router-dom";
import { setFormErr, clearForm } from '../../redux/actions/formactions';
import { validate, reduceForm } from '../../utils/Forms/validation';
import { otpSubmit, otpSubmitClear, resendOtp } from '../../redux/actions/user';
import { OTP, OTP_VERIFY, RESEND_OTP } from '../../redux/actions/constants';
import { Redirect } from "react-router-dom";
import FormCon from '../Form/formcon';

export class OtpForm extends Component {

    state = {
        seconds: 0
    };

    tick() {
        this.setState(state => ({
            seconds: state.seconds - 1
        }));
    }

    handleSubmit = e => {
        e.preventDefault();
        const { setFormErr, otp_input, otpSubmit } = this.props;
        const setErr = payload => setFormErr(OTP, payload);
        const isErr = validate(otp_input, setErr);
        if (isErr) {
            return;
        }
        const { otp } = reduceForm(otp_input);
        otpSubmit({ otp });
    }

    resend = () => {
        const { resendOtp, user } = this.props;
        this.setState({ seconds: 25 }, () => {
            this.interval = setInterval(() => this.tick(), 1000);
        })
        resendOtp({ mobile_number: user.user.username });
    }

    componentDidUpdate() {
        if (this.state.seconds === 0) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { user, otpVerify } = this.props;
        if (otpVerify.data.id) {
            let to = user.photos && user.photos.length > 0 ? "/dashboard" : "/upload-photos";
            return <Redirect to={to} />
        }
        return (
            <div>

                <FormCon
                    clear={[OTP, OTP_VERIFY, RESEND_OTP]}
                    onSubmit={this.handleSubmit}
                >
                    <h2 className='text-center mt-3'>OTP Verification</h2>
                    <h6 className='text-center mt-3 theme-support-color f-26'>{user.user.username}</h6>
                    <Formbox
                        formName={OTP}
                        inputsName="otp_input"
                    />
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <Center>
                                <LoadingBtn
                                    className="btn custom-btn registerbtn"
                                    fetching={otpVerify.fetching}
                                    disabled={otpVerify.fetching}
                                    onSubmit={this.handleSubmit}
                                    type="submit"
                                >
                                    Submit
                            </LoadingBtn>
                            </Center>
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8">
                                    <Center className="f-13 mt-2">
                                        <span className="mr-1">Did not recieve OTP? </span>
                                        {this.state.seconds === 0 ?
                                            <button className="btn btn-link p-0 m-0 f-12" onClick={this.resend}>Resend OTP</button> :
                                            <span>Wait for {this.state.seconds}s</span>
                                        }
                                    </Center>
                                    <Center className="f-13 mt-1">
                                        <span className="mr-1">Wrong mobile number?</span>
                                        <Link to="/change-mobile-number">Change mobile number</Link>
                                    </Center>
                                    <div className="col-md-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormCon>

            </div>
        )
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
}

const mapStateToProps = ({ user, otp_input, otpVerify, resend_otp }) => ({
    user, otp_input, otpVerify, resend_otp
})

const mapDispatchToProps = {
    otpSubmit, setFormErr, otpSubmitClear, resendOtp, clearForm
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpForm)
