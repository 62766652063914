import React from 'react'
import icon from "../../assets/img/cancel.png"
import PayStatus from './PayStatus';

const FailurePage = () => {
    return (
        <PayStatus
            icon={icon}
            to="/upgrade"
            title="Payment Failed"
            toTitle="upgrade page"
        >
            You have landed on the payment failure page.<br></br>
            Please click the below to go to upgrade page.
        </PayStatus>
    )
}

export default FailurePage