import { _FETCHING, _FULFILLED, _REJECTED, _CLEAR, LIST_PACKAGES, RAZOR_PAY, CHOOSE_PACKAGE } from "../actions/constants";
import { httpObjectReducer } from "./httpreducer";

const packagesInitial = { 
    fetching: false, 
    packages: [], 
    add_on: [], 
    coupon: null,
    chosenPackage: null,
    error: false 
};

//list packages
const list_packages = (state = packagesInitial, action) => {
    switch (action.type) {
        case `${LIST_PACKAGES}${_FETCHING}`:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case `${LIST_PACKAGES}${_FULFILLED}`:
            return {
                ...state,
                fetching: false,
                packages: action.payload.packages,
                add_on: action.payload.add_on
            }
        case `${LIST_PACKAGES}${_REJECTED}`:
            return {
                ...state,
                fetching: false,
                error: action.payload
            }
        case CHOOSE_PACKAGE:
            return {
                ...state, 
                chosenPackage: state.chosenPackage === action.id  ? 
                null : action.id
            }
        case `${LIST_PACKAGES}${_CLEAR}`:
            return { ...state, ...packagesInitial }
        default:
            return state
    }
}

// payment success
const razor_succ = httpObjectReducer(RAZOR_PAY);

export default {
    list_packages, razor_succ
}