import React from 'react'
import icon from "../../assets/img/correct.png"
import PayStatus from './PayStatus';

const SuccessPage = () => {
    return (
        <PayStatus icon={icon}>
            You have landed on the payment success page.<br></br>
            Please click the below to go to profile page.
        </PayStatus>
    )
}

export default SuccessPage
