import React from 'react'
import RegisterLayout from './registerlayout';
import ResetPwdForm from '../../containers/Register/resetpwd';

const ResetPwdPage = props => (
    <RegisterLayout
        heading={
            <span>Almost there!</span>
        }
    >
        <ResetPwdForm {...props} />
    </RegisterLayout>
)

export default ResetPwdPage