//form validation
export const validate = (list, setErr) => {
    let isErr = null;
    for (let i = 0; i < list.length; i++) {
        const { inputProps } = list[i];
        if (!inputProps.isOptional || inputProps.value) {
            const invalid = isvalid(inputProps);
            if (invalid) {
                setErr({ err: invalid, name: inputProps.name }) 
                if(!isErr) isErr = inputProps.name;
            }
        }
    }
    return isErr;
}

//scroll into view
export const scrollToEL = elName => {
    if(document) {
        const el = document.querySelector(`[name='${elName}']`);
        if(el){
            el.parentNode.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        }
    }
}

//check validity
export const isvalid = (inputProps) => {
    const { type, minLength, value, max, isOptional } = inputProps;
    if (type === "hidden") {
        return '';
    }
    if (minLength) {
        return value && (value.length >= minLength) ? '' : `Must be atleast ${minLength} characters`;
    }
    if (type === "email") {
        return emailValid(value) ? '' : "Email is invalid";
    }
    if (type === "password") {
        return pwdValid(value) ? '' : "Password is invalid";
    }
    if (type === "date") {
        return dateValid(value) ? '' : "Date is invalid";
    }
    if (type === "range") {
        return value.min < value.max ? "" : "From value must less to value";
    }
    if(type === "radioGroup") {
        if(isOptional) {
            return ""
        } else {
            return value.length > 0 ? '' : 'This field is required'
        }
    }
    if (type === "number") {
        let err = "";
        const num = parseInt(value, 10);
        if( isNaN(parseInt(num, 10))) {
            err = "Invalid Number";
        } else if(!isNaN(parseInt(max, 10)) && num > parseInt(max, 10)) {
            err = `Number is greater than ${parseInt(max, 10)}`;
        }
        return err;
    }
    return value ? '' : 'This field is required'
}

//reduce form to values
export const reduceForm = list => {
    return list.reduce((acc, cur) => {
        if(cur.inputProps.type !== "hidden"){
            const val = reduceValue(cur.inputProps);
            if(val !== undefined && val !== null && val !== '') {
                return {
                    ...acc,
                    [cur.inputProps.name]: val
                }
            } else {
                return acc
            }
        }
        return acc
    }, {})
}

//Reduce form names from from array
export const getFormNames = form => form.reduce((acc, cur) => [...acc, cur.formName], []);

//reduce value to reduce form function
export const reduceValue = inputProps => {
    const { value, type, checked, isMulti } = inputProps;
    switch (type) {
        case "select":
            if (value) {
                if (isMulti) {
                    return value.reduce((acc, cur) => {
                        if(cur.value) {
                            return [...acc, cur.value]
                        } else {
                            return acc
                        }
                    } , []);
                }
                return value.value
            }
            return null;
        case "radioGroup":
            if (!isMulti) {
                return value[0];
            }
            return value
        case "checkbox":
            return checked
        case "hidden":
            return ''
        case "date":
            return dateValid(value) ? `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}` : ''
        default:
            return inputProps.value
    }
}

//filter post data (remove unwanted values)
export const filterPostData = data => {
    for (let keys in data) {
        if (!data[keys]) {
            delete data[keys]
        }
        if (Array.isArray(data[keys])) {
            if (data[keys].length === 0) {
                delete data[keys]
            }
        }
    }
    return data;
}

//to search query
export const toQuery = data => {
    const query = [];
    for (let key in data) {
        query.push(`${key}=${data[key].toString()}`);
    }
    return '?' + query.join("&");
}

//map select values
export const mapSelect = data => data.reduce((acc, cur) => {
    return [...acc, { value: cur.value || cur.id, label: cur.label || cur.name }]
}, [])

//reduce intial values to form compatible values
export const initValue = data => {
    for (let keys in data) {
        if (data[keys] && typeof (data[keys]) === "object") {
            if (Array.isArray(data[keys])) {
                data[keys] = mapSelect(data[keys]);
            } else if (data[keys].hasOwnProperty("id") && data[keys].hasOwnProperty("name")) {
                data[keys] = { value: data[keys].id, label: data[keys].name };
            }
        }
    }
    return data;
}

//check email
export const emailValid = val => {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(val);
}

export const emptyValid = val => val.length > 0

export const phoneValid = val => {
    var re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return re.test(val);
}

export const pwdValid = val => {
    return val.length > 5;
}

export const dateValid = val => val && val.getMonth !== undefined

export const urlParams = url => {
    var keys = {};
    var params = decodeURIComponent(url).replace("?", "").split("&");
    for (var i = 0; i < params.length; i++) {
        var x = params[i].split("=");
        keys[x[0]] = x[1];
    }
    return keys;
}
