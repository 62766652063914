import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from './formbox';
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { setFormErr } from '../../redux/actions/formactions';
import { validate, reduceForm } from '../../utils/Forms/validation';
import { Redirect } from "react-router-dom";
import FormCon from '../Form/formcon';
import { RESET_FORM, RESETPWD } from '../../redux/actions/constants';
import { resetPwd } from '../../redux/actions/user';
import { addToast } from '../../redux/actions/common';

export class ResetPwdForm extends Component {

    urlParams = url => {
        var keys = {};
        var params = decodeURIComponent(url).replace("?", "").split("&");
        for (var i = 0; i < params.length; i++) {
            var x = params[i].split("=");
            keys[x[0]] = x[1];
        }
        return keys;
    }

    handleSubmit = e => {
        e.preventDefault();
        const { setFormErr, resetpwd_input, resetPwd, location, addToast } = this.props;
        const { id, otp } = this.urlParams(location.search);
        if(!id && !otp){
            addToast("Invalid Reset page url", false);
        }
        const setErr = payload => setFormErr(RESET_FORM, payload);
        const isErr = validate(resetpwd_input, setErr);
        if (isErr) {
            return;
        }
        const { password, retype_password } = reduceForm(resetpwd_input);
        if(password !== retype_password){
            setFormErr(RESET_FORM, {err: "Password does not match", name: "password"});
            return;
        }
        if(id && otp){
            resetPwd({ 
                id, 
                otp,
                password,
                client: Math.random() * 100000 
            });
        }
    }

    render() {
        const { reset_pwd } = this.props;
        if (reset_pwd.data.id) {
            return <Redirect push={false} to="/dashboard" />
        }
        return (
            <div>
                <FormCon
                    clear={[RESET_FORM, RESETPWD]}
                    onSubmit={this.handleSubmit}
                >
                    <Formbox
                        title="Reset Password"
                        description={`Reset your password and login`}
                        formName={RESET_FORM}
                        inputsName="resetpwd_input"
                    />
                    <div className="row">
                        <div className="col-md-8">
                            <Center>
                                <LoadingBtn
                                    className="btn custom-btn registerbtn"
                                    fetching={reset_pwd.fetching}
                                    disabled={reset_pwd.fetching}
                                    onSubmit={this.handleSubmit}
                                    type="submit"
                                >
                                    Submit
                                </LoadingBtn>
                            </Center>
                        </div>
                    </div>
                </FormCon>
            </div>
        )
    }
}

const mapStateToProps = ({ resetpwd_input, reset_pwd }) => ({
    resetpwd_input, reset_pwd
})

const mapDispatchToProps = {
    setFormErr, resetPwd, addToast
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPwdForm)