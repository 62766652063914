import React from 'react';
import Center from '../center';
import List from '../list';
import SignupIp from '../Form/signupip';

const FormBox = ({children, inputs = [], title, listIndex, description, formName, fullWidth = false }) => (
    <div className="row">
        <div className={`col-md-${description ? 8 : 12}`}>
            <div className={`bx-shdw ${listIndex && !fullWidth ? `mt-4` : ''} mb-4`}>
                
                <div className="container pt-3 pb-3 ">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="m-0 pl-1 form-title">{title}</h5>
                        </div>
                    </div>
                </div>
                <div className="container pt-3 pb-3">
                    <div className="row">
                        <div className={`col-md-${fullWidth ? '12' : '10'}`}>
                            <div className="row">
                                {

                                    inputs && 
                                    <List 
                                        RenderItem={SignupIp} 
                                        list={inputs} 
                                        formName={formName}
                                        title={title + "-form"}
                                    /> 
                                }
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
        {description && <Center className="col-md-4">
            <div className="p-md-4 d-none d-md-block">
                <h4 className="font-weight-normal">
                    {description}
                </h4>
                <div className="form-box-underline"></div>
            </div>
        </Center>}
    </div>
)

export default FormBox
