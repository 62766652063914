import React, { Component } from 'react'
import { connect } from 'react-redux'


import { handleInputChange, updateOptions, clearDes } from '../../redux/actions/formactions';
import SelectIp from '../../components/Form/selectip';



export class CSelect extends Component {

    handleChange = value => {
        const { handleInputChange, formName, name } = this.props;
        handleInputChange(formName, {name, value});
        this.decendOptions(value);
    }

    decendOptions = (value) => {
        
        const { decendant, options, isMulti, decendentOptionName, formName, updateOptions } = this.props;
        if(decendant && value && decendentOptionName && options && options.length > 0){
            const getValues = val => {
                const filterValues = val =>  {
                    let filteredVals = options.filter(x => x.id === val.value );
                    if(filteredVals.length > 0){
                        return filteredVals[0][decendentOptionName]
                    } else {
                        return []
                    }
                }
                if(isMulti && Array.isArray(val)){
                    return val.reduce((acc, cur) => [...acc, ...filterValues(cur)] , [])
                }
                return filterValues(val);
            }
            updateOptions( 
                formName,
                {   
                    name: decendant, 
                    options: getValues(value)
                }
            )
        }
    }

    componentDidMount(){
        this.decendOptions(this.props.value);
    }

    componentDidUpdate(prevProps){
        const { decendant, isMulti, value, options, formName, clearDes } = this.props;
        if(decendant){
            if(!prevProps.options.length && options.length > 0 ){
                this.decendOptions(this.props.value);
            }
            if(isMulti){
                if(prevProps.value.length !== value.length){
                    clearDes(formName, decendant);
                }
            } else {
                if(!value || !prevProps.value){
                    clearDes(formName, decendant);
                } else if(prevProps.value.value !== value.value ) {
                    clearDes(formName, decendant);
                }
            }
        }
    }

    render() {
        const { decendant, formName,  decendentOptionName, ...restProps} = this.props; 
        return (
            <SelectIp onChange={this.handleChange} {...restProps} />
        )
    }
}

const mapDispatchToProps = {
    handleInputChange, updateOptions, clearDes
}

export default connect(null, mapDispatchToProps)(CSelect)