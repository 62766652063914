import React from 'react';
import { BrowserRouter as Router} from "react-router-dom";
import Routes from './Routes';
import Footer from './Header/footer';
import ConnectHeader from '../containers/common/header';
import Toast from '../containers/Toast/toast';
import Modal from '../containers/Modal/modal';

const Page = () => (
    <Router>
        <div>
            <ConnectHeader />
            <Routes />
            <Footer />
            <Toast />
            <Modal />
        </div>
    </Router>
);

export default Page;
