import React from 'react'
import TInput from './tinput';
import LabelIp from './labelip';
import formDepend from '../../hocs/formdepend';

const SignupIp = ({ size = 12, left, err, helpText, listIndex, formName, label, inputProps }) => {
    
    //compute class name
    let className = size < 12 ? `col-${size} p${left ? 'r' : 'l'}-1` : `col-12`;

    //if hidden
    if (inputProps.type === "hidden") {
        return null;
    }

    //render item
    const renderItem = <TInput 
        helpText={helpText} 
        err={err} 
        formName={formName} 
        {...inputProps} 
    />

    return (
        <div className={className.trim()}>
            {
                label ?
                    <LabelIp isOptional={inputProps.isOptional} label={label}>
                        {renderItem}
                    </LabelIp>
                    :
                    renderItem
            }
        </div>
    )
}

export default formDepend(SignupIp)
