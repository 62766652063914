import React from 'react'
import RegisterLayout from './registerlayout';
import Center from '../center';
import Photoslist, { PhotoCon } from '../../containers/Register/photoslist';

export const ManagePhotos = () => (
    <div>
        {/* <h5 className="m-0">Profile Pic</h5> */}
        <Photoslist />
    </div>
)

const PhotosUploadPage = () => (
    <RegisterLayout
        heading={
            `Upload your Photos`
        }
    >

        <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
                <div className="pl-4 pr-4">
                    <ManagePhotos />
                </div>
            </div>
            <div className="col-lg-1" />
        </div>
        <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-10">
                <Center className="mt-2 pl-4 pr-4">
                    <PhotoCon />
                </Center>
            </div>
            <div className="col-lg-1" />
        </div>
    </RegisterLayout>
)

export default PhotosUploadPage
