import React, { Component } from "react";
import { addToast } from "../../redux/actions/common";
import SubmitBtn from "../common/submitbtn";
import { updateUser } from "../../redux/actions/user";
import { emailValid } from "../../utils/Forms/validation";
import {
    changeEmail_url,
    sendVerifyEmail_url
} from "../../redux/actions/constants";
import { connect } from "react-redux";
import { helpJSX } from "../../utils/Forms/editdetails";

const mapStateToProps = ({ user }) => ({
    emailVal: user.user.email || "",
    emailVerified: user.is_email_verified,
    disabled:
        (user.disable_filed || []).filter(x => x.field_name === "email")
            .length > 0
});

const mapDispatchToProps = {
    addToast,
    updateUser
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    class Editemail extends Component {
        state = {
            email: ""
        };

        setEmail = value => {
            this.setState({ email: value });
        };

        componentDidMount() {
            this.setEmail(this.props.emailVal);
        }

        componentDidUpdate(prevProps) {
            if (this.props.emailVal !== prevProps.emailVal) {
                this.setEmail(this.props.emailVal);
            }
        }

        render() {
            const { email } = this.state;
            const {
                addToast,
                updateUser,
                emailVerified,
                emailVal,
                disabled
            } = this.props;
            return (
                <div className="form-group">
                    <div className="d-flex">
                        <input
                            style={{ maxWidth: `60%` }}
                            className="form-control mr-2"
                            placeholder="Enter your Email"
                            onChange={({ target }) =>
                                this.setEmail(target.value)
                            }
                            value={email}
                            disabled={disabled}
                        />
                        <SubmitBtn
                            className="btn custom-btn"
                            disabled={
                                disabled || (emailValid(email) ? false : true)
                            }
                            config={{
                                url: changeEmail_url,
                                method: "POST",
                                data: {
                                    email
                                }
                            }}
                            succFunc={payload => {
                                updateUser(payload);
                                addToast("Email Updated");
                            }}
                            children={"Submit"}
                        />
                    </div>
                    <div className="f-14 mb-1">
                        {emailVerified ? (
                            <span className="mr-2 f-14 color-green">
                                <i className="fas fa-check-circle f-13 mr-1"></i>
                                Email verified
                            </span>
                        ) : (
                            <span className="mr-2 f-12 text-muted">
                                <i className="fas fa-info-circle f-13 mr-1"></i>
                                Please verify email address.
                                <SubmitBtn
                                    className="btn btn-link f-12"
                                    config={{
                                        url: sendVerifyEmail_url,
                                        method: "GET"
                                    }}
                                    succFunc={() => {
                                        addToast(
                                            `Email verification link has been sent to ${emailVal}`
                                        );
                                    }}
                                    children={"Verify email"}
                                />
                            </span>
                        )}
                        <div className="mr-2 f-12 text-muted">
                            <i className="fas fa-info-circle f-13 mr-1"></i>
                            {helpJSX(disabled)}
                        </div>
                    </div>
                </div>
            );
        }
    }
);
