import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleInputChange } from '../../redux/actions/formactions';
import DateIp from '../../components/Form/datepicker';

export class CDateInput extends Component {

    handleChange = value =>{
        const { handleInputChange, formName, name } = this.props;
        handleInputChange( formName, {name, value});
    }

    render() {
        const { handleInputChange, formName, ...restProps } = this.props;
        return (
            <DateIp  
                // onChangeRaw={e => {
                //     e.preventDefault()
                // }} 
                onChange={this.handleChange} 
                {...restProps}
            />
        )
    }
}

const mapDispatchToProps = {
    handleInputChange
}

export default connect(null, mapDispatchToProps)(CDateInput)
