import React from 'react'
import { ModalCon, CloseModalWrapper, CloseModalBtn } from '../../containers/Modal/closemodal';
import Center from '../center';
import tip from "../../assets/img/tips.png";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'

//modal name PROMOTE_MODAL
const PromoteModal = ({ user, title = "", link ="/" , btnTitle = "", desc ="" }) => {
    return (
        <CloseModalWrapper className="modal-wrapper">
            <ModalCon className="phone-modal-container slideInDown">
                <div className="bg-white pt-4 pb-4 pl-3 pr-3">
                    <Center>
                        <img src={tip} alt="" />
                    </Center>
                    <div className="text-center mt-3">
                        <h5>{title}</h5>
                        <p className="f-14">Hi {`${user.user.first_name} ${user.user.last_name}`}, {desc}</p>
                        <Link to={link} className="btn btn-block btn-rounded custom-btn">
                            {btnTitle}
                        </Link>
                        <CloseModalBtn className="btn btn-block btn-rounded btn-link f-14">
                            Close
                        </CloseModalBtn>
                    </div>
                </div>
            </ModalCon>
        </CloseModalWrapper>
    )
}


const mapStateToProps = ({ user }) => ({
    user
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoteModal)
