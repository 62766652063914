import React from 'react'
import LoadDataHoc from '../../hocs/loaddatahoc';
import { LIST_PACKAGES, packages_url, RAZOR_PAY } from '../../redux/actions/constants';
import DynamicList from '../../components/dynamiclist';
import Package from '../../components/upgrade/package';
import ClearForm from '../Form/clearForm';

const Packages = ({ apiData }) => {
    return (
        <ClearForm
            clear={[RAZOR_PAY]}
        >
            <div>
                <div className="container pt-3 pb-3">
                    <div className="row">
                        <DynamicList
                            RenderItem={Package}
                            fetching={apiData.fetching}
                            data={apiData.packages}
                            error={apiData.error}
                            title="packages"
                        />
                    </div>
                </div>
                
            </div>
        </ClearForm>
    )
}

export default LoadDataHoc(Packages, LIST_PACKAGES, 'list_packages', packages_url);
