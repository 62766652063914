import { partnervalues } from "./mastervalues";
import {
    ADV_BASIC,
    ADV_EDU,
    ADV_ASTRO,
    ADV_HABITS,
} from "../../redux/actions/constants";
import { annualLimit } from "./editdetails";

const isMulti = true;
const isOptional = true;

const doesnt = { value: `Doesn't matter`, label: `Doesn't matter` };

const physically = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    doesnt
];

const doshamChoices = [
    {
        value: "Yes",
        label: "Yes"
    },
    {
        value: "No",
        label: "No"
    },
    {
        value: `Doesn't matter`,
        label: `Doesn't matter`
    }
];

const childLivingChoices = [
    {
        value: '1',
        label: "Living together",
    },
    {
        value: '0',
        label: "Not living together"
    },
    {
        value: '',
        label: "Don't wish Specify"
    }
];

//partner preferences
export const basicSearch = init => [
    {
        label: "Age",
        inputProps: {
            type: "range",
            isOptional,
            name: "age",
            unit: "yrs",
            mulitple: 1,
            maxValue: 70,
            minValue: 18,
            value: {
                min: init.age_from,
                max: init.age_to,
            }
        }
    },
    {
        label: "Height",
        inputProps: {
            type: "range",
            name: "height",
            isOptional,
            unit: "cms",
            maxValue: 240,
            minValue: 120,
            mulitple: 10,
            value: {
                min: init.height_from,
                max: init.height_to,
            }
        }
    },
    {
        label: "Income",
        inputProps: {
            type: "range",
            name: "income",
            isOptional,
            
            unit: "lakhs",
            maxValue: annualLimit,
            minValue: 0,
            mulitple: 1,
            value: {
                min: Math.floor(init.income_from / 100000),
                max:  Math.floor(init.income_to / 100000),
            }
        }
    },
    {
        label: "Physically Challenged",
        inputProps: {
            type: "radioGroup",
            isOptional,
            name: "physical_status",
            value: ["Doesn't matter"],
            options: physically
        }
    },
    {
        label: "Martial Status",
        inputProps: {
            type: "select",
            isOptional,
            name: "marital_status",
            value: init.marital_status,
            isMulti,
            placeholder: "Marital Status",
            options: partnervalues.marital_status
        }
    },
    {
        label: "Number of children",
        dependData: "number_of_children",
        inputProps: {
            type: "number",
            isOptional,
            name: "number_of_children",
            maxLength: 2,
            placeholder: "Number of children (Min Child Count)"
        }
    },
    {
        label: "Child Living",
        inputProps: {
            isOptional,
            type: "hidden",
            name: "child_living",
            value: [""],
            options: childLivingChoices
        }
    },
    {
        label: "Country",
        inputProps: {
            type: "select",
            name: "citizenship_id",
            value: init.citizenship,
            placeholder: "Country of Residence",
            options: partnervalues.country,
            decendant: "state_id",
            decendentOptionName: "states",
            isMulti,
            isOptional
        }
    },
    {
        label: "State",
        inputProps: {
            isOptional,
            type: "select",
            name: "state_id",
            value: init.state,
            placeholder: "State of Residence",
            options: [],
            decendant: "district_id",
            decendentOptionName: "district",
            isMulti
        }
    },
    {
        label: "District",
        inputProps: {
            isOptional,
            type: "select",
            name: "district_id",
            value: init.district,
            placeholder: "District of Residence",
            options: [],
            isMulti
        }
    },
    {
        label: "Religion",
        inputProps: {
            type: "select",
            isOptional,
            name: "religion",
            value: init.religion,
            placeholder: "Select Religion",
            isMulti,
            options: partnervalues.religions,
            decendant: "caste",
            decendentOptionName: "castes"
        }
    },
    {
        label: "Caste",
        inputProps: {
            type: "select",
            isOptional,
            name: "caste",
            value: init.caste,
            placeholder: "Select Caste",
            isMulti,
            options: [],
            decendant: "sub_caste",
            decendentOptionName: "sub_castes"
        }
    },
    {
        label: "Sub Caste",
        inputProps: {
            type: "select",
            isOptional,
            name: "sub_caste",
            value: init.sub_caste,
            placeholder: "Select Sub Caste",
            isMulti,
            options: []
        }
    }
];

export const eduSearch = init => [
    {
        label: "Education",
        inputProps: {
            type: "select",
            isOptional,
            name: "education",
            value: init.education,
            placeholder: "Select Education",
            isMulti,
            options: partnervalues.degrees,
            optgroup: "courses"
        }
    },
    {
        label: "Employment Type",
        inputProps: {
            type: "select",
            isOptional,
            name: "employment_in",
            value: init.employed_in,
            placeholder: "Select Employment Type",
            isMulti,
            options: partnervalues.employment
        }
    },
    {
        label: "Select Occupation",
        inputProps: {
            type: "select",
            isOptional,
            name: "occupation",
            optgroup: "occupation",
            value: init.occupation,
            placeholder: "Select Occupation",
            isMulti,
            options: partnervalues.careers
        }
    }
];

export const astroSearch = init => [
    {
        label: "Star",
        inputProps: {
            type: "select",
            isOptional,
            name: "star",
            value: init.star,
            placeholder: "Select Star",
            isMulti,
            options: partnervalues.stars,
            decendant: "raasi",
            decendentOptionName: "raasi"
        }
    },
    
    {
        label: "Dosham",
        inputProps: {
            isOptional,
            name: "dosham_choices",
            type: "radioGroup",
            value: [init.dosham_choices],
            options: doshamChoices
        }
    },
    {
        label: "Choose Dosham",
        inputProps: {
            isOptional,
            type: "hidden",
            name: "dosham_id",
            value: [],
            placeholder: "Any Dosham",
            isMulti,
            options: partnervalues.dosham
        }
    }
];

export const habitSearch = init => [
    {
        label: "Food Habits",
        inputProps: {
            type: "radioGroup",
            isOptional,
            name: "food_habits",
            value: init.food_habits,
            options: partnervalues.eating
        }
    },
    {
        label: "Smoking",
        inputProps: {
            type: "radioGroup",
            isOptional,
            name: "smoking_habits",
            value: init.smoking_habits,
            options: partnervalues.drinking
        }
    },
    {
        label: "Drinking",
        inputProps: {
            type: "radioGroup",
            isOptional,
            value: init.drinking_habits,
            options: partnervalues.drinking
        }
    }
];

export const filters = init =>[
    {
        label: "Show Profile",
        inputProps: {
            type: "radioGroup",
            value: ["0"],
            isOptional,
            name: "show_profile",
            options: [
                {
                    value: "0",
                    label: "All profiles"
                },
                {
                    value: "1",
                    label: "With photo"
                }
            ]
        }
    },
    {
        label: "Don't show Profile",
        inputProps: {
            type: "radioGroup",
            isOptional,
            name: "reject_profile",
            value: [],
            isMulti,
            options: [
                {
                    value: "3",
                    label: "Ignored"
                },
                {
                    value: "1",
                    label: "Contacted"
                },
                {
                    value: "2",
                    label: "Viewed"
                },
                {
                    value: "4",
                    label: "Shorlisted"
                }
            ]
        }
    }
];


export const advInputform = [
    {
        title: "Basic",
        inputsName: "basic_advsearch",
        formName: ADV_BASIC,
        initFunc: basicSearch
    },
    {
        title: "Education & occupation",
        fullWidth: true,
        inputsName: "edu_advsearch",
        formName: ADV_EDU,
        initFunc: eduSearch
    },
    {
        title: "Astrological",
        fullWidth: true,
        inputsName: "astro_advsearch",
        formName: ADV_ASTRO,
        initFunc: astroSearch
    },
    {
        title: "Habits",
        fullWidth: true,
        inputsName: "habit_advsearch",
        formName: ADV_HABITS,
        initFunc: habitSearch
    }
];
