import { LIST_CHAT_USERS, _FETCHING, _FULFILLED, _REJECTED, _CLEAR, _ADD_DATA, LOCAL_CHAT_USERS, _UPDATE_DATA, SEND_INTEREST } from "../actions/constants";
import { reduceResults, resultsIntial, byIdAddData, byIdUpdateData } from "./httpreducer";

//intial httpResultsReducer
const initial = {
    ...resultsIntial,
    hasLocalUser: null
};

export const chatuser = (state = initial, action) => {
    switch (action.type) {
        case `${LIST_CHAT_USERS}${_FETCHING}`:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case `${LIST_CHAT_USERS}${_FULFILLED}`:
            return {
                ...state,
                fetching: false,
                count: action.payload.count,
                ...reduceResults(state, action.payload.results),
                next: action.payload.next,
                prev: action.payload.prev,
                hasLocalUser: null
            }
        case `${LIST_CHAT_USERS}${_REJECTED}`:
            return {
                ...state,
                fetching: false,
                error: action.payload
            }
        case `${LIST_CHAT_USERS}${_ADD_DATA}`:
            return byIdAddData(state, action)
        case `${LOCAL_CHAT_USERS}${_ADD_DATA}`:
            return {
                ...state,
                data: [action.payload.id],
                byId: {
                    [action.payload.id]: action.payload 
                },
                hasLocalUser: action.payload.id !== "LOCAL_USER" ? action.payload.id : null
            }
        case SEND_INTEREST:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload
                },
                data: state.data.map(user => {
                    if (user === "LOCAL_USER") {
                        return action.payload.id
                    }
                    return user
                }),
                hasLocalUser: null
            }
        case `${LIST_CHAT_USERS}${_UPDATE_DATA}`:
            return byIdUpdateData(state, action)
        case `${LIST_CHAT_USERS}${_CLEAR}`:
            return { ...state, ...initial }
        default:
            return state
    }
}

const chatuserInit = { 
    "id": "ALL"
};

const chatusers = (state = chatuserInit, action) => {
    if (action.type === "SET_CHAT_USER_FILTER") {
        return {
            ...state,
            id: action.filter || "ALL"
        }
    }
    if (action.type === "CHATUSERS_CLEAR") {
        return chatuserInit
    }
    if (
        action.id && (action.type.indexOf(LIST_CHAT_USERS) > -1 ||
            [`${LOCAL_CHAT_USERS}${_ADD_DATA}`, SEND_INTEREST].includes(action.type))
    ) {
        return {
            ...state,
            [action.id]: chatuser(state[action.id], action)
        }
    }
    return state
}

export default chatusers