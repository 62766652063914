import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import ChatFooter from "./chatfooter"
import SendInterest from './sendinterest';
import ScrollPaging from '../common/scrollpaging';
import { LIST_CHAT_ROOM_MESSAGES, chats_url } from '../../redux/actions/constants';
import Acceptchat from './acceptchat';
import Chatmsg from './chatmsg';
import { clearActiveRoom } from "../../redux/actions/chat"

const getUsers = (chatroom, chatusers) => {
    let roomId = chatroom.id;
    let filter = chatusers.id;
    if (roomId && filter && chatusers[filter]) {
        return chatusers[filter].byId[roomId]
    }
    return null
}

class ChatRoom extends PureComponent {

    render() {
        const { userActive, user, clearActiveRoom } = this.props;
        if (userActive) {
            const { id, actor, status, requested_me, is_paid } = userActive;
            const isOnePaid = is_paid || (user.packages ? user.packages.points > 0 : false);
            return (
                <React.Fragment>
                    <div className="chat-app-left-header chat-app-header-height">
                        {
                            id && actor &&
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <button onClick={() => clearActiveRoom()} className="btn btn-chat-back">
                                        <i className="fas fa-chevron-left"></i>
                                    </button>
                                    {actor.profile_pic && <img src={actor.profile_pic} alt="" className="rounded-circle chat-user-top-img" />}
                                    <div>
                                        <Link to={`/profile/${actor.id}`} target="_blank">
                                            {actor.full_name && <h6 className="m-0">{actor.full_name}</h6>}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="chatuser-msg-container chatscroll">
                        {
                            id === "LOCAL_USER" && status === "NEW_ROOM" && actor ?
                                <SendInterest actor={actor} /> :
                                id && id !== "LOCAL_USER" ?
                                    <ScrollPaging
                                        url={chats_url + `?room=${id}`}
                                        actionName={LIST_CHAT_ROOM_MESSAGES}
                                        reducerName={"chatroom"}
                                        RenderItem={Chatmsg}
                                        reducerId={id}
                                    /> : null
                        }
                    </div>
                    {
                        id && status !== "NEW_ROOM" &&
                        <div className="container-fluid chat-footer">
                            {
                                status ?
                                    !isOnePaid ?
                                        <div className="text-center f-16">
                                            To chat with <span className="text-capitalize">{actor.full_name} </span>
                                            <Link className="btn custom-btn f-15 ml-3" to="/upgrade">
                                                Upgrade to paid membership
                                            </Link>
                                        </div> :
                                        <ChatFooter
                                            roomId={id}
                                            actor={actor}
                                            me={user}
                                        /> :
                                    <Acceptchat
                                        status={status}
                                        id={id}
                                        requested_me={requested_me}
                                    />
                            }
                        </div>
                    }
                </React.Fragment>
            )
        }
        return null;
    }
}

const mapStateToProps = ({ chatusers, chatroom, user }) => ({
    userActive: getUsers(chatroom, chatusers),
    user
})

const mapDispatchToProps = {
    clearActiveRoom
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom)
