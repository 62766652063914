import {
    LIST_CHAT_USERS,
    _ADD_DATA,
    LOCAL_CHAT_USERS,
    _UPDATE_DATA,
    CREATE_ROOM,
    LIST_CHAT_ROOM_MESSAGES,
    _CLEAR
} from "./constants";

//add local chatuser
export const addLocalChatUser = payload => {
    return {
        type: `${LOCAL_CHAT_USERS}${_ADD_DATA}`,
        payload,
        id: "ALL"
    };
};

//update chatuser
export const updateChatUser = (id, data, filter = "ALL") => {
    return {
        type: `${LIST_CHAT_USERS}${_UPDATE_DATA}`,
        payload: { id, data },
        id: filter
    };
};

//create room
export const createRoom = id => {
    return {
        type: `${CREATE_ROOM}`,
        room: id
    };
};

//create room
export const clearActiveRoom = id => {
    return {
        type: "CHATROOMS" + _CLEAR
    };
};

//add chat message
export const addChatMsg = (roomId, payload) => {
    return {
        type: `${LIST_CHAT_ROOM_MESSAGES}${_ADD_DATA}`,
        payload,
        id: roomId
    };
};

//sent
export const chatMsgSent = (roomId, id) => {
    return {
        type: `${LIST_CHAT_ROOM_MESSAGES}${_UPDATE_DATA}`,
        payload: { id, is_sent: true },
        id: roomId
    };
};

//send socket msg
export const sendSocketMsg = (emit, payload) => ({
    type: "SEND_WEBSOCKET_MESSAGE",
    emit,
    payload
});
