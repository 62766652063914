import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleInputChange } from '../../redux/actions/formactions';

import Select from 'react-select';



function createRange(start, end, multiple = 1) {
    let arr = [];
    for(let i = start; i < end;) {
        arr.push(i);
        i = i + multiple;
    }
    arr.push(end);
    return arr;
}

const createOptions = (min, max, mulitple, unit="") => {
    const range = createRange(min, max, mulitple);
    const result =  range.map(x => ({
        label: getLabel(x, unit),
        value: x
    }))
    result.push({label: `${max+1}+ ${unit} More`, value: max+1})
    return result;
}

const getLabel = (val, unit) => val + " " + unit;

class CRangeSelect extends Component {

    constructor(props) {
        super(props);
        const { minValue, maxValue, mulitple, unit } = props;
        this.options = createOptions(minValue, maxValue, mulitple, unit);
    }

    handleChange = value =>{
        const { handleInputChange, formName, name} = this.props;
        handleInputChange( formName, {name, value});
    }

    handleMin = min => {
        const { value, mulitple } = this.props;
        let max = value.max;
        if(min >= max) {
            max = min + mulitple;
        }
        this.handleChange({ min, max });
    }

    handleMax = max => {
        const { value, mulitple } = this.props;
        let min = value.min;
        if(max <= min) {
            min = max - mulitple;
        }
        this.handleChange({ min, max });
    }

    render() {
        const  { name, value, unit } = this.props;
        return (
            <div className="row align-item-center">
                <div className="col-5 pr-0">
                    <Select 
                        placeholder="From"
                        onChange={(val) => {
                            this.handleMin(val.value);
                        }}
                        value={{ label: getLabel(value.min, unit), value: value.min  }}
                        options={this.options.slice(0, this.options.length - 1)}
                        classNamePrefix="react-select" 
                    />
                </div>
                <div className="col-2 text-center p-0">
                    <div className="mt-1">to</div>
                </div>
                <div className="col-5 pl-0">
                    <Select 
                        placeholder="To"
                        onChange={(value) => {
                            this.handleMax(value.value);
                        }}
                        value={{ label: getLabel(value.max, unit), value: value.max  }}
                        options={this.options.slice(1, this.options.length)}
                        classNamePrefix="react-select" 
                    />
                </div>
                <input name={name} value={value} type="hidden" />
            </div>
        )
    }
}

const mapDispatchToProps = {
    handleInputChange
}

const RangeSelect = connect(null, mapDispatchToProps)(CRangeSelect)

export default RangeSelect
