import { Component } from 'react'
import { connect } from 'react-redux'
import { clearForm } from '../../redux/actions/formactions';
import { addToast } from '../../redux/actions/common';

class ClearForm extends Component {

    clear = () => {
        const {clear, clearForm} = this.props;
        clear.forEach(cl => 
            clearForm(cl)    
        )
    }

    componentDidUpdate(prevProps){
        const { unMount, addToast, toastMsg } = this.props;
        if(unMount && unMount !== prevProps.unMount){
            if(toastMsg){
                addToast(toastMsg);
            }
            this.clear();
        }
    }

    render() {
        return this.props.children;
    }
    
    componentWillUnmount(){
        this.clear();
    }
}

const mapDispatchToProps = {
    clearForm, addToast
}

export default connect(null, mapDispatchToProps)(ClearForm)
