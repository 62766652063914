import React from 'react'
import { CloseModalWrapper } from '../../containers/Modal/closemodal';
import NavLinks from '../Header/navlinks';
import SideModalCon from '../../containers/Modal/sidemodalcon';
import Logo from '../Header/logo';
import { connect } from 'react-redux'
import { LogoutBtn } from '../Manage/managetabs';

//modal name "SIDE_MODAL"
const mapStateToProps = ({user}) => ({
    user
})
const SideModal = connect(mapStateToProps)(({ user }) => (
    <CloseModalWrapper className="side-modal-wrapper">
        <SideModalCon className="side-modal-container">
            <div className="side-modal-body">
                <div className="side-account border-bottom">
                    <Logo isLoggedIn={user.token ? true : false}  />
                </div>
                <div className="pt-2">
                    <NavLinks 
                        showUpgrade 
                        isLoggedIn={user.token ? true : false}  
                        isSide 
                        notiCount={user.notification || 0}
                    />
                    <LogoutBtn 
                        className="align-items-center bg-white btn btn-block d-flex nav-link relative"
                    />
                </div>
            </div>
        </SideModalCon>
    </CloseModalWrapper>
))

export default SideModal