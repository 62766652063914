import React from 'react';
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import dateFormat from '../../utils/dateformat'

const MobileProfile = ({ user }) => (
    <div className='row'>
        <div className="col-12 d-flex flex-row-reverse">

            <Link className="theme-color" to="/upgrade">Edit Profile</Link>
        </div>
        <div className="col-4">
            <img alt="user profile" src={user.profile_pic} style={{ width: "100%", height: "90px", objectFit: "cover", borderRadius: "10px" }}></img>
        </div>
        <div className="col-8 pl-0">

            <div>
                <div className="lft-profile-name">{user.user.first_name}</div>
            </div>
            <div className="theme-support-color f-12 f-600">{user.uid} | {user.age} yrs</div>
            <div className="">
                {user.packages && user.packages.points ?
                    <div className="d-flex mt-2">
                        <div className="col-6 text-center">
                            <h6 className=" f-600 mb-0">{user.packages.points}</h6>
                            <p className="mb-0 f-12 theme-support-color">Points</p>
                        </div>
                        <div className="col-6 text-center">
                            <h6 className=" f-600 mb-0">{dateFormat(user.packages.expiration_on)}</h6>
                            <p className="mb-0 f-12 theme-support-color">Expiration On</p>
                        </div>
                    </div> :
                    <div>
                        <Link className="btn btn-primary btn-sm" to="/upgrade">Upgrade</Link>
                    </div>
                }
            </div>
        </div>
    </div>
);

const mapStateToProps = ({ user }) => ({
    user
})

export default connect(mapStateToProps)(MobileProfile)

