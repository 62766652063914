import React from 'react'
import { Noti } from '../../components/Notifications/notificationspage';
import listhoc from '../../hocs/listhoc';
import { LIST_NOTI, notifications_url } from '../../redux/actions/constants';

const ListNoti = props => {
    return (
        <Noti {...props} />
    )
}

export default listhoc(ListNoti, LIST_NOTI, 'list_noti', notifications_url);
