import React from 'react';
import ContentLoader from "react-content-loader";

const MatchesLoaders = () => {
    return (
        <div>
            <ContentLoader
                speed={2}
                width={840}
                height={450}
                viewBox="0 0 840 450"
                backgroundcolor="#f3f3f3"
                foregroundcolor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="800" height="5" />
                <rect x="0" y="0" rx="0" ry="0" width="5" height="450" />

                <rect x="25" y="30" rx="0" ry="0" width="4" height="360" />
                <rect x="310" y="30" rx="0" ry="0" width="4" height="360" />

                <rect x="44" y="40" rx="10" ry="10" width="250" height="180" />
                <rect x="44" y="240" rx="5" ry="5" width="250" height="8" />
                <rect x="44" y="270" rx="5" ry="5" width="250" height="8" />
                <rect x="44" y="300" rx="5" ry="5" width="250" height="8" />

                <circle cx="64" cy="350" r="20" />
                <circle cx="164" cy="350" r="20" />
                <circle cx="270" cy="350" r="20" />

                <rect x="25" y="30" rx="0" ry="0" width="288" height="4" />
                <rect x="25" y="390" rx="0" ry="0" width="288" height="4" />



                <rect x="425" y="30" rx="0" ry="0" width="4" height="360" />
                <rect x="710" y="30" rx="0" ry="0" width="4" height="360" />

                <rect x="444" y="40" rx="10" ry="10" width="250" height="180" />
                <rect x="444" y="240" rx="5" ry="5" width="250" height="8" />
                <rect x="444" y="270" rx="5" ry="5" width="250" height="8" />
                <rect x="444" y="300" rx="5" ry="5" width="250" height="8" />

                <circle cx="464" cy="350" r="20" />
                <circle cx="564" cy="350" r="20" />
                <circle cx="670" cy="350" r="20" />

                <rect x="425" y="30" rx="0" ry="0" width="288" height="4" />
                <rect x="425" y="390" rx="0" ry="0" width="288" height="4" />

                <rect x="0" y="445" rx="5" ry="5" width="800" height="5" />
                <rect x="800" y="0" rx="0" ry="0" width="5" height="450" />

            </ContentLoader>

        </div>
    );
};

export default MatchesLoaders;