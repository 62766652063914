import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormBox from '../../components/Register/formbox';


export class Formbox extends Component {
    render() {
        return (
            <FormBox {...this.props} />
        )
    }
}

const mapStateToProps = (state, props) => ({
    inputs: state[props.inputsName]
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Formbox)
