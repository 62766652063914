import React, { Component } from 'react';
import Lightbox from 'react-images';

export const reduceImages = images => images.reduce((acc, cur) => [...acc, { src: cur.image }], []);

class Light extends Component {

	constructor () {
		super();

		this.state = {
			lightboxIsOpen: false,
			currentImage: 0,
		};
		this.closeLightbox = this.closeLightbox.bind(this);
		this.gotoNext = this.gotoNext.bind(this);
		this.gotoPrevious = this.gotoPrevious.bind(this);
		this.gotoImage = this.gotoImage.bind(this);
		this.handleClickImage = this.handleClickImage.bind(this);
		this.openLightbox = this.openLightbox.bind(this);
    }
    
	openLightbox (index, event) {
		event.preventDefault();
		this.setState({
			currentImage: index,
			lightboxIsOpen: true,
		});
    }
    
	closeLightbox () {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false,
		});
    }
    
	gotoPrevious () {
		this.setState({
			currentImage: this.state.currentImage - 1,
		});
    }
    
	gotoNext () {
		this.setState({
			currentImage: this.state.currentImage + 1,
		});
    }
    
	gotoImage (index) {
		this.setState({
			currentImage: index,
		});
    }
    
	handleClickImage () {
		if (this.state.currentImage === this.props.images.length - 1) return;
		this.gotoNext();
	}
	
	render () {
        const  { Gallery, images } = this.props;
		return (
			<React.Fragment>
				<Gallery 
                    openLightbox={this.openLightbox}
                />
                <Lightbox
                    images={images}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.lightboxIsOpen}
                    onClickImage={this.handleClickImage}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    onClickThumbnail={this.gotoImage}
                    onClose={this.closeLightbox}
                    preventScroll={true}
                    showThumbnails={true}
                    enableKeyboardInput={true}
                />
			</React.Fragment>
		);
	}
}

export default Light;