import React from 'react';
import List from '../../components/list';
import { NavLink } from "react-router-dom"

export const ActivityLinks = [
    {
        links: [
            {
                to: "/activity",
                title: "Viewed by Me",
            },
            {
                to: "/activity/shortlisted-by-me",
                title: "Shortlisted by Me",
            },
            {
                to: "/activity/me-viewed-number",
                title: "Me Viewed Number",
            },
            {
                to: "/activity/who-viewed-you",
                title: "Who Viewed You",
            },
            {
                to: "/activity/who-shortlisted-you",
                title: "Who Shortlisted You",
            },
            {
                to: "/activity/who-viewed-your-number",
                title: "Who Viewed Your Number",
            },
        ]
    }

]

const ActivityNav = ({ links }) => {
    return (
        <div className="">
            <List
                RenderItem={ManageBox}
                list={links}
                title="manage-box"
            />

        </div>
    );
};


export const ManageBox = ({ links }) => (
    <ul className="list-inline p-2">
        <List
            RenderItem={ManageTabLink}
            list={links}
            title="manage-links"
        />
    </ul>
)

export const ManageTabLink = ({ to, title, external }) => (
    <li className="list-inline-item nav-item pl-2 pb-2">
        {external ? <a href={to} target="_blank" rel="noopener noreferrer">{title}</a> :
            <NavLink className="btn btn-matches-normal" exact activeClassName="btn-matches-active" to={to}>
                {title}
            </NavLink>}
    </li>
)


export default ActivityNav;