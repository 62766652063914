import { 
    LIST_CHAT_ROOM_MESSAGES,  _CLEAR, CREATE_ROOM, SEND_INTEREST
} 
from "../actions/constants";
import { httpResultsReducer } from "./httpreducer";

const createChatroom = httpResultsReducer(LIST_CHAT_ROOM_MESSAGES);

const chatroom = ( 
    state = { 
        id: null
    }, 
    action ) => {
    if(action.type === CREATE_ROOM) {
        return {
            ...state,
            id: action.room
        }
    }
    if(action.type === SEND_INTEREST) {
        return {
            ...state,
            id: state.id === 'LOCAL_USER' ? action.payload.id : state.id
        }
    }
    if(action.type === "CHATROOMS" + _CLEAR) {
        return {}
    }
    if(action.id === state.id && action.type.indexOf(LIST_CHAT_ROOM_MESSAGES) > -1){
        return {
            ...state,
            [action.id]: createChatroom(state[action.id], action)
        }
    }
    return state
}

export default chatroom