import React from 'react'
import { OpenModalBtn } from '../../containers/Modal/closemodal';

const Hambtn = () => {
    return (
        <OpenModalBtn modalName="SIDE_MODAL" className="btn ham-btn">
            <span className="navbar-toggler-icon"></span>
        </OpenModalBtn>
    )
}

export default Hambtn
