import React, { Component } from 'react'
import { connect } from 'react-redux'
import Center from '../../components/center';
import Formbox from '../Register/formbox';
import LoadingBtn from '../../components/Buttons/loadingbtn';
import { setFormErr} from '../../redux/actions/formactions';
import { validate, reduceForm } from '../../utils/Forms/validation';
import { CHANGE_PASSWORD, CHANGE_PWD } from '../../redux/actions/constants';
import { changePwd } from '../../redux/actions/user';
import FormCon from '../Form/formcon';


export class ChangePwd extends Component {

    handleSubmit = e => {
        e.preventDefault();
        const {changePwd, setFormErr, change_pwd} = this.props;
        const setErr = payload => setFormErr(CHANGE_PASSWORD, payload);
        const isErr = validate(change_pwd, setErr);
        if(isErr){
            return;
        }
        const {new_password, old_password, retype_password} = reduceForm(change_pwd);
        if(new_password !== retype_password){
            setFormErr(CHANGE_PASSWORD, {err: "Incorrect password", name: "new_password"});
            return;
        }
        changePwd({new_password, old_password});
    }

    render() {
        const {data, fetching} = this.props.change_pwd_form;
        return (
            <FormCon 
                unMount={data.id} 
                toastMsg="Password changed successfully"
                clear={[CHANGE_PASSWORD, CHANGE_PWD]} 
                onSubmit={this.handleSubmit}
            >
                <Formbox
                    title="Change password"
                    formName={CHANGE_PASSWORD}
                    inputsName="change_pwd"
                />
                <div className="row">
                    <div className="col-md-12">
                        <Center>
                            <LoadingBtn 
                                className="btn custom-btn registerbtn"
                                fetching={fetching}
                                disabled={fetching}
                                onSubmit={this.handleSubmit}
                            >
                                Submit
                            </LoadingBtn>
                        </Center>
                    </div>
                </div>
            </FormCon>
        )
    }
}

const mapStateToProps = ({change_pwd, change_pwd_form}) => ({
    change_pwd, change_pwd_form
})

const mapDispatchToProps = {
    setFormErr, changePwd
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePwd)