import React from 'react'

const FileInput = props => (
    <label htmlFor={props.name} className="row align-items-center">
        <div className="col-8">
            <input placeholder={props.placeholder} className="form-control" type="text" readOnly />
        </div>
        <div className="col-4">
            <button className="btn file-upload-btn">
                <input type="file" style={{display: `none`}} {...props} />
                {`Choose file${props.multiple ? `s` : ``}`}
            </button>
        </div>
    </label>
)

export default FileInput
