import React from 'react'
import { connect } from 'react-redux'
import { createRoom } from '../../redux/actions/chat';

const CreateRoom = ({room, createRoom,  chatroom, children}) => {
    const is_active = room === chatroom.id;
    return (
        <button 
            disabled={is_active}
            onClick={() => createRoom(room)}
            className={`chatuser${is_active ? " active" :  ""}`}
        >
            {children}
        </button>
    )
}

const mapStateToProps = ({ chatroom }) => ({
    chatroom
})

const mapDispatchToProps = {
    createRoom
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRoom)
