import React, { Component } from 'react'
import { connect } from 'react-redux'
import { openModal } from '../../redux/actions/common';

// props reducerName, ntAction, formName
export class ConfirmBtn extends Component {

    handleClick = () => {
        const { dispatch, ntAction } = this.props;
        dispatch(ntAction());
    }

    componentDidUpdate(prevProps){
        const { dispatch, formName, formData, reducerName } = this.props;
        if(formData.fetching && !prevProps.fetching){
            dispatch(openModal("PAGE_LOADER", {
                reducerName, formName
            }))
        }
    }

    render() {
        return (
            <button onClick={this.handleClick} className="btn custom-btn">
                Confirm
            </button>
        )
    }
}

const mapStateToProps = (state, props) => ({
    formData: state[props.reducerName]
})

export default connect(mapStateToProps)(ConfirmBtn)
