import React from 'react'
import renderInput from './renderinput';

const TInput = ({ type, err, helpText = null, isOptional, ...ipProps }) => {
    if (type === "hidden") {
        return null;
    } else {
        const RenderInput = renderInput(type);
        return (
            <div className={`form-group${err ? ' form-control-err' : ''}`}>
                <RenderInput className={type === 'select' ? `` : `form-control`} {...ipProps} type={type} />
                {(err || helpText) &&<p>
                    {
                        err ?
                            <span className="text-danger"><i className="fas fa-times-circle"></i> {err}</span> :
                            null
                    }
                </p>}
            </div>
        )
    }
}

export default TInput
