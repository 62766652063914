import React, { Component } from 'react'
import { connect } from 'react-redux'
import Chatuser from '../../components/Chat/chatuser';
import ScrollPaging from '../common/scrollpaging';
import { LIST_CHAT_USERS, chats_url } from '../../redux/actions/constants';
import { addLocalChatUser, createRoom } from '../../redux/actions/chat';

class ListChatusers extends Component {

    state = {
        filter: null
    };

    componentDidMount() {
        const { chatusers } = this.props;
        const localUser = chatusers.id && chatusers[chatusers.id] ? chatusers[chatusers.id].hasLocalUser : null;
        const filter = localUser ? `active_room=${localUser}` : "";
        this.setState({ filter });
    }

    render() {
        const { chatusers } = this.props;
        const { filter } = this.state;
        if (chatusers.id && filter !== null) {
            const params = chatusers.id === "RECIEVED" ?
                "received=1" : chatusers.id === "SENT" ? "sent=1" : "";
            const query = `${filter ? `${filter}${params ? "&" : ""}` : ""}${params}`;
            return (
                <div className="chatuser-list">
                    <ScrollPaging
                        url={chats_url + (query ? `?${query}` : '')}
                        actionName={LIST_CHAT_USERS}
                        reducerName={"chatusers"}
                        RenderItem={Chatuser}
                        reducerId={this.props.chatusers.id}
                    />
                </div>
            )
        }
        return null
    }

}

const mapStateToProps = ({ chatusers, chatroom, user }) => ({
    chatusers, chatroom, user
})

const mapDispatchToProps = {
    createRoom, addLocalChatUser
}

export default connect(mapStateToProps, mapDispatchToProps)(ListChatusers)