import React from 'react'
import LoadDataHoc from '../../hocs/loaddatahoc';
import ProfilePersonalInfo from '../../components/Profile/profilePersonalInfo'
import { MATCH_DETAIL } from '../../redux/actions/constants';
import ProfileDetails from '../../components/Profile/profiledetails';
import TrustBadges from '../../components/Profile/trustBadges';
import ProfilePartnerPrefers from '../../components/Profile/profilePartnerPrefers';
import { DetailLoader } from '../../components/Profile/detailloader';
import { SimilarMatch } from '../Dashboard/matches';
import profileSvg from "../../assets/img/profile.svg"

export const Profile = ({ apiData }) => {
    if (apiData.fetching) {
        return <DetailLoader />
    }
    if (apiData.error) {
        return (
            <div className="text-center pt-5 pb-5">
                <img className="mb-4" style={{ width: `100%`, maxWidth: `450px` }} src={profileSvg} alt="" />
                <h5 className="mb-5 logo-red-color">PROFILE IS CURRENTLY NOT AVAILABLE</h5>
            </div>
        )
    }
    if (apiData.data.id) {
        const { id, gender, partner_preferences } = apiData.data;
        const gender_value = gender === "Female" ? "Her" : "Him"
        return (
            
            <div className="container custom-container profile-page pt-3 pb-3">
                <ProfilePersonalInfo gender_value={gender_value} userData={apiData.data} />
                <div className="row">
                    <div className="col-lg-9">
                        <ProfileDetails gender_value={gender_value} userData={apiData.data} />
                        <TrustBadges gender_value={gender_value} />
                        <ProfilePartnerPrefers {...partner_preferences} />
                    </div>
                    <div className="col-lg-3">
                        <div className="bx-shdw p-4">


                            <h6 className="profile-box-head">Similar Profiles</h6>
                            <SimilarMatch
                                url={`users/profile/${id}/similar_profile`}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default LoadDataHoc(Profile, MATCH_DETAIL, 'matchDetail');
