import React from 'react'
import CardProfile from '../../components/Dashboard/cardProfile';
import listhocpaginations from '../../hocs/listhocpaginations';
import { matches_url, YET_TO_BE_VIEWED, NEW_MATCHES, MY_COMMUNITY } from '../../redux/actions/constants';
import MatchesLoaders from '../../components/Loaders/loaders'

const DashBoardMatch = props => (
    <div className={`col-md-2 col-6 pl-2 pr-2`}>
        <CardProfile {...props} />
    </div>
)


const withldr = (a, b, c, d) => listhocpaginations(a, b, c, d, MatchesLoaders);


const YetTobeViewed = withldr(DashBoardMatch, YET_TO_BE_VIEWED, 'yet_to_be_viewed', matches_url + `?type=${1}`);
const NewMatches = withldr(DashBoardMatch, NEW_MATCHES, 'new_matches', matches_url + `?type=${2}`);
const MyCommunity = withldr(DashBoardMatch, MY_COMMUNITY, 'my_community', matches_url + `?type=${3}`);
const NoCaste = withldr(DashBoardMatch, MY_COMMUNITY, 'my_community', matches_url + `?type=${4}`);




export const Matchestabs = [
    {
        path: "/",
        exact: true,
        component: YetTobeViewed
    },
    {
        path: "/recently-joined",
        component: NewMatches
    },
    {
        path: "/my-community",
        component: MyCommunity
    },
    {
        path:"/no-caste",
        component: NoCaste
    }

]; 