import React from 'react'
import {Link} from 'react-router-dom'

const CustomBtn = ({children, to , className="", ...restProps}) => (
    to ?
    <Link to={to} className={`btn custom-btn ${className}`.trim()} {...restProps}>
        {children}
    </Link> : 
    <button className={`btn custom-btn ${className}`.trim()} {...restProps}>
        {children}
    </button>
)

export default CustomBtn
