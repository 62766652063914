import {
    QUICK_SEARCH_FORM
} from "../../redux/actions/constants";
import {
    partnervalues
} from "./mastervalues";

const isMulti = true;
const isOptional = true;


//partner preferences
export const quickSearch = init => [


    {
        label: "Age",
        inputProps: {
            type: "range",
            name: "age",
            unit: "yrs",
            mulitple: 1,
            isOptional,
            maxValue: 70,
            minValue: 18,
            value: {
                min: init.age_from,
                max: init.age_to,
            }
        }
    },
    {
        label: "Mother Toungue",
        inputProps: {
            type: "select",
            isOptional,
            name: "mother_tongue",
            value: init.mother_tongue,
            placeholder: "Select Languages",
            isMulti,
            options: partnervalues.languages
        }
    },
    {
        label: "Martial Status",
        inputProps: {
            type: "select",
            isOptional,
            name: "marital_status",
            value: init.marital_status,
            isMulti,
            placeholder: "Marital Status",
            options: partnervalues.marital_status
        }
    },
    {
        label: "Religion",
        inputProps: {
            type: "select",
            isOptional,
            name: "religion",
            value: init.religion,
            placeholder: "Select Religion",
            isMulti,
            options: partnervalues.religions,
            decendant: "caste",
            decendentOptionName: "castes"
        }
    },
    {
        label: "Caste",
        inputProps: {
            type: "select",
            isOptional,
            name: "caste",
            value: init.caste,
            placeholder: "Select caste",
            isMulti,
            options: []
        }
    },
    {
        label: "Country",
        inputProps: {
            type: "select",
            isOptional,
            name: "citizenship",
            placeholder: "Select Country",
            isMulti,
            value: init.citizenship,
            options: partnervalues.country,
        }
    },
];



export const quickSearchInputform = [{
        title: "QUICK SEARCH",
        inputsName: 'quick_search_form',
        formName: QUICK_SEARCH_FORM,
        initFunc: quickSearch
    },

];