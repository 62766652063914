import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getPager } from '../../utils/common'
import listAction from "../../redux/actions/listaction"

class Pagination extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.lazyImageObserver = null;
        this.state = {
            currentPage: 1,
            pageNumbers: 1,
            indexOfLastPage: 1,
            indexOfFirstPage: 1,
        }
    }

    handleClick(id) {
        const { api, actionName, listAction, query_string, is_offset, page_max_size } = this.props
        query_string.set("page", id)
        this.setState({ currentPage: id })
        this.props.history.push({
            search: query_string.toString()
          });

        if(is_offset){
            query_string.set("offset", (query_string.get("page") - 1) * page_max_size)

        }
        
        let update_data = api
        if (api.includes("?")){
            update_data = update_data + `&${query_string.toString()}`
        }else{
             update_data = update_data + `?${query_string.toString()}`
        }
        
        listAction("LOAD", actionName + "_UPDATE_DATA", update_data)
    }



    render() {
        const { count, page_size, current_page, classname } = this.props
        console.log(count)

        const currentPage = current_page ? parseInt(current_page, 0) : this.state.currentPage
        const { pages, totalPages } = getPager(count, currentPage, page_size)


        const renderPageNumbers = pages.map(number => {
            return (
                <li
                    key={number}
                    className={`page-item ${currentPage === number ? "active" : ""}`}
                    id={number}
                    onClick={this.handleClick.bind(this, number)}
                >
                    <span className="page-link">{number}</span>
                </li>
            );
        });

        return (
            <div className="mt-4">
                <ul className={`pagination ${classname ? classname : "justify-content-center"}`}>
                    <li className={`page-item ${currentPage === 1 && "disabled"}`}><span className="page-link" onClick={this.handleClick.bind(this, currentPage - 1)}>
                        <i className="fas fa-angle-double-left"></i></span>
                    </li>
                    {renderPageNumbers}
                    <li className={`page-item ${currentPage >= totalPages && "disabled"}`}><span className="page-link" onClick={this.handleClick.bind(this, currentPage + 1)}>
                        <i className="fas fa-angle-double-right"></i></span>
                    </li>
                </ul>
            </div>);
    }

}



const mapDispatchToProps = {
    listAction
}

export default connect(null, mapDispatchToProps)(Pagination)