import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MatchFunc from "../../containers/Dashboard/matchfunc";
import ContentLoader from "react-content-loader";

const Matches = memo(props => {
    const {
        fetching,
        profile_pic,
        user,
        id,
        religion_details,
        district,
        uid,
        user_details,
        age,
        is_notUser=true,
        is_premium
    } = props;

    const [width, setWidth] = useState(window.innerWidth)
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    let isMobile = (width <= 750);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])


    if (fetching) {
        return <MatchLoader />;
    }

    return (
        <div className="relative">
            {is_premium && (
                <div className="premium-label">
                    <i className="fas fa-crown"></i>Premium
                </div>
            )}
            <div className="bx-shdw p-0">
                <MatchLink
                    isMobile={isMobile}
                    isUserActive={user.is_active}
                    className=""
                    to={`/profile/${id}`}
                >

                    <div className="bx-shdw-img">
                        <img src={profile_pic} alt={user.first_name}></img>

                    </div>
                    <div className="bx-shdw-content">
                        <div className="">
                            <div className="bx-shdw-name">{user.first_name}</div>
                            <div className="bx-shdw-id">{uid}</div>
                        </div>

                        <div className="bx-shdw-desc">
                            <span> {age} yrs, </span>
                            <span>{user_details.height.short_name}</span>
                            {religion_details &&
                                religion_details.religion && religion_details.caste &&
                                <div className='bx-shdw-caste'>{religion_details.caste.name},</div>
                            }
                            {
                                district ?
                                    <div className='bx-shdw-caste'>{district.name}</div> : ""
                            }
                        </div>

                        <div className="a-1 pt-2 text-center">View Profile</div>


                    </div>


                </MatchLink>
                {!is_notUser && <MatchFunc {...props} />}
            </div>
        </div >
    );
});


export const MatchLink = ({
    isUserActive,
    children,
    isMobile,
    to,
    ...restProps
}) => {

    if (to && isUserActive !== false) {
        return (
            <Link {...restProps} to={to} target={isMobile ? "" : "_blank"}>
                {children}
            </Link>
        );
    }
    return <div {...restProps}>{children}</div>;
};


export const MatchLoader = ({ size = 6 }) => (
    <div className="match">
        <ContentLoader
            speed={2}
            width={200}
            height={250}
            viewBox="0 0 200 250"
        >
            <rect x="1" y="1" rx="15" ry="15" width="190" height="150" />
            <rect x="5" y="160" rx="5" ry="5" width="180" height="10" />
            <rect x="5" y="180" rx="5" ry="5" width="180" height="10" />
            <circle cx="20" cy="220" r="15" />
            <circle cx="90" cy="220" r="15" />
            <circle cx="180" cy="220" r="15" />
        </ContentLoader>
    </div>
);

export default Matches;
