import React from 'react'

const ImageInput = ({placeholder, name, ...restProps}) => {
    return (
        <div className="border-bottom pb-2">
            {placeholder &&<h5 className="pb-2 font-weight-normal">{placeholder}</h5>}
            <label className="file-sqaure mb-2">
                <input name={name} type="file" style={{display: `none`}} {...restProps} />
                <div className="text-center">
                    <p className="m-0"><i className="fas fa-plus"></i></p>
                </div>
            </label>
        </div>
    )
}

export default ImageInput
