import {
    _FETCHING, _FULFILLED, _REJECTED, _CLEAR, _NEXT, SHORT_LIST_MATCH, _UPDATE_DATA,
    REJECT_MATCH, ACCEPT_DENY
} from "../actions/constants";

//intial match data
const matchesIntial = {
    fetching: false,
    data: [],
    error: false,
    count: 0,
    next: null,
    prev: null,
    nextFetching: false,
    nextError: false,
    page_max_size: 12
};

const updateData = (data, payload) => data.map(x => {
    const { id, ...rest } = payload;
    if (x.actor) {
        if (x.actor.id === payload.id) {
            return {
                ...x,
                actor: {
                    ...x.actor,
                    ...rest
                }
            }
        }
    } else {
        if (x.id === payload.id) {
            return { ...x, ...rest }
        }
    }
    return x;
})

const creatematches = name => (state = matchesIntial, action) => {
    switch (action.type) {
        case `${name}${_FETCHING}`:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case `${name}${_FULFILLED}`:
            return {
                ...state,
                fetching: false,
                count: action.payload.count,
                page_max_size: action.payload.page_max_size,
                data: action.payload.results,
                next: action.payload.next,
                prev: action.payload.next,
            }
        case `${name}${_REJECTED}`:
            return {
                ...state,
                fetching: false,
                error: action.payload
            }
        case `${name}${_NEXT}${_FETCHING}`:
            return {
                ...state,
                nextFetching: true,
                nextError: false
            }
        case `${name}${_NEXT}${_FULFILLED}`:
            return {
                ...state,
                nextFetching: false,
                next: action.payload.next,
                data: [...state.data, ...action.payload.results]
            }
        case `${name}${_NEXT}${_REJECTED}`:
            return {
                ...state,
                nextFetching: false,
                nextError: true
            }

        case `${name}${_UPDATE_DATA}${_FETCHING}`:
            return {
                ...state,
                data: [],
                fetching: true,
                error: false
            }
        case `${name}${_UPDATE_DATA}${_FULFILLED}`:
            return {
                ...state,
                fetching: false,
                next: action.payload.next,
                data: [...action.payload.results]
            }
        case `${name}${_UPDATE_DATA}${_REJECTED}`:
            return {
                ...state,
                nextFetching: false,
                nextError: true
            }
        case `${SHORT_LIST_MATCH}${_FULFILLED}`:
            return {
                ...state, data: updateData(state.data, action.payload)
            }
        case `${REJECT_MATCH}${_FULFILLED}`:
            return {
                ...state, data: updateData(state.data, action.payload)
            }

        case `${ACCEPT_DENY}${_FULFILLED}`:
            return {
                ...state,
                data: state.data.map(x => {
                    if (x.id === action.payload.id) {
                        return { ...x, status: action.payload.status }
                    }
                    return x;
                })
            }

        case `${name}${_CLEAR}`:
            return { ...state, ...matchesIntial }
        default:
            return state
    }
}

export default creatematches