import React from 'react'

export const CheckBox = ({ name, listIndex = "single", checklabel, className, checked, ...restProps }) => (
    <div className="d-flex align-item-center">
        <div className="multiple-checkbox pt-1">
            <input
                id={`${name}-${listIndex}`}
                checked={checked}
                type="checkbox"
                name={name}
                {...restProps}
            />
            <label className="radio-label d-flex align-items-center" htmlFor={`${name}-${listIndex}`}>
                {checklabel.length > 1 && checked ? checklabel[1] : checklabel[0]}
            </label>
        </div>
    </div>
)

export default CheckBox
