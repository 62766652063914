import React from 'react'
import { connect } from 'react-redux'

const ChatCon = ({ children, id }) => {
    return (
        <div className={`chat-app chat-room-${id ? 'open': 'close'}`}>
            {children}
        </div>
    )
}

const mapStateToProps = ({ chatroom }) => ({
    id: chatroom.id
})

export default connect(mapStateToProps)(ChatCon)
