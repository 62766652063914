import React from 'react'
import ProgressLoader from './toploader';   
import Section from '../section';

const RegisterLayout = ({children}) => (
    <div>
        <ProgressLoader width={25} />
        <Section>
            <div className="col-md-12 nopadding mb-5">
                {children}
            </div>  
        </Section>
    </div>
)

export default RegisterLayout
