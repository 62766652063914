import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendSocketMsg } from '../../redux/actions/chat';
import ChatBtn from '../../components/Chat/ChatBtn';


class ChatFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            chatinput: "",
            disabled: false
        };
    }

    componentDidMount() {
        this.joinRoom();
    }

    componentDidUpdate(prevProps) {
        if (this.props.roomId !== prevProps.roomId) {
            this.leaveRoom(prevProps.roomId);
            this.joinRoom();
        }
    }

    leaveRoom = room => sendSocketMsg("leave room", { room});

    joinRoom = () => {
        const { roomId, sendSocketMsg } = this.props;
        sendSocketMsg("join room", {
            room: roomId
        });
    }

    handleChange = e => {
        const { value } = e.target;
        this.setState({ chatinput: value });
    }

    handleSubmit = e => {
        e.preventDefault();
        const { chatinput } = this.state;
        const { sendSocketMsg, roomId, me } = this.props;
        if (chatinput) {
            sendSocketMsg("message", {
                room: roomId,
                msg: {
                    message: chatinput,
                    created_on: new Date(),
                    send_by: me.id
                },
                token: me.token
            });
            this.setState({ chatinput: '' });
        }
    }

    render() {
        const { chatinput, disabled } = this.state;
        return (
            <div className="row">
                <div className="col-md-12">
                    <form 
                        onSubmit={this.handleSubmit}
                        className="d-flex msg-ip-con border"
                    >
                        <input
                            disabled={disabled}
                            onChange={this.handleChange}
                            className="form-control msg-ip"
                            placeholder="Type your message here"
                            name="chatip"
                            value={chatinput}
                            required
                        />
                        {
                            chatinput &&
                            <ChatBtn />
                        }
                    </form>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        this.leaveRoom(this.props.roomId);
    }

}

export default connect(null, { sendSocketMsg })(ChatFooter)
