import React from 'react'
import List from './list';
import noitems from "../assets/img/noitems.png";

export const NoData = () => (
    <div className="col-md-12 d-flex justify-content-center">
        <img height="300" src={noitems} alt="" />
    </div>
)

const DynamicList = ({ 
    RenderItem,
    title="",
    Loader=null,
    error = false, 
    data = [], 
    fetching,
    ...restProps
}) => {
    if(data.length === 0){
        if(fetching){
            if(Loader){
                return <Loader />;
            }
            return (
                <div className="col-md-12 text-center">Loading...</div>
            )
        }
        return (
            <NoData />
        )
    }
    if(data.length > 0) {
        return (
            <List 
                RenderItem={RenderItem}
                list={data}
                title={title}
                {...restProps}
            />
        )
    }
    return null;
}

export default DynamicList
