import React from 'react'
import RegisterLayout from './registerlayout';
import RegisterForm from '../../containers/Register/registerform';

const RegisterPage = () => (
    <RegisterLayout>
        <RegisterForm />
    </RegisterLayout>
)

export default RegisterPage