import React from 'react'
import Section from '../section';

const Terms = () => {
    return (
        <div>
            <Section className="pt-4 mb-5">
                <div className="col-md-12 bx-shdw">
                    <p>Terms and Conditions of Santhosham Matrimony</p>
                    <div className="terms-list">

                        <ul>
                            <li>The file you have submitted in an electronic manner and which are acceptable under Information Technology Act, 2000.</li>
                            <li>Women should have completed 18 years and men should have 21 years completed.</li>
                            <li>Indian or Non Resident Indian or Anyone likes to marry Indian origin.</li>
                            <li>Fill the forms with correct information then you can get exact match for your profile.</li>
                            <li>To get bride you register in membership of <span className="theme-support-color">Santhoshammatrimony</span>.</li>
                            <li>We will help you to get perfect right life partner.</li>
                            <li>We cannot assure you exact result that you are searching.</li>
                            <li>Once you registered in <span className="theme-support-color">Santhoshammatrimony</span> don’t forget your user name and password.</li>
                            <li><span className="theme-support-color">Santhoshammatrimony</span> has the right to verify your entered details like marital status, educational qualification, occupation, etc.</li>
                            <li>We only receive online payment and be aware of broker no other hand to hand payment.</li>
                            <li>No criminal case should be registered for the person who is going to marry.</li>
                            <li>No misusing the details of person who registered in <span className="theme-support-color">Santhoshammatrimony</span>.</li>
                            <li>Please read our privacy and policy carefully before registering in <span className="theme-support-color">Santhoshammatrimony</span>.</li>
                            <li>We assure you that we will not to misuse your details to other persons.</li>
                            <li>Please enter true details in <span className="theme-support-color">Santhoshammatrimony</span> for searching the exact bride.</li>
                            <li>The content that you are filling in the form that you are full responsibility.</li>
                            <li>Don’t violate our guidelines and services.</li>
                            <li>Be aware of people who asking for money to help them.</li>
                            <li>You want to accept that we have the right to run your entered da</li>
                            <li>If you have any queries about our service please email us.</li>
                        </ul>
                    </div>
                </div>
            </Section>
        </div>
    )
}

export default Terms
