import React from 'react'
import ScrollPaging from '../containers/common/scrollpaging';
import { NoData } from '../components/dynamiclist';

const listhoc = (RenderItem, actionName, reducerName, api) => {
    const ListHoc = ({ url, noNext, ...restProps }) => {
        const listUrl = (url || api) + (noNext || ""); 
        return (
            <div className="row">
                <ScrollPaging
                    RenderItem={RenderItem}
                    actionName={actionName}
                    reducerName={reducerName}
                    url={listUrl}
                    noNext={noNext}
                    NoData={NoData}
                    {...restProps}
                />
            </div>
        )
    }
    return ListHoc
}

export default listhoc