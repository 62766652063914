import React, { Component } from 'react'
import apiInstance, { CancelToken, isCancel } from '../../redux/apiInstace';
import { connect } from 'react-redux'
import httpStatusErr from "../../redux/actions/httpStatusErr";

class Submit extends Component {

    state = {
        fetching: false
    };

    handleClick = async () => {
        const { config, succFunc, errFunc } = this.props;
        this.setState({ fetching: true });
        try {
            const res = await apiInstance({
                ...config,
                cancelToken: new CancelToken( c => this.cancel = c )
            });
            this.setState({ fetching: false });
            if (succFunc && typeof succFunc === "function") {
                succFunc(res.data)
            }
        } catch (err) {
            if (isCancel(err)) {
                console.log('Request canceled', err);
            } else {
                this.setState({ fetching: false });
                if(err.response) {
                    this.props.dispatch(httpStatusErr(err.response))
                }
                if (errFunc && typeof errFunc === "function") {
                    errFunc(err);
                }
            }
        }
    }

    render() {
        const { fetching } = this.state;
        const { Btn, config, succFunc, errFunc, ...restProps } = this.props;
        return (
            <Btn
                fetching={fetching}
                onClick={this.handleClick}
                {...restProps}
            />
        )
    }

    componentWillUnmount() {
        if(this.cancel){
            this.cancel("component unmounted");
        }
    }

}

export default connect(null)(Submit)
