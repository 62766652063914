import React from 'react'
import Section from '../section';
import Contactus from '../../containers/Manage/contactus';


const Help = () => {
    return (
        <div>
            <Section className="pt-4 pb-4">
                <div className="col-md-4">
                    <div className="sticky-dash-nav mb-3">
                        <Contactus />
                    </div>
                </div>
                <div className="col-md-4 mt-4">
                    <h6>Chennai</h6>
                    <p className="f-14"> <i className="fa fa-map-marker mr-3 theme-color f-18"></i>
                    No.37F 1st floor, Vijay Nagar, Beside Adyar Anandha Bhavan, Velachery, Chennai - 600042.
                    </p>
                    <p className="f-14">
                        <i className="fa fa-phone theme-color mr-3 f-18"></i>
                        Ph: 8925088389
                    </p>
                    <p className="f-14">
                        <i className="fa fa-envelope mr-3 theme-color f-18"></i>
                    Email:contact@santhoshammatrimony.com
                    </p>
                </div>
                <div className="col-md-4">
                    <iframe title="map location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.974818403769!2d80.2178576!3d12.9734624!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d4711612b35%3A0x19e6c6aaa6a4b995!2sAdyar%20Ananda%20Bhavan%20A2B%20Veg%20Restaurant!5e0!3m2!1sen!2sin!4v1620456219122!5m2!1sen!2sin" frameborder="0" width="100%" height="350" allowfullscreen="" loading="lazy"></iframe>
                    
                </div>
            </Section>
        </div>
    )
}

export default Help
