export const ConvertLocalDate = date => {
    let convertdate = new Date(date)
    return convertdate.toLocaleDateString('en-IN', { year: 'numeric', month: 'short', day: 'numeric' })
}

export const ConvertLocalTime = time => {

    let converttime = new Date(`1994-02-18T${time}`)
    return converttime.toLocaleTimeString('en-IN', { hour12: true, hour: '2-digit', minute: '2-digit' })
}

export const dateFormat = date => {
    const sevenDays = 604800000; //in ms
    const oneDay = sevenDays / 7;
    const oneHour = oneDay / 24;
    const oneMin = oneHour / 60;
    try {
        const dt = new Date(date);
        const now = new Date();
        const diff = now.getTime() - dt.getTime();
        if (diff < sevenDays && diff >= 0) {
            if (diff > oneDay) {
                const days = parseInt(diff / oneDay, 10);
                return `${days} day${days > 1 ? 's' : ''} ago`
            } else {
                if (diff > oneHour) {
                    const hrs = parseInt(diff / oneHour, 10);
                    return `${hrs} hour${hrs > 1 ? 's' : ''} ago`
                }
                return `${parseInt(diff / oneMin, 10)} min ago`
            }
        } else {
            return dt.toLocaleDateString('en-IN', { year: 'numeric', month: 'short', day: 'numeric' })
        }
    } catch (err) {
        console.log(err);
        return "";
    }
}


export const getPager = (totalItems, currentPage, pageSize) => {
    currentPage = currentPage || 1;

    pageSize = pageSize || 12;

    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 2 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }
    }
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

    return {
        totalItems: totalItems,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}