import React from 'react'
import Center from '../center';
import List from '../list';
import Paybtn from '../../containers/upgrade/paybtn';

export const Feature = ({ description, mt = 3 }) => (
    <li className={`mt-${mt} mb-3`}>
        <span className="theme-secondary-color mr-2 mt-1">
            <i className="fas fa-check-circle"></i>
        </span>
        <span className="f-12 f-600">
            {description}
        </span>
    </li>
)

const Package = ({ id, discount_price, original_price, name, features, is_purchased, offer }) => {
    return (
        <div className="col-md-4 flex-grow-1 mt-3 mb-2">
            <div className={`package ${is_purchased ? " purchasedpackage" : ""}`}>
                <div className="border-bottom p-2">
                    <h5>{name}</h5>
                    <div>
                        <span className="original-price text-striked  p-2 f-14">₹ {original_price}</span>
                        <span className="discount-price f-600 f-22">₹ {discount_price}</span>
                    </div>
                    <span className="discout-percentage label">{offer} %</span>
                </div>
                <div className="d-flex pl-3 pr-3" style={{ minHeight: `180px` }}>
                    <ul className="h100p list-unstyled m-0 pl-0">
                        <List
                            RenderItem={Feature}
                            list={features}
                            title={name + "-" + id}
                        />
                    </ul>
                </div>
                <Center className="">

                    <Paybtn package_id={id} className="btn custom-btn btn-block pay-btn mt-3" >
                        Upgrade Now
                    </Paybtn>
                </Center>
            </div>
        </div>
    )
}

export default Package
