import React, { Component } from "react";
import { addToast } from "../../redux/actions/common";
import SubmitBtn from "../common/submitbtn";
import { updateUser } from "../../redux/actions/user";
import { connect } from "react-redux";
import { changeMobile_url } from "../../redux/actions/constants";
import { withRouter } from "react-router-dom";
import { helpJSX } from "../../utils/Forms/editdetails";
import { phoneValid } from "../../utils/Forms/validation";

class Editusername extends Component {
    state = {
        username: this.props.usernameVal || ""
    };

    setUsername = value => {
        this.setState({ username: value });
    };

    componentDidMount() {
        this.setUsername(this.props.usernameVal);
    }

    componentDidUpdate(prevProps) {
        if (this.props.usernameVal !== prevProps.usernameVal) {
            this.setUsername(this.props.usernameVal);
        }
    }

    render() {
        const { username } = this.state;
        const { updateUser, phoneVerified, disabled } = this.props;
        return (
            <div className="form-group">
                <div className="d-flex">
                    <input
                        style={{ maxWidth: `60%` }}
                        className="form-control mr-2"
                        placeholder="Enter your Phone number"
                        onChange={({ target }) =>
                            this.setUsername(target.value)
                        }
                        value={username}
                        disabled={disabled}
                    />
                    <SubmitBtn
                        className="btn custom-btn"
                        disabled={
                            disabled || (phoneValid(username) ? false : true)
                        }
                        config={{
                            url: changeMobile_url,
                            method: "POST",
                            data: {
                                mobile_number: username
                            }
                        }}
                        succFunc={payload => {
                            updateUser(payload);
                            this.props.history.push("/otp");
                            // addToast("Phone number Updated");
                        }}
                        children={"Submit"}
                    />
                </div>
                <div className="f-12 mb-1">
                    {phoneVerified && (
                        <span className="mr-2 f-12 color-green">
                            <i className="fas fa-check-circle f-13 mr-1"></i>
                            Phone number verified
                        </span>
                    )}
                    <div className="mr-2 f-12 text-muted">
                        <i className="fas fa-info-circle f-13 mr-1"></i>
                        {helpJSX(disabled)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({
    user,
    usernameVal: user.user.username || "",
    phoneVerified: user.is_number_verified,
    disabled:
        (user.disable_filed || []).filter(x => x.field_name === "username")
            .length > 0
});

const mapDispatchToProps = {
    addToast,
    updateUser
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Editusername)
);
