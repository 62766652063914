import React from 'react'
import googleplaystore from "../../assets/img/google-play-store.png";
import { Link } from "react-router-dom"

export default function Footer1() {
    return (
        <footer className="footer-bottom-section">
            <div className="container">
                <div className="footer-bottom">
                    <div className="footer-center mt-3">
                        <div className="row">
                            <div className="col-xs-12 col-sm-5 col-md-6 mb-3">
                                <div>
                                    <h6 className="mb-3 f-600 theme-color">Santhosham Matrimony</h6>
                                    <p className="f-12 text-justify">One of southern India’s leading matrimonial websites that has helped lots of members find their perfect life partner.</p>
                                    <p className="f-12 text-justify">We believe choosing a life partner is the most primary decision, and hence works towards giving a simple and secure matchmaking experience for you and your family. Each profile registered with us goes through a manual screening process before going live on-site; we provide superior privacy controls for Free, and also verify contact information of members.</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6">
                                <div className='row'>

                                    <div className="mb-3 col-md-3 col-4">
                                        <h6 className="f-14">CONTACT US</h6>
                                        <ul className="footer-list">
                                            <li>
                                                <Link to="/safety-and-security">About US</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3 col-4">
                                        <h6 className="f-14">TERMS OF USE</h6>
                                        <FooterList />
                                    </div>
                                    <div className="mb-3 col-md-3 col-4">
                                        <h6 className="f-14">HELP & SUPPORT</h6>
                                        <ul className="footer-list">
                                            <li>
                                                <Link to="/help">Help</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="mb-3 col-md-3 col-4">
                                        <h6 className="f-14">Download</h6>
                                        <ul className="footer-list">
                                            <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.santhoshammatrimony_app" }} target="_blank">
                                                <img src={googleplaystore} alt="google-play-store" style={{ width: "150px", height: "40px", objectFit: "cover" }} />
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <hr />
                            <p className="footer-help">
                                <i className="far fa-copyright mr-1 f-12"></i>2019 <span className="theme-color">santhoshammatrimony.com</span> -  - The Best Tamil Matrimony, All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}


const FooterList = () => (
    <ul className="footer-list">
        <li>
            <Link to="/privacy-policy">
                Privacy Policy
            </Link>
        </li>
        <li>
            <Link to="/terms-and-conditions">
                Terms & conditions
            </Link>
        </li>
        <li>
            <Link to="/faqs">
                FAQ's
            </Link>
        </li>
    </ul>
)