import httpAction from "./httpAction"

//listAction
const listAction = (type, name, api) =>{
    switch(type) {
        case "LOAD":
            return httpAction(name, {
                url: api,
                method: "get",
            })
        case "NEXT":
            return httpAction(name, {
                url: api,
                method: "get",
            })
        case "UNLOAD":
            return { 
                type: `${name}_CLEAR`
            }
        default:
            return { 
                type: `${name}_CLEAR`
            }
    }
}

export default listAction;