import React from 'react'
import { connect } from 'react-redux'
import List from '../../components/list';
import UploadPhoto from '../../components/Register/uploadphoto';
import { Link } from "react-router-dom"

//creates [1,2,4, ...n]
export const createNarray = (n) => Array.apply(null, { length: n }).map(Function.call, Number);

//list of user photos
export const Photoslist = ({ photos }) => {
    const list = photos || [];
    const addlist = createNarray(12 - list.length);
    return (
        <div>
            {photos.length === 0 ?
                <div className="p-2">
                    <ul>
                        <li>You can upload Atleast <span className="theme-color">1 images</span> to complete <span className="theme-color">Registration</span></li>
                    </ul>
                </div> : ""}

            <div className="row pb-2">
                <List
                    list={list}
                    RenderItem={UploadPhoto}
                    title="user-photos"
                />
                <List
                    list={addlist}
                    RenderItem={UploadPhoto}
                    title="add-user-photos"
                />
            </div>
            <div className="p-2">
                <ul>
                    <li>You can upload upto <span className="theme-color">12 images</span> only with a maximum size of <span className="theme-color">5 MB</span> per image.</li>
                    <li>Profiles with Photo get more than <span className="theme-color">90% success</span> while Profiles without Photo get less than <span className="theme-color">10% success</span>. Upload Photo to get maximum result.</li>
                    <li><span className="theme-color">Group Photos or blurred Images</span> will be deleted by admin.</li>
                    <li>Criminal Action will be taken for uploading <span className="theme-color">Fake and Unauthorized </span> Photos.</li>

                </ul>
            </div>
        </div>
    )
}


const PhotoContinue = ({ photos }) => {
    if(photos.length===0){
        return <div className="btn btn-primary">Upload Atleast One Image & Continue</div>
    }
    return (
        <Link disabled={photos.length === 0} className="btn btn-primary" to="/partner-preferences">
            Continue
        </Link>
    )
}

const mapStateToProps = ({ user }) => ({
    photos: user.photos
})

const mapDispatchToProps = {

}


export const PhotoCon = connect(mapStateToProps, mapDispatchToProps)(PhotoContinue);

export default connect(mapStateToProps, mapDispatchToProps)(Photoslist)
