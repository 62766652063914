import React, { memo } from 'react'
import { withRouter } from "react-router-dom"
import Listnoti from '../../containers/Notifications/listnoti';
import Pagelayout from '../pagelayout';
import ContentLoader from "react-content-loader"
import dateFormat from '../../utils/dateformat';
import apiInstance from '../../redux/apiInstace';
import { addLocalChatUser, createRoom } from '../../redux/actions/chat';
import { connect } from 'react-redux';
import { createChatObj } from '../../containers/Dashboard/startchat';

const Notifications = () => {
    return (
        <Pagelayout>
            <div className="container pt-4 pb-5">
                <h3 className="mb-3">Notifications</h3>
                <Listnoti />
            </div>
        </Pagelayout>
    )
}

export const NotiComp = memo(({ id, actor, description, created_on, is_read, type = "default", fetching, history, addLocalChatUser, createRoom }) => {

    if (fetching) {
        return <NotiLdr />
    }
    else {
        const goLink = {
            "Chat": "/chat",
            "Number Request": "/dashboard/recieved-requests",
            "Photo Request": "/dashboard/recieved-requests",
            "Photo Approved": "/manage/photos",
            "Profile": `/profile/${actor.id}`,
            "default": `/profile/${actor.id}`,
        };
        return (
            <div className="col-md-12">
                <div
                    onClick={() => {
                        apiInstance({
                            url: `activities/notification/${id}/read_notification/`
                        })
                        if (type === "Chat") {
                            createChatObj(actor, addLocalChatUser, createRoom, history);
                        } else {
                            history.push(goLink[type] || goLink["Profile"]);
                        }
                    }}
                    className="noti">
                    <div className={`mb-3`}>
                        <div className="d-flex">
                            <img className="noti-image" src={actor.profile_pic} alt="" />
                            <div className="pl-2 pr-2 pt-1 flex-grow-1">
                                {!is_read && <span className="new-noti">New</span>}
                                <div className="medium-txt1">
                                    <div className="row">
                                        <div className="col-10">

                                            {actor.user.first_name} {actor.user.last_name}
                                        </div>
                                        <div className="col-2">
                                            {created_on && <small>{dateFormat(created_on)}</small>}
                                        </div>

                                    </div>
                                </div>
                                <p className="m-0 small-txt1">
                                    {description}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
})

export const Noti = withRouter(
    connect(null, { createRoom, addLocalChatUser })(NotiComp)
)

export const NotiLdr = props => (
    <div className="col-md-12">
        <ContentLoader
            height={112}
            width={1040}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            {...props}
        >
            <rect x="123.67" y="18.67" rx="4" ry="4" width="219.96" height="7.55" />
            <rect x="123" y="38.4" rx="3" ry="3" width="114.75" height="8.64" />
            <rect x="2.23" y="2.67" rx="0" ry="0" width="101.85" height="78.21" />
        </ContentLoader>
    </div>
)

export default Notifications
