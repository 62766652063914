import React from 'react'
import ProfileBox from './profilebox';

const TrustBadges = ({ gender_value }) => {

    return (
        <div className="bx-shdw p-4">
            <div className="row">
                <div className="col-md-12">
                    <ProfileBox title={
                        <span>
                            Trust Badges
                        </span>
                    }>
                        <div className="medium-txt1 mb-4 row">
                            <div className="m-3 d-flex clr-black1">
                                <i className="fas fa-mobile-alt theme-color f-60 "></i>
                                <div className="ml-3">
                                    <div className="f-14 mb-0 theme-support-color">Mobile Badge</div>
                                    <div>{gender_value} contact details have been checked</div>
                                </div>
                            </div>
                        </div>
                    </ProfileBox>

                </div>
            </div>
        </div>
    )
}

export default TrustBadges
