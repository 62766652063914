import { Component } from 'react'
import { connect } from 'react-redux'
import { loadForm } from '../../redux/actions/formactions';
import { initValue } from '../../utils/Forms/validation';
import { init, edituserform, descProps } from '../../utils/Forms/editdetails';

export class InitEdit extends Component {

    state = {
        show: false
    };

    componentDidMount(){
        const { initData } = this.props;
        const {mother_tongue, date_of_birth, user, user_details, religion_details, education_details, country, state, city, district, about_me, gender, disable_filed, secondary_number} = initData;
        const dob = date_of_birth ? new Date(date_of_birth) : null;
        const updatedUser = user_details ? {
            ...init.user_details,
            ...user_details
        } : init.user_details;
        const updatedRel = religion_details ? {
            ...init.religion_details,
            ...religion_details
        } : init.user_details;
        const updatedEd = education_details ? {
            ...init.education_details,
            ...education_details
        } : init.user_details;
        let otherdetails = { country, state, city, district, about_me, date_of_birth: dob, mother_tongue, gender, secondary_number };
        const updateData = {
            ...initValue(otherdetails),
            user,
            user_details: initValue(updatedUser),
            religion_details: initValue(updatedRel),
            education_details: initValue(updatedEd)
        };
        const disabledIps = (disable_filed || []).map(x => x.field_name);
        this.loadData(initValue(updateData), disabledIps);
    }

    loadData = (initVal, disabledIps) => {
        [...edituserform, descProps].forEach( x => {
            this.props.loadForm( x.formName, x.initFunc(initVal, disabledIps) );
        })
        this.setState({show: true});
    }

    render() {
        return this.state.show ? this.props.children : null 
    }
}

const mapStateToProps = ({user}) => ({
    initData: user
})

const mapDispatchToProps = {
    loadForm
}

export default connect(mapStateToProps, mapDispatchToProps)(InitEdit)