import React from 'react';
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import dateFormat from '../../utils/dateformat'

const LftPanelProfile = ({ location, user }) => {
    return (
        <div className='scrollable-myhome'>
            <div className="left-scroll-inside">
                <div className="lft-profile">
                    <div className="lft-profile-img">
                        <img alt="user profile" src={user.profile_pic}></img>
                    </div>
                    <div className="lft-profile-content">
                        <div className="lft-profile-name">{user.user.first_name}</div>
                        <div className="theme-support-color f-12 f-600">{user.uid} | {user.age} yrs</div>
                        <div className="f-12 ">{user.religion_details.caste.name}</div>
                        <div className="f-12 ">{user.district.name}</div>

                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        {user.packages && user.packages.points ?
                            <div className="mt-2">
                                <h5 className="text-center f-600 mb-0">{user.packages.points}</h5>
                                <p className="mb-0 f-12 theme-support-color">Available Points</p>
                                <div>
                                    <span>{dateFormat(user.packages.expiration_on)}</span>
                                    <p className="mb-0 f-12 theme-support-color">Expiration On</p>
                                </div>
                            </div> :
                            <Link className="btn upgradelink" to="/upgrade">Upgrade</Link>
                        }
                    </div>
                </div>
                <div className="mt-3 border-bottom">
                    <ul className="list-unstyled">
                        <li>
                            <Link to="manage">
                                <i className="fas fa-user-circle f-16 theme-color"></i>
                                <span className="pl-2 f-14 clr-black1">Edit Profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="manage/partner-preferences">
                                <i className="fas f-16 theme-color fa-user " aria-hidden="true"></i>
                                <span className="pl-2 f-14 clr-black1">Edit Partner Preferences</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="manage/photos">
                                <i className="fas f-14 theme-color fa-images" aria-hidden="true"></i>
                                <span className="pl-2 f-14 clr-black1">Manage Photos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="manage/privacy">
                                <i className="fas fa-user-cog f-14 theme-color"></i>
                                <span className="pl-2 f-14 clr-black1">Settings</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="mt-3 border-bottom">
                    <ul className="list-unstyled">
                        <li>
                            <Link to="activity/who-viewed-you">
                                <i className="fas fa-user-circle f-16 theme-color"></i>
                                <span className="pl-2 f-14 clr-black1 ">Who viewed my profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="activity/who-shortlisted-you">
                                <i className="fas f-14 theme-color fa-images" aria-hidden="true"></i>
                                <span className="pl-2 f-14 clr-black1 ">Shortlisted my profile</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="activity/who-viewed-your-number">
                                <i className="fas fa-user-cog f-14 theme-color"></i>
                                <span className="pl-2 f-14 clr-black1 ">Numbers viewed by me</span>
                            </Link>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

    );
};

const mapStateToProps = ({ user }) => ({
    user
})

export default connect(mapStateToProps)(LftPanelProfile)

