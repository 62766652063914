import React, { Component } from 'react'
import { connect } from 'react-redux'
import listAction from "../redux/actions/listaction"

const LoadDataHoc = (WrappedComponent, actionName, reducerName, api) => {
    class Loadapi extends Component {
        
        componentDidMount(){
            this.loadData();
        }

        componentDidUpdate(prevProps){
            const { url } = this.props;
            if(url && url !== prevProps.url){
               this.loadData();
            }
        }

        loadData = () => {
            this.props.dispatch(
                listAction("LOAD", actionName, api || this.props.url)
            );
        }

        render() {
            const { url, ...restProps} = this.props;
            return (
                <WrappedComponent 
                    {...restProps}
                />
            )
        }

        componentWillUnmount(){
            this.props.dispatch(
                listAction("UNLOAD", actionName, api)
            );
        }

    }

    const mapStateToProps = (state) => ({
        apiData: state[reducerName],
        userData: state.user
    })

    return connect(mapStateToProps)(Loadapi)
}

export default LoadDataHoc